<template>
    <div class="navigation-bar">
        <Menubar :model="items" style="border: 0px !important;">
            <template #start>
                <a style="cursor: pointer;" @click="$router.push('/')"><img alt="logo" src="@/assets/img/logo.png" height="60" class="p-mr-2"></a>
            </template>
        </Menubar>
    </div>
</template>

<script>

import Menubar from 'primevue/menubar';
import { ref } from 'vue';

export default {
    name: "NavigationBar",
    components: { Menubar },
    props: {
        account_id : String
    },
    setup(props){
        const items = ref([
            {
            label:'Anagrafiche',
            icon:'pi pi-fw pi-book',
            items:[
                    {
                        label:'Linee',
                        items: [
                            {
                                label: 'Lista',
                                icon: 'pi pi-fw pi-book',
                                to: '/productLines/' + props.account_id
                            },
                            {
                                label: 'Nuova',
                                icon: 'pi pi-fw pi-plus',
                                to: '/productLines/createProductLine/' + props.account_id
                            }
                        ]
                    },
                    {
                        label:'Categorie',
                        items: [
                            {
                                label: 'Lista',
                                icon: 'pi pi-fw pi-book',
                                to: '/productCategories/' + props.account_id
                            },
                            {
                                label: 'Nuova',
                                icon: 'pi pi-fw pi-plus',
                                to: '/productCategories/createProductCategory/' + props.account_id
                            }
                        ]
                    },
                    {
                        label:'Prodotti',
                        items: [
                            {
                                label: 'Lista',
                                icon: 'pi pi-fw pi-book',
                                to: '/products/' + props.account_id
                            },
                            {
                                label: 'Nuovo',
                                icon: 'pi pi-fw pi-plus',
                                to: '/createProduct/' + props.account_id
                            }
                        ]
                    },
                    {
                        separator:true
                    },
                    {
                        label:'Documenti',
                        items: [
                            {
                                label: 'Lista',
                                icon: 'pi pi-fw pi-book',
                                to: '/'
                            },
                            {
                                label: 'Nuovo',
                                icon: 'pi pi-fw pi-plus',
                                to: '/documents/uploadDoc/' + props.account_id
                            }
                        ]
                    },
                    {
                        label:'Fornitori',
                        items: [
                            {
                                label: 'Lista',
                                icon: 'pi pi-fw pi-book',
                                to: '/suppliers/' + props.account_id
                            },
                            {
                                label: 'Nuovo',
                                icon: 'pi pi-fw pi-plus',
                                to: '/suppliers/createSupplier/' + props.account_id
                            }
                        ]
                    },
            ]
            },
            {
                label:'Digital Twin',
                icon:'pi pi-fw pi-id-card',
                items:[
                    {
                        label:'Timeline',
                        items: [
                            {
                                label: 'Anagrafica',
                                icon: 'pi pi-fw pi-book',
                                to: '/timelinesV2/' + props.account_id
                            },
                            {
                                label: 'Crea nuova',
                                icon: 'pi pi-fw pi-plus',
                                to: '/configureTimelineV2/' + props.account_id
                            }
                        ]  
                    },
                    {
                        label:'Pagina timeline',
                        items: [
                            {
                                label: 'Lista pagine',
                                icon: 'pi pi-fw pi-book',
                                to: '/pagesV2/' + props.account_id
                            },
                            {
                                label: 'Crea nuova',
                                icon: 'pi pi-fw pi-plus',
                                to: '/configureProductPageV2/' + props.account_id
                            }
                        ]  
                    },
                    {
                        separator:true
                    },
                    {
                        label:'Modello 3D'
                    },
                    {
                        label:'NFT',
                    }
                ]
            },/*
            {
                label:'NFT Machine',
                icon:'pi pi-fw pi-shopping-cart',
                items:[
                    {
                        label:'Crea',
                        icon: 'pi pi-fw pi-plus',
                        to: '/mintNFT'
                    },
                    {
                        label:'Collezioni',
                        icon: 'pi pi-fw pi-book',
                        to: '/collectionList'
                    }
                ]
            },
             {
                label:'Test',
                icon:'pi pi-fw pi-shopping-cart',
                items:[
                    {
                        label:'test',
                        icon: 'pi pi-fw pi-plus',
                        to: '/test'
                    }
                ]
            }, */
            {
                label:'Settings',
                icon:'pi pi-fw pi-cog',
                to: '/settings/' + props.account_id
            }
        ]);

        return{ items }
    }
}

</script>

<style scoped>
    .navigation-bar{
        background-color: #2f3333;
    }

    ::v-deep(.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text){
        color: white !important;
    }

    ::v-deep(.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon){
        color: white !important;
    }

    ::v-deep(.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon){
        color: white !important;
    }
</style>

