<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items" />
    <Toast />

    <div class="p-ml-4 p-mt-5">
      <Message v-if="!category_id" class="p-mr-4" severity="info">Per registrare una categoria è necessario assegnarle un nome e una descrizione, specificare quanti prodotti dovrà contenere e la sua linea di appartenenza.</Message>  
      <Message v-if="category_id" class="p-mr-4" severity="info">Inserisci il numero di prodotti che desideri aggiungere alla categoria.</Message>  
    </div>

    <div class="p-mt-5 p-mx-3 p-mb-5">
      <div class="p-grid p-jc-between p-mr-2 p-ml-3 p-mt-5">

        <div class="p-fluid p-formgrid p-col-12 p-grid p-md-7 p-shadow-2 rounded-5 p-p-3">
          <div class="p-col-12">

            <h3>Nome Categoria</h3>
            <span class="p-float-label p-mt-2">
              <InputText v-model="name" id="name" type="text" :disabled="category_id"/>
            </span>
            
            <div>
              <h3 class="p-mt-5">Descrizione Categoria</h3>
              <span class="p-float-label p-mt-2">
                <TextArea v-model="description" id="description" type="text" rows="5" placeholder="Descrizione" :disabled="category_id"/>
              </span>
            </div>

            <div class="p-grid p-ai-center p-vertical-container p-mt-5 p-mb-5">
              <h3 class="p-col-12">Numero prodotti</h3>
              <span class="p-float-label p-mt-2 p-ml-2">
                <InputText v-model="limit" id="limit" type="text" placeholder="Numero prodotti" autocomplete="off" @click="setFlag()" @keypress="isNumber($event)"/>
              </span>
              <div v-if="selectedLine">
                <span class="p-float-label p-mt-2 p-ml-4">
                  <Button :disabled="!limit" type="button" icon="pi pi-download" @click="loadLineProducts()" class="purple purple-btn"></Button>
                </span>
              </div>
            </div>

            <div class="p-grid p-jc-between p-ai-center p-vertical-container p-mb-5 p-mt-5">
              <div class="p-col-6">
                <h3>Linea di appartenenza</h3>
                <span class="p-float-label p-mt-2">
                  <Dropdown v-model="selectedLine" @change="loadLineProducts()" :disabled="lockLine || !limit" :options="productLines" optionLabel="name" optionValue="id" placeholder="Select a Line" />
                </span>
              </div>
              <div class="p-col-12" >
                <Message v-if="!selectedLine" severity="info">Per caricare i prodotti devi prima scegliere una linea.</Message> 
              </div> 
            </div>

            

            <div v-if="showProducts && description && name && limit" class="p-col-12" style="padding-left: 0px !important; padding-right: 0px !important">
              <div class="p-mt-4" >
                <Button label="Registra" v-if="!category_id" :disabled="!selectedTags || !selectedTags.length"  icon="pi pi-pencil" class="purple purple-btn" style="font-size: 1.5em" @click="onSave()"/>
                <Button label="Aggiungi" v-if="category_id" :disabled="!selectedTags || !selectedTags.length"  icon="pi pi-plus" class="purple purple-btn" style="font-size: 1.5em" @click="registerProducts()"/>
              </div>
            </div>

          </div>
        </div>

        <div class="p-col-12 p-md-5 p-shadow-2 rounded-5 p-p-3" style="max-eight: 80vh !important">
          <Message v-if="!showProducts" severity="info">Una volta scelta la linea e inserito il numero di prodotti da registrare sarà possibile selezionarli da questo riquadro.</Message>
          <Message v-if="limit>products.length && showProducts && !category_id && products.length" severity="warn">Il numero di prodotti richiesto <b>({{limit}})</b> è superiore al numero di prodotti registrati liberi per la linea {{selectedLineName}} <b>({{products.length}})</b>. Saranno assegnati alla categoria solo {{products.length}} prodotti</Message>
          <Message v-if="limit>products.length && showProducts && category_id && products.length" severity="warn">Il numero di prodotti richiesto <b>({{limit}})</b> è superiore al numero di prodotti registrati liberi per la linea {{selectedLineName}} <b>({{products.length}})</b>. Saranno aggiunti alla categoria solo {{products.length}} prodotti</Message>

          <div v-if="showProducts">
            <div v-if="products.length">
              <h4 v-if="limit<=products.length">I prodotti selezionati ({{selectedTags.length}}) saranno assegnati alla categoria di prodotto corrente.</h4>

              <products-table class="p-mt-3" title="Lista tag registrabili" :products="products" :showRowDetail="false" :lineSelectable="true" 
                :showRemoveSwitch="false" :selectedAll="true" :showIdTag="true" :showID="false" :showName="false" :showDescription="false" 
                :showCat="false" :showLine="false" :showTimeline="false" :showPage="false" :showDocs="false" :showSelectedColumns="false" @reload="reload" @selectedTags="setSelectedTags">
              </products-table>

            </div>
            <div v-else>
              <transition-group name="p-message" tag="div">
                <Message severity="warn">La linea selezionata ({{selectedLineName}}) non ha prodotti assegnabili.</Message>
              </transition-group>
            </div>
          </div>

        </div>

      </div>
    </div>

    <Dialog header="Conferma" :showHeader="!showSpinner" v-model:visible="showConfirm" :style="{width: '50vw'}" :modal="true">
      <div v-if="!showSpinner">
        Vuoi procedere al caricamento della categoria? Nessuna transazione verrà scritta su blockchain.
      </div>

      <div v-else>
        <div class="p-text-center p-mt-3">
          <h2>Attendere il caricamento del documento.</h2>
          <ProgressSpinner />
        </div>
      </div>

      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirm" />
        <Button label="Si" icon="pi pi-check" @click="registerCategory()" />
      </template>
    </Dialog>

  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

import NavigationBar from '../components/NavigationBar';
import ProductService from '../../service/ProductService';
import AccountService from '../../service/AccountService';
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useToast } from 'primevue/usetoast';


export default {
  name: "Create Product Line",
  components: {  NavigationBar, ProductsTable, Breadcrumb, Toast, InputText, TextArea, Button, Dropdown, Message, Dialog, ProgressSpinner },

  setup(){
    const toast = useToast();

    onMounted(() => {
      setBreadCrumb();
      getLines(account_id);
      if(category_id){
        loadCategoryInfo();
      }
    })

    const products = ref([]);
    const productService = ref(new ProductService());
    const accountService = ref(new AccountService());

    const selectedTags = ref([]);

    const limit = ref();
    const name = ref();
    const description = ref();

    const selectedLine = ref();
    const selectedLineName = ref();
    const productLines = ref([]);

    const showProducts = ref(false);
    const selectedAll = ref(true);

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const line_id = route_params.split('&')[1];
    const category_id = route_params.split('&')[2];

    const lockLine = ref(false);

    if(line_id){
      selectedLine.value = line_id;
      lockLine.value = true;
    }
  
    const home = ref({
      icon: 'pi pi-home', 
      to: '/',
    });

    const items = ref([]);

    const setBreadCrumb = () => {
      if(category_id){
        (items.value).push(
          {
            label: 'Categoria ('+category_id+')',
            to: '/categoryDetail/'+account_id+'&'+category_id
          },
          {
            label: 'Modifica categoria'
          }, 
        )
      }else if(line_id){
        (items.value).push(
          {
            label: 'Linea ('+line_id+')',
            to: '/lineDetail/'+account_id+'&'+line_id
          },
          {
            label: 'Crea categoria'
          }, 
        )
        /* toast.add({severity: 'warn', summary: 'Timeline già registrata', detail: "Ogni modifica sostituirà la configurazione corrente", life: 30000}); */
      }else{
        (items.value).push(
          {
            label: 'Categorie Prodotto',
            to: '/productCategories/'+account_id
          },
          {
            label: 'Crea nuova'
          }
        )
      }
    }

    const loadLineProducts = () => {
      let line = productLines.value.find(element => element.id == selectedLine.value);
      selectedLineName.value = line.name;
      
      showProducts.value = false;

      productService.value.getFreeProductsByLineId(selectedLine.value, limit.value).then(data => {
        console.log("Data: ",data.docs);
        products.value = data.docs;
        showProducts.value = true;
      })
    }

    const loadCategoryInfo = () => {
      accountService.value.getCategoryById(category_id).then(data => {
        products.value = data.products;
        name.value = data.name;
        description.value = data.description;
      })
    }

    const getLines = (account_id) => {
      accountService.value.getProductLines(account_id).then(data => {
            //console.log("productLines: ",data);
            productLines.value = data;
        })
    }

    const isNumber = (evt) => {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    const reload = () => {
      loadLineProducts(limit.value)
    }

    const setSelectedTags = (rows) => {
      selectedTags.value = rows.value;
    }

    const registerCategory = () => {account_id, name, description, selectedTags, line_id, category_id
      accountService.value.registerCategory(account_id, name.value, description.value, selectedTags.value, selectedLine.value).then(response => {
        if(response == 200){
            toast.add({severity: 'success', summary: 'Success', detail: 'Categoria registrata', life: 5000});
            name.value = "";
            description.value = "";
            limit.value = "";
            products.value = [];
            selectedTags.value = [];
            showProducts.value = false;
            showSpinner.value = false;
            showConfirm.value = false;

            if(!line_id){
              selectedLine.value = "";
              selectedLineName.value = "";
            }
          }
        });
    }

    const showConfirm = ref(false);
    const enableUpload = ref(false);
    const showSpinner = ref(false);

    const closeConfirm = () => {
      showConfirm.value = false;
    }

    const onSave = () => {
      showConfirm.value = true;
    }

    const registerProducts = () => {
      productService.value.registerProductCategory(category_id, selectedTags.value, name.value, description.value).then( response => {
        if(response == 200){
          toast.add({severity: 'success', summary: 'Success', detail: 'Category updated', life: 5000});
          limit.value = "";
          products.value = [];
          selectedTags.value = [];
          showProducts.value = false;
        }
      });
    }

    const setFlag = () => {
      showProducts.value = false;
    }

    return { setBreadCrumb, registerProducts, category_id, setFlag, loadLineProducts, lockLine, isNumber, selectedTags, selectedLineName, 
      selectedAll, showProducts, productLines, selectedLine, home, items, enableUpload, showSpinner, closeConfirm, onSave, showConfirm,
      account_id, products, productService, reload, limit, name, description, registerCategory, setSelectedTags}
  }
};
</script>

<style scoped>
  .a {
    max-height: 80vh !important;
  }
</style>

