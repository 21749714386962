<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>

    <div class="p-mx-3">
      <div class="p-grid p-jc-end p-mt-3">
        <Button label="Nuovo" icon="pi pi-plus" class="purple-btn purple p-mt-3 p-col-12 p-md-2 p-mr-3" style="font-size: 1.5em" @click="$router.push('/createProduct/'+account_id)"/>
      </div>
      <Message class="p-mx-2" severity="info" v-if="!products.length">Per prima cosa registra un prodotto.</Message>  
      <div class="p-shadow-2 rounded-5 p-p-3 p-mt-3 p-mx-2">
        <products-table class="p-mt-3" title="Lista prodotti" :products="products" :showRowDetail="true" :lineSelectable="false" 
        :showRemoveSwitch="false" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
        :showCat="true" :showLine="true" :showTimeline="true" :showPage="true" :showDocs="true" :showSelectedColumns="true"
        ></products-table>
      </div>
    </div>
  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';
import ProductsTable from '../components/ProductsTable';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Message from 'primevue/message';

import { useRoute } from 'vue-router';

import { ref, onMounted } from 'vue';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, ProductsTable, Button, Message },
  setup(){

    onMounted(() => {
      loadProducts();
    })

    const route = useRoute();

    const account_id = route.params.data;

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Prodotti'},
    ]);

    const products = ref([]);
    const productService = ref(new ProductService());

    const loadProducts = () => {
      productService.value.getProducts(account_id, 10000000).then(data => {
        console.log("Data: ", data.docs);
        let prods = [];
        data.docs.forEach(e => {
          if(e.timeline_id){
            e.has_timeline = true;
          }else{
            e.has_timeline = false;
          }

          if(e.page_id){
            e.has_page = true;
          }else{
            e.has_page = false;
          }
          prods.push(e);
        });
        products.value = prods;
      })
    }

    return { home, items, account_id, products }
  }
};

</script>

<style scoped>

</style>

