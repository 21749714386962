<template>
    <div class="footer">
        <div class="p-grid p-ai-center">
            <div class="p-col-12 p-mt-4 p-d-flex p-ai-center p-jc-center">
                <a style="cursor: pointer; text-decoration: none !important;" href="https://www.blockvision.it" target="_blank"><h3 style="color: white;">Powered by Blockvision</h3></a>
                <!-- <img alt="logo" src="@/assets/img/logo.png" height="60" class="p-mr-2"> -->
            </div>
            
            <div class="p-col-12 p-d-flex p-ai-center p-jc-around">
                <div class="p-col-12 p-grid p-d-flex p-ai-center p-text-center p-mb-1">
                    <small class="p-col-12" style="color: darkgrey;">The Digital Product Passport was last updated on {{updateDate}}</small>
                    <!-- <a href="https://www.blockvision.it" target="_blank" class="p-col-12" style="color: white"><h4>www.blockvision.it</h4></a> -->
                </div>

               <!--  <div class="p-col-6 p-grid p-d-flex p-ai-center p-text-right">
                    <h4 class="p-col-12"><span class="p-px-2" style="border-right: 1px solid white">Torino</span><span class="p-px-2" style="border-right: 1px solid white">Firenze</span><span class="p-px-2">Cesena</span></h4>
                    <h4 class="p-col-12">info@blockvision.it</h4>
                </div> -->
            </div>

        </div>
        
    </div>
</template>

<script>


export default {
    name: "FooterBar",
    props: {
        updateDate : String
    },
    components: {  },
}

</script>

<style scoped>
    .footer{
        background-color: #2f3333;
    }
</style>

