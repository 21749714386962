<template>
  <main style="overflow-x: hidden">

    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>

    <div class="p-mx-3 p-grid p-jc-between">

      <div class="p-col-12 p-d-flex p-jc-end p-mt-3">
        <Button label="Modifica" icon="pi pi-pencil" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="$router.push('/configureProductPageV2/'+account_id+'&'+page_id+'&86ddf3ed-6603-45a6-95b1-60c941c89054')"/>
      </div>

      <div class="p-grid p-col-12 p-ai-stretch p-jc-between" style="padding-left: 1rem;">


        <div class="p-grid p-col-12 p-md-8 p-jc-between">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
            <div class="p-col-12">

              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="page_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Nome</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="pageName" id="ID" type="text" placeholder="Nome" :disabled="true"/>
                </span>
              </div>

            </div>
          </div>
        
        </div>

        <div class="p-col-12 p-xl-4 p-jc-start p-ai-stretch p-pr-3" style="padding-left: 0px !important; margin-left: 0px !important;">
          
          <div v-if="page_products.length" class="p-col-12 overflow-break" style="padding-left: 0px !important; margin-left: 0px !important;">
            <Card>
              <template #content>
                <products-table class="p-mt-3" title="Lista prodotti registrati" :products="page_products" :showRowDetail="true" :lineSelectable="false" 
                :showRemoveSwitch="true" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
                :showCat="true" :showLine="true" :showTimeline="false" :showPage="false" :showDocs="false" :showSelectedColumns="false" @removeProduct="removeFromPage"
                ></products-table>
              </template>
            </Card>
          </div>
            
        </div>

        <!--<div class="p-col-12 p-xl-4">
        
        <ScrollPanel style="height: 70vh">
          <div v-if="loadPage" class="">
      
            <div class="p-d-flex p-jc-center p-ai-center p0" :style="{ background: 'url(\''+pageParameters.backgroundImgMobile+'\')', height: '70vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative'}">
              <div class="p-text-center overlay-nav p-col-12 px0 py0" style="position: fixed !important; z-index: 100;">
                <header-bar class="navigator" v-if="showHeaderBar" @openMenu="openMenu()"></header-bar>
              </div>

              <div class="overlay p-p-1 rounded-35 text-grey p-shadow-5" :style="{ bottom: '-'+pageParameters.upperTextBlockBottom+'%'}">
                <div class="p-text-center">
                  <div class="p-mb-4 p-mt-4">
                    <Image class="" :imageStyle="{ maxWidth: '35%' }" :src="pageParameters.logoImg" alt="Image"/>
                  </div>
                  <h4 class="p-mx-4" style="font-weight: unset !important;">{{pageParameters.primaryUpperText}}</h4>
                  <h4 class="p-mx-4 p-mt-3">{{pageParameters.secondaryUpperText}} <br/><br/></h4>
                </div>
                <div class="p-text-center">
                    <a href="#timeline" :class="linkClass" v-smooth-scroll>
                      <Button :class="btnClass" style="font-size: 1.3em" @click="changeButtonColor()">
                        <span class="blink_me">Scopri la filiera!</span>
                      </Button>      
                    </a>
                </div>
              </div>

            </div>

            <div id="menu-overlay" class="menu-overlay">
              <a class="menu-link closebtn" href="javascript:void(0)" @click="closeMenu()">&times;</a>
              <div class="overlay-content">
                <a class="menu-link" href="https://www.blockvision.it" target="_blank">Scopri Blockvision</a>
                <a class="menu-link" href="https://www.ohoskin.com/" target="_blank">Scopri Ohoskin</a>
                <a class="menu-link" href="#timeline" @click="closeMenu()">Vai alla filiera</a>
                <a class="menu-link" href="#contact-us" @click="closeMenu()">Contattaci</a>
              </div>
            </div>
            
            <div class="p-grid p-mx-3 p-ai-center p-jc-center" :style="{ marginTop: pageParameters.lowerTextBlockBottom+'%'}">
              <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
                | <br />
                | <br />
                | <br />
                | <br />
                <font-awesome-icon style="font-size: 13vw;" class="badge-icon fa-comment-dots dark-grey" :icon="['fas', 'arrow-circle-down']"/>
              </h3>
              
              <div class="p-col-10 p-mt-5 p-mb-5">
                <h2 class="text-grey p-text-center">{{pageParameters.lowerBlockText}}</h2>
              </div>
            </div>

            <div v-if="pageParameters.gifSrc" class="p-mt-3">
              <Image  :imageStyle="{ width: '100%' }" :src="pageParameters.gifSrc" alt="Image"/>
            </div>

            <div class="p-grid p-mx-3 p-ai-center p-jc-center">
              <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
                | <br />
                | <br />
                | <br />
                | <br />
              </h3>
              
              <div class="p-col-10 text-grey p-jc-center p-text-center pb0" >
                <h3 class="p-mx-4">Scopri tutta la filiera</h3>
                <div class="big-text">{{pageParameters.companyName}}</div>
              </div>
            </div>

            <Dialog class="px0 py0" :contentStyle="{padding: '0px !important'}" v-model:visible="displayModalVideo" :style="{width: '100vw'}" :modal="true" :maximizable="true">
              <div class="player-container">
                <vue3-video-player :src="pageParameters.videoSrc"></vue3-video-player>
              </div>
            </Dialog>

          </div>

          <div v-if="loadTimeline" id="timeline" class="p-mb-5">


            <div>

              <div class="p-d-flex p-jc-center pt0 p0">
                
                  <div class="p-grid p-col-12 p-jc-center p-mt-2 p-ai-start py0 px0">
                    
                    <div v-if="(selectedIndex>0)" class="p-col-2 p-mb-1">
                      <Button class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-left" style="margin-top: 0.46rem;"  @click="shiftDocumentsRight()"/>
                    </div>
                    <div v-else class="p-col-2 p-mb-1">
                      <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="" style="margin-top: 0.46rem; color: white !important;"/>
                    </div>

                    <div class="p-col-8 pb0 p-d-flex p-jc-center">
                      <div class="py0 p-d-flex p-jc-center p-text-center" v-for="(input, index) in visibleDocuments" :key='index'>
                        <div class="p-jc-center p-ai-center">
                          <Avatar v-if="index==selectedIndex" class="cursor-pointer p-mx-1 timeline-badge-selected timeline-badge" shape="circle">
                            <font-awesome-icon :class="input.icon_class" :icon="input.icon" />
                          </Avatar>
                          <Avatar v-else class="cursor-pointer p-mx-1 timeline-badge" shape="circle" style="margin-top: 0.2rem;">
                            <font-awesome-icon :class="input.icon_class" :icon="input.icon" />
                          </Avatar>
                          <div v-if="index==selectedIndex" class="dark-grey" style="font-weight: bolder !important;">
                            | <br />
                            | <br />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div v-if="(docIdx<=documents.length-2)" class="p-col-2 p-mb-1">
                      <Button v-if="(docIdx<=documents.length-2)" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.46rem;"  @click="shiftDocumentsLeft()"/>
                    </div>
                    <div v-else class="p-col-2 p-mb-1">
                      <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.46rem; color: white !important;"/>
                    </div>

                  </div>

              </div>

            </div>

            <div v-if="selectedIndex!=-1" class="p-grid p-jc-center p-mt-2">
              <timeline-panel-V2
                :docName="selectedStep.docName" :timelineLocation="selectedStep.timelineInfo.timelineLocation" :timelineName="selectedStep.timelineInfo.timelineName" :timelineDescription="selectedStep.timelineInfo.timelineDescription"
                :tags="selectedStep.docTags" :panelClass="'p-col-9 p-grid p-jc-center box'" :imageUrl="selectedStep.timelineInfo.timelineImage" :parameters="parameters" :documentUrl="selectedStep.metaBlockchain.link" :blockchainLink="selectedStep.metaBlockchain.tx"
              >
              </timeline-panel-V2>

            </div>
            
            <div class="p-grid p-mx-2 p-mt-5">

              <Galleria :value="pageParameters.images" :numVisible="5" :showThumbnails="false" :circular="true" :showItemNavigators="true">
                <template #item="{item}">
                  <img :src="item.itemImageSrc" :alt="item.alt" style="width: 100%; display: block;" />
                </template>
                <template #caption="{item}">
                  <h4 style="margin-bottom: .5rem;">{{item.title}}</h4>
                  <p>{{item.description}}</p>
                </template>
              </Galleria>

            </div>

            <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
              | <br />
              | <br />
              | <br />
              | <br />
              <font-awesome-icon style="font-size: 13vw;" class="badge-icon fa-comment-dots dark-grey" :icon="['fas', 'arrow-circle-down']"/>
            </h3>

            <div v-if="loadPage" id="contact-us" class="p-grid p-jc-center" @click="openModalFeedback()">
              <div class="p-col-10 p-grid p-shadow-5 p-mt-5 rounded-20 p-ai-center p-jc-center">
                <div class="p-col-4 p-text-center">
                  <Image class="" :imageStyle="{ maxWidth: '100%' }" :src="pageParameters.logoImg" alt="Image"/>
                </div>
                <div class="p-col-4 p-text-center">
                  <font-awesome-icon style="font-size: 16vw; color: darkgrey;" class="badge-icon fa-comment-dots" :icon="['fas', 'comment-dots']"/>
                </div>
              </div>
            </div>
            
          </div>

          <Dialog class="p-p-0" :show-header="false" v-model:visible="displayModalFeedback" style="width: 80vw; border-radius: 20px !important;" contentStyle="border-radius: 20px !important;" :modal="true" :maximizable="false">
            <div class="p-mt-4">
              <i class="pi pi-times" @click="displayModalFeedback=false"></i>
            </div>
            <div v-if="!messageSent" class="p-mt-5 p-mb-5">
              <div class="p-grid p-jc-end">
                <div class="p-col-9 message rounded-10 p-py-3 p-px-3">
                  Cerchi altre informazioni?
                </div>
              </div>
              <div>
                <Avatar class="cursor-pointer user-badge" shape="circle">
                  <Image class="" :imageStyle="{ maxWidth: '80%' }" :src="pageParameters.messageLogo" alt="Image"/>
                </Avatar>
              </div>

              <div class="p-mt-5 p-mb-2">
                <small>Siamo pronti a risponderti!</small>
              </div>

              <div class="p-grid p-jc-start py0 px0 p-ai-end message-2">

                <div class="p-col-10 p-grid" style="margin-left: 0px !important;">
                  <div class="p-mt-2 p-col-5 p-d-flex pl0 py0 p-mb-1">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Città" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-mt-2 p-col-7 p-d-flex pr0 py0 p-mb-1 pl0">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Mario Rossi" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-col-12 p-d-flex px0 py0">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="E-mail" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-col-12 p-mt-1 px0 py0">
                    <TextArea class="rounded-10 p-col-12" v-model="message" id="message" type="text" rows="5" placeholder="Messaggio..." style="background-color: lightgrey; border: none;"/>        
                  </div>
                </div>

                <div class="p-col-2 p-mb-1">
                  <Button class="p-mb-1 p-ml-1" icon="pi pi-send" style="font-size: 1.5em; background-color: #A7B182; color: white;"  @click="sendMessage()"/>
                </div>

              </div>
            </div>

          </Dialog>

          <div class="p-grid p-jc-center p-mt-5" style="margin-bottom: 4rem !important;">

            <div v-if="!showInfo" class="p-col-10 p-grid p-jc-center p-mt-5">
              <div class="p-grid p-col-8 p-ai-center pb0" >
                <i class="p-col-2 pi pi-check-circle px0"></i>
                <Image class="p-col-10 px0" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img924/2817/CBjoQz.png" alt="Image"/>
              </div>
              <div class="p-col-10 p-ai-center p-text-center pt0">
                Filiera tracciata su Blockchain
              </div>
              
            </div>

          </div>
        </ScrollPanel> 

        </div>-->

      </div>

      

    </div>
    
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';

/* import Image from 'primevue/image'; */
/* import Dialog from 'primevue/dialog'; */
/* import Avatar from 'primevue/avatar'; */
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
/* import ScrollPanel from 'primevue/scrollpanel'; */
import Card from 'primevue/card';
/* import Galleria from 'primevue/galleria'; */
/* import TextArea from 'primevue/textarea'; */

import NavigationBar from '../components/NavigationBar';
/* import HeaderBar from '../components/HeaderBar'; */

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';

/* import TimelinePanelV2 from '../components/TimelinePanelV2'; */
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: "ProductPage",
  components: { InputText, Button, Card, NavigationBar, Breadcrumb, ProductsTable },
  setup(){

    onMounted(() => {
      loadPageInfo();
    })

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const loadPageInfo = () => {
      accountService.value.getProductPageById(page_id).then(data => {
        if(data.docs[0]){
          pageName.value = data.docs[0].page_name;
          pageParameters.value = data.docs[0].parameters;

          selectedStep.value = visibleDocuments.value[0];

          loadPage.value = true;
          loadTimeline.value = true;
        }
      })

      loadPageProducts();
      
    }

    const loadPageProducts = () => {
      productService.value.getProductsByPageId(page_id).then(data => {
        console.log("Prodotti: ", data.docs);
        page_products.value = data.docs;
      })
    }

    const page_products = ref([]);

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const page_id = route_params.split('&')[1];

    const documents = ref([
      {
        "docName": "Doc prova 1",
        "docDescr": "Doc prova 1",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 1",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 1
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "industry"
        ],
        "icon_class": "badge-icon fa-industry",
      },
      {
        "docName": "Doc prova 2",
        "docDescr": "Doc prova 2",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 2",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 2
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "search"
        ],
        "icon_class": "badge-icon fa-search",
      },
      {
        "docName": "Doc prova 3",
        "docDescr": "Doc prova 3",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 3",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 3
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "exchange-alt"
        ],
        "icon_class": "badge-icon fa-exchange-alt",
      },
      {
        "docName": "Doc prova 4",
        "docDescr": "Doc prova 4",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 4",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 4
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "medal"
        ],
        "icon_class": "badge-icon fa-medal",
      },
      {
        "docName": "Doc prova 5",
        "docDescr": "Doc prova 5",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 5",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 5
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "box-open"
        ],
        "icon_class": "badge-icon fa-box-open",
      },
      {
        "docName": "Doc prova 6",
        "docDescr": "Doc prova 6",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 6",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 6
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "brain"
        ],
        "icon_class": "badge-icon fa-brain",
      },
      {
        "docName": "Doc prova 7",
        "docDescr": "Doc prova 7",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 6",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 7
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "user-shield"
        ],
        "icon_class": "badge-icon fa-user-shield",
      },
      {
        "docName": "Doc prova 8",
        "docDescr": "Doc prova 8",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 8",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 8
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "lock"
        ],
        "icon_class": "badge-icon fa-lock",
      },
      {
        "docName": "Doc prova 9",
        "docDescr": "Doc prova 9",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 9",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 9
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "map-marker-alt"
        ],
        "icon_class": "badge-icon fa-map-marker-alt",
      }
    ]);

    const visibleDocuments = ref([
      {
        "docName": "Doc prova 1",
        "docDescr": "Doc prova 1",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 1",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 1
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "industry"
        ],
        "icon_class": "badge-icon fa-industry",
      },
      {
        "docName": "Doc prova 2",
        "docDescr": "Doc prova 2",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 2",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 2
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "search"
        ],
        "icon_class": "badge-icon fa-search",
      },
      {
        "docName": "Doc prova 3",
        "docDescr": "Doc prova 3",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 3",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 3
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "exchange-alt"
        ],
        "icon_class": "badge-icon fa-exchange-alt",
      },
      {
        "docName": "Doc prova 4",
        "docDescr": "Doc prova 4",
        "timelineInfo": {
          "timelineLocation": "*Località da inserire*",
          "timelineName": "Doc prova 4",
          "timelineDescription": "Abbiamo brevettato il nostro processo alla fine del 2019, per estenderlo in PCT internazionale l'anno successivo. \nSono il processo e il suo brevetto a consentirci una produzione scalabile e sostenibile, senza consumo di suolo o emissioni di CO2.\n",
          "timelineImage": "https://imageshack.com/i/ponJ7wHNj",
          "timelineIndex": 4
        },
        "docTags": [
          {
            "tagName": "tag 1"
          },
          {
            "tagName": "tag 2"
          }
        ],
        "metaBlockchain": {
          "ipfs": "bafkreibs3ykdgg5wtvsmmyxznotfpqj47onutlrmcjx2pop3tine62tzby",
          "link": "https://ipfs.io/ipfs/bafkreiaezho44gfymiyxk4mwhvkeiwl26tbzpmi2qgh3ic4d4xjpt37p5y",
          "tx": "6d769c7aac0e204538fa75c32b4754e84985c3f6f0455ef7b7eb8003768b7a81"
        },
        "icon": [
          "fas",
          "medal"
        ],
        "icon_class": "badge-icon fa-medal",
      }
    ]);

    const shiftDocumentsRight = () => {
      let idx = docIdx.value -1;

      /* console.log("Document Index: ", docIdx.value);
      console.log("Selected Index: ", selectedIndex.value); */

      if(idx >= -1){
        if(selectedIndex.value != docIdx.value){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
    }

    const shiftDocumentsLeft = () => {

      let idx = docIdx.value + 1;

      /* console.log("Document Index: ", docIdx.value);
      console.log("Selected Index: ", selectedIndex.value); */

      if(idx < documents.value.length){
        if(idx > 3){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          /* console.log("Visible documents after: ", visibleDocuments.value); */

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
      
    }

    const loadPage = ref(false);
    const loadTimeline = ref(false);

    const tags = ref([ { tagName : "Lorem" }, { tagName : "Lorem" } ])

    const parameters = ref({
      "extBorderTickness": 0,
      "extBorderRadius": 6,
      "extBorderColor": "000000",
      "extBorderShadow": "f5f5f5",
      "primaryTextBodyColor": "000000",
      "primaryTextTitleColor": "000000",
      "hastagColor": "000000",
      "buttonBackgroundColor": "ffffff",
      "buttonIconColor": "949494",
      "backgroundColor": "ffffff"
    });

    const pageParameters = ref({
      'backgroundImgMobile' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png',
      'companyName' : 'Ohoskin',
      'primaryUpperText' : 'L’alternativa vegana e bio-based alla pelle animale, realizzata con un processo di economia circolare che sfrutta i sottoprodotti alimentari di arance e fichi d\'india siciliani.',
      'secondaryUpperText' : 'Per un lusso senza sensi di colpa',
      'upperTextBlockBottom' : '14',
      'lowerTextBlockBottom' : '20',
      'lowerBlockText' : 'Sostenibile, vegano, italiano e trasparente',
      'gifSrc' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png',
      'videoSrc' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png',
      'coverVideo' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png',
      'images' : [
      ],
      'presentationTextContent' : 'Scopri tutto il percorso che trasforma i sottoprodotti di arance e fichi d\'india siciliani in un materiale vegano alternativo alla pelle. Adesso è anche trasparente, perché tutta la filiera è tracciata tramite blockchain con Blockvision',
      'logoImg' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png',
      'messageLogo' : 'https://imagizer.imageshack.com/img922/8651/x6idX8.png'
    });

    const displayModalVideo = ref(false);

    const showInfo = ref(false);

    const openModalVideo = () => {
      displayModalVideo.value = true;
    };

    const displayModalFeedback = ref(false);

    const openModalFeedback = () => {
      displayModalFeedback.value = true;
    }

    const pageName = ref("");

    const selectedIndex = ref(0);
    const docIdx = ref(0);

    const selectedStep = ref();

    const selectStep = (index) => {
      docIdx.value = index;  

      if(index<4){
        selectedStep.value = (documents.value)[docIdx.value];
        selectedIndex.value = index;  
        
      }else{
        selectedStep.value = (documents.value)[docIdx.value];
      }

    }

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
       {
        label: 'Pagine',
        to: '/pagesV2/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    const setRemovable = ref(false);

    const removeFromPage = (id) => {
      productService.value.removePage(id).then( () => {
        loadPageProducts();
        setRemovable.value = true;
      })
    }

    const btnClass = ref('rounded-10 p-button-raised p-button-text p-button-plain p-mt-3 p-mb-4 p-py-4 p-px-4');
    const linkClass = ref('cursor-pointer no-underline dark-grey');
    const btnClicked = ref(false);

    const changeButtonColor = () => {
      if(!btnClicked.value){
        btnClass.value = 'rounded-10 p-button-raised p-button-text p-button-plain p-mt-3 p-mb-4 p-py-4 p-px-4 btn-grey';
        linkClass.value = 'cursor-pointer no-underline text-white';
        btnClicked.value = !btnClicked.value;
      }
    }

    const showHeaderBar = ref(true);

    const openMenu = () => {
      showHeaderBar.value = false;
      document.getElementById("menu-overlay").style.width = "100%";
    }

    const closeMenu = () => {
      showHeaderBar.value = true;
      document.getElementById("menu-overlay").style.width = "0%";
    }

    const scrollY = ref(0);
    const oldScroll = ref(0);

    window.onscroll = () => {
      // print "false" if direction is down and "true" if up
      scrollY.value = window.pageYOffset;
      oldScroll.value > scrollY.value ? showHeaderBar.value = true : showHeaderBar.value = false;
      oldScroll.value = scrollY.value;
    }
   
    return { account_id, pageParameters, documents, pageName, showInfo, home, items, page_products, removeFromPage, scrollY, oldScroll, openMenu, closeMenu,
      displayModalVideo, displayModalFeedback, openModalVideo, openModalFeedback, page_id, loadPage, loadTimeline, tags, showHeaderBar, changeButtonColor, btnClass, linkClass, btnClicked,
      parameters, selectedIndex, selectedStep, selectStep, visibleDocuments, shiftDocumentsRight, shiftDocumentsLeft, docIdx
    }
  }
};
</script>

<style scoped>
  .user-badge{
    width: 4rem !important;
    height: 4rem !important;
  }

  .btn-grey{
    background-color: rgb(172, 172, 172) !important;
    color: white !important;
  }

  .overlay {
    height: auto;
    text-align: center;
    width: 85%;
    position: absolute;
    left: 7.5%;
    z-index: 9;
    background-color: white;
  }

  .overlay-nav {
    top: 0;
    left: 0;
  }

  .navigator{
    background-color: rgba(111, 111, 111, 0.55) !important;
    margin: 0px;
  }

  .big-text{
    font-size: 15vw;
    font-weight: bold;
  }

  .timeline-badge-selected{
    background-color: rgb(75, 75, 75);
    color: white;
    height: 3.8rem !important;
    width: 3.8rem !important;
  }

  .timeline-badge{
    width: 3.4rem;
    height: 3.4rem;
  }

  .message{
    background-color: lightgrey;
    font-size: 3.9vw;
    position: relative;
  }

  .message:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-right: solid 23px transparent;
  }

  .message-2{
    position: relative;
  }

  .message-2:after {
    content:'';
    position: absolute;
    top: 94%;
    left: 80%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-left: solid 23px transparent;
  }


  ::v-deep(.p-avatar.p-avatar-xl){
    width: 8rem !important;
    height: 8rem !important;
  }

  ::v-deep(.p-avatar.p-avatar-lg){
    width: 6rem !important;
    height: 6rem !important;
  }
  
  ::v-deep(.p-dialog .p-dialog-content){
    padding: 0px !important;
  }
  
  .blink_me {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }

  ::v-deep(.pi .pi-angle-right .p-button-icon){
    font-size: 35px !important;
  }

  .pi .pi-angle-right .p-button-icon{
    font-size: 35px !important;
  }

  .menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .menu-link {
    padding: 8px;
    text-decoration: none;
    font-size: 8vw;
    color: #818181;
    display: block;
    transition: 0.3s;
    font-weight: bolder;
  }

  .menu-link:hover {
    color: #f1f1f1 !important;
  }

  .menu-link:focus {
    color: #f1f1f1 !important;
  }

  .menu-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 15vw;
  }

</style>

