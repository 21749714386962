<template>
  <main>
    <Toast />
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>
    <div>

      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end p-mt-3">
          <Button :disabled="!timelineName" label="Salva" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-md-2" style="font-size: 1.5em" @click="registerTimeline()"/>
        </div>

        <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2 p-mt-3">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
            <div class="p-col-12 p-grid p-p-3">

              <Message v-if="timeline_for == 1 && !ids.length" class="p-col-12" severity="info">Selezionando una o più linee la timeline verrà registrata per tutti i prodotti appartenenti.</Message>
              <Message v-if="timeline_for == 2 && !ids.length" class="p-col-12" severity="info">Selezionando una o più categorie la timeline verrà registrata per tutti i prodotti appartenenti.</Message>  
              <Message v-if="timeline_for == 3 && !ids.length" class="p-col-12" severity="info">Selezionando una o più prodotti la timeline verrà registrata per ognuno.</Message>  
              
              <Message v-if="timeline_for == 1 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'linea' : 'linee'}}.</Message>
              <Message v-if="timeline_for == 2 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'categoria' : 'categorie'}}.</Message>  
              <Message v-if="timeline_for == 3 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'prodotto' : 'prodotti'}}.</Message>  
              
              <div class="p-field p-col-12 p-md-6">
                <h3 for="timelineName" class="p-mb-3">Nome timeline</h3>
                <InputText id="" v-model="timelineName" type="text"/>
              </div>
              
              <div v-if="!timeline_id" class="p-field p-col-12 p-md-6">
                <div class="p-field-radiobutton">
                  <h3 class="p-mr-3">Imposta per: </h3>

                  <RadioButton id="line" name="timeline_for" :value=1 v-model="timeline_for" @change="loadOptions(timeline_for)"/>
                  <label for="line" class="p-mr-3">Linea</label>

                  <RadioButton id="category" name="timeline_for" :value=2 v-model="timeline_for" @change="loadOptions(timeline_for)"/>
                  <label for="category" class="p-mr-3">Categoria</label>

                  <RadioButton id="product" name="timeline_for" :value=3 v-model="timeline_for" @change="loadOptions(timeline_for)"/>
                  <label for="product" class="p-mr-3">Prodotto</label>
                </div>

                <MultiSelect v-if="timeline_for != 3" :filter="true" v-model="ids" :options="options" optionLabel="name" optionValue="id" placeholder="Select element" />

                <MultiSelect v-else v-model="ids" :filter="true"  :options="options" optionLabel="id_tag" optionValue="id" placeholder="Select element">
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option">
                        <div>{{option}}</div>
                    </div>
                    <template v-if="!slotProps.value || slotProps.value.length === 0">
                        Scegli prodotto
                    </template>
                  </template>
                  <template #option="slotProps">
                    <div>{{slotProps.option.metadata.name}} 
                      <span v-if="slotProps.option.id_tag">
                        <span v-if="slotProps.option.metadata.name">({{slotProps.option.id_tag}})</span>
                        <span v-else>{{slotProps.option.id_tag}}</span>
                      </span>
                    </div>
                  </template>
                </MultiSelect>

              </div>
            </div>
          </div>
          
        </div>

        <div class="p-grid p-col-12 p-mt-5 p-ml-1">

          <div class="p-col-12 p-xl-4">

            <timeline-panel-V2
              docName="Lorem ipsum" timelineLocation="Inserire location" timelineName="Lorem ipsum" timelineDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
              :tags="tags" :panelClass="'p-col-12 p-grid p-jc-center'" imageUrl="https://imageshack.com/i/ponJ7wHNj" :parameters="parameters" documentUrl="" blockchainLink=""
            >
            </timeline-panel-V2>

          </div>

          <div class="p-col-12 p-xl-8">
            <div class="p-fluid p-formgrid p-grid p-col-12 p-jc-between">

              <!-- Testi -->
              <div class="p-field p-col-12">
                <Fieldset class="overflow-break info-blockchain" legend="Testi" :toggleable="true" :collapsed="true">
                  <ScrollPanel style="width: 100%; height: 40vh">
                    <div class="p-grid p-mt-3">

                      <h3 class="p-col-12 p-mb-3">Primario</h3>

                      <div class="p-col-12 p-lg-6">
                        <label for="primaryTextTitleColor">Titolo</label>
                        <InputText id="primaryTextTitleColor" v-model="parameters.primaryTextTitleColor" type="text"/>
                        <ColorPicker class="p-mt-3" id="primaryTextTitleColor" v-model="parameters.primaryTextTitleColor" :inline="true" />
                      </div> 
                      <div class="p-col-12 p-lg-6">
                        <label for="primaryTextBodyColor">Corpo</label>
                        <InputText id="primaryTextBodyColor" v-model="parameters.primaryTextBodyColor" type="text"/>
                        <ColorPicker class="p-mt-3" id="primaryTextBodyColor" v-model="parameters.primaryTextBodyColor" :inline="true" />
                      </div> 

                      <h3 class="p-col-12 p-mb-3 p-mt-5">Hastags</h3>
                      
                      <div class="p-col-12 p-lg-6">
                        <label for="secondaryTextTitleColor">Corpo</label>
                        <InputText id="secondaryTextTitleColor" v-model="parameters.hastagColor" type="text"/>
                        <ColorPicker class="p-mt-3" id="secondaryTextTitleColor" v-model="parameters.hastagColor" :inline="true" />
                      </div> 

                    </div>
                  </ScrollPanel>
                </Fieldset>
              </div>

              <!-- Sfondi -->
              <div class="p-field p-col-12">
                <Fieldset class="overflow-break info-blockchain" legend="Sfondi" :toggleable="true" :collapsed="true">
                  <ScrollPanel style="width: 100%; height: 40vh">
                    <div class="p-grid p-mt-3">

                      <h3 class="p-col-12 p-mb-3">Bottoni</h3>

                      <div class="p-col-12 p-lg-6">
                        <label for="primaryTextTitleColor">Sfondo</label>
                        <InputText id="primaryTextTitleColor" v-model="parameters.buttonBackgroundColor" type="text"/>
                        <ColorPicker class="p-mt-3" id="primaryTextTitleColor" v-model="parameters.buttonBackgroundColor" :inline="true" />
                      </div> 

                      <div class="p-col-12 p-lg-6">
                        <label for="primaryTextBodyColor">Icona</label>
                        <InputText id="primaryTextBodyColor" v-model="parameters.buttonIconColor" type="text"/>
                        <ColorPicker class="p-mt-3" id="primaryTextBodyColor" v-model="parameters.buttonIconColor" :inline="true" />
                      </div> 

                    </div>
                  </ScrollPanel>
                </Fieldset>
              </div>

              <!-- Bordi -->
              <div class="p-field p-col-12">
                
                  <Fieldset class="overflow-break info-blockchain" legend="Bordi e ombre" :toggleable="true" :collapsed="true">
                    <ScrollPanel style="width: 100%; height: 40vh">
                      <div class="p-grid p-mt-3">
                      
                        <!-- Bordo esterno -->
                        <h3 class="p-col-12">Esterno</h3>

                        <div class="p-mt-3 p-col-12 p-lg-6">
                          <label for="extBorderTickness">Spessore</label>
                          <InputText id="extBorderTickness" v-model.number="parameters.extBorderTickness" />
                          <Slider v-model="parameters.extBorderTickness" :min="0" :max="5" :step="0.2"/>
                        </div>

                        <div class="p-mt-3 p-col-12 p-lg-6">
                          <label for="extBorderRadius">Curvatura</label>
                          <InputText id="extBorderRadius" v-model.number="parameters.extBorderRadius" />
                          <Slider v-model="parameters.extBorderRadius" :min="0" :max="20"/>
                        </div>

                        <div class="p-mt-3 p-col-12 p-lg-6">
                          <label for="extBorderColor">Colore</label>
                          <InputText id="extBorderColor" v-model="parameters.extBorderColor" type="text"/>
                          <ColorPicker class="p-mt-3" id="extBorderColor" v-model="parameters.extBorderColor" :inline="true" />
                        </div>

                        <div class="p-mt-3 p-col-12 p-lg-6">
                          <label for="extBorderShadow">Sfumatura</label>
                          <InputText id="" v-model="parameters.extBorderShadow" type="text"/>
                          <ColorPicker class="p-mt-3" id="extBorderShadow" v-model="parameters.extBorderShadow" :inline="true" />
                        </div>

                      </div>   
                    </ScrollPanel>
                  </Fieldset>

              </div>

            </div>
          </div>

        </div>
        
      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import ColorPicker from 'primevue/colorpicker';
import Slider from 'primevue/slider';
import Fieldset from 'primevue/fieldset';
import Toast from 'primevue/toast';
import ScrollPanel from 'primevue/scrollpanel';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';

import NavigationBar from '../components/NavigationBar';
import TimelinePanelV2 from '../components/TimelinePanelV2';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Toast, RadioButton, Message, InputText, Slider, MultiSelect, TimelinePanelV2, ColorPicker, Fieldset, Button, ScrollPanel },
  setup(){

    onMounted(() => {
      loadOptions();
      setBreadCrumb();
    })

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const timeline_id = route_params.split('&')[1];

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const timelineName = ref();

    const configureTimeline = ref(false);

    const timeline_for = ref(1);

    const options = ref([]);

    const parameters = ref({
      "extBorderTickness" : 0,
      "extBorderRadius" : 6,
      "extBorderColor" : "000000",
      "extBorderShadow" : "f5f5f5",

      "primaryTextBodyColor" : "000000",
      "primaryTextTitleColor" : "000000",
      "hastagColor" : "000000",

      "buttonBackgroundColor" : "ffffff",
      "buttonIconColor" : "949494",
      "backgroundColor" : "ffffff"
    });

    const router = useRouter();

    const toast = useToast();

    const tags = ref([ { tagName : "Lorem" }, { tagName : "Lorem" } ])

    const ids = ref([]);

    if(timeline_id){
      accountService.value.getTimelineById(timeline_id).then(data => {
        /* console.log(data); */
        if(data.docs[0]){
          setTimeline(data.docs[0]);
        }
      })
    }

    const registerTimeline = () => {
      accountService.value.registerTimeline(account_id, ids.value, timeline_for.value, timeline_id, parameters.value, timelineName.value);
      router.go();
    }

    const loadOptions = () => {
      switch (timeline_for.value) {
        case 1:
          accountService.value.getProductLines(account_id).then(data => {
            /* console.log("DATA: ", data); */
            options.value = data;
          })
          break;

        case 2:
          accountService.value.getProductCategories(account_id).then(data => {
            /* console.log("DATA: ", data); */
            options.value = data;
          })
          break;

        case 3:
          productService.value.getProducts(account_id, 10000000).then(data => {
            /* console.log("DATA: ", data.docs); */
            options.value = data.docs;
          })
          break;
      
        default:
          break;
      }
    }

    const setTimeline = (data) => {

      /* console.log("Data: ",data); */

      timelineName.value = data.timeline_name;

      parameters.value = data.parameters;
      
    }

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([]);

    const setBreadCrumb = () => {
      if(timeline_id){
        (items.value).push(
          {
            label: 'Modifica timeline'
          }
        )
        toast.add({severity: 'warn', summary: 'Timeline già registrata', detail: "Ogni modifica sostituirà la configurazione corrente", life: 30000});
      }else{
        (items.value).push(
          {
            label: 'Crea timeline'
          }
        )
      }
    }

    const liStyle = ref('list-style-type: none;');

    return { 
      account_id, options, tags, timelineName, timeline_id, registerTimeline, loadOptions,
      liStyle, home, items, configureTimeline, timeline_for, ids, parameters
    }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }

  ::v-deep(.timeline-badge) {
    display: none !important;
  }

  ::v-deep(.step-small-text){
    font-size: 0.9vw !important;
  }

  ::v-deep(.step-small-icon){
    font-size: 0.7vw !important;
  }

  ::v-deep(.step-description-text){
    font-size: 1.1vw !important;
  }

</style>

