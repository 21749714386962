<template>
  <main>
    <!-- <navigation-bar></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>
    <Toast />
    <ConfirmDialog></ConfirmDialog>

    <div class="card">
        <TabView class="tabview-custom" ref="tabview1" :activeIndex="active">
          <TabPanel>
            <template #header>
              <i class="p-mr-2 pi pi-pencil"></i>
              <span>Informazioni</span>
            </template>

            <div class="p-fluid p-formgrid p-col-12 p-grid">
              <div class="p-col-12">
                <h3>Nome collezione</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="collection_name" id="collection_name" type="text" placeholder="Nome collezione" :disabled="info_set"/>
                </span>
                <Message v-if="!info_set" severity="warn"><b>IMPORTANTE: </b>Il nome inserito comparirà come nome della collezione e non sarà più modificabile una volta scelto.</Message>

                <h3 class="p-mt-5">Nome contract</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="contract_name" id="contract_name" type="text" placeholder="Nome contract" :disabled="info_set"/>
                </span>
                <Message v-if="!info_set" severity="info">Il nome del contract verrà ti permetterà di visualizzarlo facilmente all'interno del software. Non verrà mostrato in altri contesti.</Message>
              </div>
            </div>

            <div class="p-col-2">
              <Button v-if="contract_name && collection_name" label="Salva" icon="pi pi-save" class="purple purple-btn" style="font-size: 1.5em" @click="confirmInfo()" :disabled="info_set"/>
            </div>
          </TabPanel>

          <TabPanel>
            <template #header>
              <i class="p-mr-2 pi pi-file"></i>
              <span>File</span>
            </template>

            <div class="p-fluid p-formgrid p-col-12 p-grid p-px-0 p-mb-5">
              <div class="p-col-6">
                <h3>Pinata Key</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText :disabled="file_set" v-model="pinata_key" id="pinata_key" type="text" placeholder="Key"/>
                  </span>
              </div>

              <div class="p-col-6">
                <h3>Pinata Secret</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText :disabled="file_set" v-model="pinata_secret" id="pinata_secret" type="text" placeholder="Secret"/>
                  </span>
              </div>
            </div>

            <Message v-if="!file_set" severity="info">Selezionando <span>"<i class="p-mr-2 pi pi-upload"></i>Carica"</span> i file scelti saranno solo mostrati in anteprima. Per confermare la scelta cliccare il tasto <span>"<i class="p-mr-2 pi pi-save"></i>Salva"</span></Message>

            <FileUpload id="document" name="file" @uploader="onUpload" :customUpload="true" :multiple="true" :maxFileSize="1000000" :auto="true">

              <template #empty>
                <p>Trascina o scegli i file da caricare</p>
              </template>
            </FileUpload>

            <div class="p-col-2 p-mt-4 p-px-0">
              <Button label="Salva" icon="pi pi-save" class="purple purple-btn" style="font-size: 1.5em" @click="confirmFiles()" :disabled="file_set || !(pinata_secret && pinata_key)"/>
            </div>

            

          </TabPanel>

          <TabPanel>
            <template #header>
              <i class="p-mr-2 pi pi-shopping-cart"></i>
              <span>Pubblica</span>
            </template>
            
            <Message v-if="!file_set" severity="info">Gli NFT saranno pubblicati con denominazione numerica crescente da 1 al numero totale.</Message>
            <nft-table v-if="show_preview" :NFTs="NFTs" :show_mint="true"></nft-table>

            <div class="p-mt-4 p-px-0 p-grid p-jc-center">
              <div class="p-col-3">
                <Button label="Pubblica tutti" icon="pi pi-shopping-cart" class="purple purple-btn" style="font-size: 1.5em" @click="publishAll()"/>
              </div>
            </div>

          </TabPanel>

        </TabView>
    </div> -->

  </main>
</template>

<script>

/* import NavigationBar from '../../../components/NavigationBar';
import NftTable from '../components/NftTable.vue';
import FileUpload from 'primevue/fileupload';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Breadcrumb from 'primevue/breadcrumb';

import 'primeicons/primeicons.css';

import { ref, onMounted } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, FileUpload, Button, TabPanel, TabView, InputText, Message, ConfirmDialog, Toast, NftTable },
  setup(){

    onMounted(() => {

    });

    const home = ref({
        icon: 'pi pi-shopping-cart'
    });

    const items = ref([
      {label: 'Lista collezioni'},
    ]);

    const NFTs = ref([]);

    const collection_name = ref("");
    const contract_name = ref("");

    const pinata_key = ref("");
    const pinata_secret = ref("");

    const active = ref(0);

    const info_set = ref(false);
    const file_set = ref(false);

    const confirm = useConfirm();
    const toast = useToast();

    const confirmInfo = () => {
      confirm.require({
        message: 'Vuoi salvere le informazioni? Una volta effettuata la transazioni queste non saranno più modificabili e il contratto sarà pubblicato',
        header: 'Conferma',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
            info_set.value = true;
            active.value = 1;
        },
        reject: () => {
            toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
        }
      });
    }

    const confirmFiles = () => {
      confirm.require({
        message: 'Una volta caricati i file su IPFS questi diventeranno gli NFT della collezione e non saranno modificabili.',
        header: 'Conferma',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
            file_set.value = true;
            active.value = 2;
        },
        reject: () => {
            toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
        }
      });
    }

    const show_preview = ref(false);

    const onUpload = (event) => {
      console.log("Files: ", event.files);
      let index = 0;
      event.files.forEach(e => {
        index = index+1;
        NFTs.value.push(
          {
          "name": "#"+index,
          "image": e.objectURL,
          },
        )
      });
      show_preview.value = true;

    }

    return { home, items, collection_name, contract_name, confirmInfo, confirmFiles, onUpload, show_preview, info_set, file_set, active, pinata_key, pinata_secret, NFTs }
  }
}; */
</script>

<style scoped>
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
  }
  .p-dropdown {
    width: 14rem;
    font-weight: normal;
  }

  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .product-description {
    margin: 0 0 1rem 0;
  }

  .product-category-icon {
    vertical-align: middle;
    margin-right: .5rem;
  }

  .product-category {
    font-weight: 600;
    vertical-align: middle;
  }

  ::v-deep(.product-list-item) {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }

  ::v-deep(.product-list-item img) {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

   ::v-deep(.product-list-item .product-list-detail) {
      flex: 1 1 0;
    }

   ::v-deep(.product-list-item .product-price) {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: .5rem;
    align-self: flex-end;
  }

   ::v-deep(.product-list-item .product-list-action) {
    display: flex;
    flex-direction: column;
  }

   ::v-deep(.product-list-item .p-button) {
    margin-bottom: .5rem;
  }
  

  ::v-deep(.product-grid-item) {
    margin: .5rem;
    border: 1px solid var(--surface-border);
  }

  ::v-deep(.product-grid-item .product-grid-item-top){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
    
  ::v-deep(.product-grid-item .product-grid-item-bottom) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::v-deep(.product-grid-item img) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
    max-width: 100%;
    border-radius: 5%;
  }

  ::v-deep(.product-grid-item .product-grid-item-content) {
    text-align: center;
  }

  ::v-deep(.product-grid-item .product-price) {
    font-size: 1.5rem;
    font-weight: 600;
  }

</style>

