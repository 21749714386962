<template>
  <main style="overflow-x: hidden">

    <Toast />
    <div class="p-grid">
      <div class="p-col-8">
        <navigation-bar :account_id="account_id"></navigation-bar>
        <Breadcrumb :home="home" :model="items"/>

        <div class="p-mx-3 p-grid p-jc-between">

          <div class="p-col-12 p-d-flex p-jc-end p-mt-3">
            <Button :disabled="!pageName" label="Salva" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-md-2" style="font-size: 1.5em" @click="registerPage()"/>
          </div>

          <ScrollPanel style="width: 100%; height: 70vh;" >

            <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2 p-mt-3">

              <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
                <div class="p-col-12 p-grid p-p-3">

                  <Message v-if="pageFor == 1 && !ids.length" class="p-col-12" severity="info">Selezionando una o più linee la timeline verrà registrata per tutti i prodotti appartenenti.</Message>
                  <Message v-if="pageFor == 2 && !ids.length" class="p-col-12" severity="info">Selezionando una o più categorie la timeline verrà registrata per tutti i prodotti appartenenti.</Message>  
                  <Message v-if="pageFor == 3 && !ids.length" class="p-col-12" severity="info">Selezionando una o più prodotti la timeline verrà registrata per ognuno.</Message>  
                  
                  <Message v-if="pageFor == 1 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'linea' : 'linee'}}.</Message>
                  <Message v-if="pageFor == 2 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'categoria' : 'categorie'}}.</Message>  
                  <Message v-if="pageFor == 3 && ids.length" class="p-col-12" severity="info">Hai selezionato <b>{{ids.length}}</b> {{ids.length == 1 ? 'prodotto' : 'prodotti'}}.</Message>  
                  
                  <div class="p-field p-col-12 p-md-6">
                    <h3 for="pageName" class="p-mb-3">Nome pagina</h3>
                    <InputText id="" v-model="pageName" type="text" placeholder="Nome"/>
                  </div>
                  
                  <div v-if="!page_id" class="p-field p-col-12 p-md-6">
                    <div class="p-field-radiobutton">
                      <h3 class="p-mr-3">Imposta per: </h3>

                      <RadioButton id="line" name="pageFor" :value=1 v-model="pageFor" @change="loadOptions(pageFor)"/>
                      <label for="line" class="p-mr-3">Linea</label>

                      <RadioButton id="category" name="pageFor" :value=2 v-model="pageFor" @change="loadOptions(pageFor)"/>
                      <label for="category" class="p-mr-3">Categoria</label>

                      <RadioButton id="product" name="pageFor" :value=3 v-model="pageFor" @change="loadOptions(pageFor)"/>
                      <label for="product" class="p-mr-3">Prodotto</label>
                    </div>

                    <MultiSelect v-if="pageFor != 3" :filter="true" v-model="ids" :options="options" optionLabel="name" optionValue="id" placeholder="Select element" />

                    <MultiSelect v-else v-model="ids" :filter="true"  :options="options" optionLabel="id_tag" optionValue="id" placeholder="Select element">
                      <template #value="slotProps">
                        <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option">
                            <div>{{option}}</div>
                        </div>
                        <template v-if="!slotProps.value || slotProps.value.length === 0">
                            Scegli prodotto
                        </template>
                      </template>
                      <template #option="slotProps">
                        <div>{{slotProps.option.metadata.name}} 
                          <span v-if="slotProps.option.id_tag">
                            <span v-if="slotProps.option.metadata.name">({{slotProps.option.id_tag}})</span>
                            <span v-else>{{slotProps.option.id_tag}}</span>
                          </span>
                        </div>
                      </template>
                    </MultiSelect>

                  </div>
                </div>
              </div>
              
            </div>

            <div class="p-grid p-col-12 p-jc-between">

              <Message class="p-col-12 p-mx-2" severity="warn">L'aspetto effettivo della pagina varierà in base alla dimensione effettiva del dispositivo.</Message>

              <div class="p-fluid p-formgrid p-grid p-col-12 p-jc-between">

                  <!-- Pannello superiore -->
                  <div class="p-field p-col-12">
                    <Fieldset class="overflow-break info-blockchain" legend="Blocco superiore" :toggleable="true" :collapsed="true">
                      <ScrollPanel style="width: 100%; height: 40vh">
                        <div class="p-grid p-mt-3 p-mb-5">

                          <h3 class="p-col-12 p-mb-3">Contenuto</h3>

                          <div class="p-col-12 p-lg-6">
                            <label for="backgroundImgMobile">Immagine</label>
                            <InputText id="backgroundImgMobile" v-model="pageParameters.backgroundImgMobile" type="text" placeholder="Link"/>
                          </div> 

                          <div class="p-col-12 p-lg-6">
                            <label for="companyName">Nome azienda</label>
                            <InputText id="companyName" v-model="pageParameters.companyName" type="text" placeholder="Nome"/>
                          </div> 

                          <div class="p-col-12 p-lg-6 p-mt-5">
                            <label for="primaryUpperText">Testo principale</label>
                            <TextArea rows="5" id="primaryUpperText" v-model="pageParameters.primaryUpperText" type="text" placeholder="Testo"/>
                          </div> 

                          <div class="p-col-12 p-lg-6 p-mt-5">
                            <label for="secondaryUpperText">Testo secondario</label>
                            <TextArea rows="5" id="secondaryUpperText" v-model="pageParameters.secondaryUpperText" type="text" placeholder="Testo"/>
                          </div> 

                          <div class="p-col-12 p-lg-6 p-mt-5">
                            <label for="buttonText">Testo bottone</label>
                            <InputText id="buttonText" v-model="pageParameters.buttonText" type="text" placeholder="Testo"/>
                          </div> 

                          <div class="p-col-12 p-lg-6 p-mt-5">
                            <label for="lowerBlockText">Testo inferiore</label>
                            <TextArea rows="5" id="lowerBlockText" v-model="pageParameters.lowerBlockText" type="text" placeholder="Testo"/>
                          </div> 

                          <h3 class="p-col-12 p-mb-3 p-mt-5">Layout</h3>

                          <div class="p-mt-3 p-col-12 p-lg-6">
                            <label for="upperTextBlockBottom">Altezza blocco di testo superiore (0-100)</label>
                            <InputText id="upperTextBlockBottom" v-model.number="pageParameters.upperTextBlockBottom" />
                            <!-- <Slider v-model="pageParameters.upperTextBlockBottom" :min="0" :max="100" :step="0.5"/> -->
                          </div>

                          <div class="p-mt-3 p-col-12 p-lg-6">
                            <label for="lowerTextBlockBottom">Altezza blocco di testo inferiore (0-100)</label>
                            <InputText id="lowerTextBlockBottom" v-model.number="pageParameters.lowerTextBlockBottom" />
                            <!-- <Slider v-model="pageParameters.lowerTextBlockBottom" :min="0" :max="100" :step="0.5"/> -->
                          </div>

                        </div>
                      </ScrollPanel>
                    </Fieldset>
                  </div>

                  <!-- Immagini -->
                  <div class="p-field p-col-12">
                    <Fieldset class="overflow-break info-blockchain" legend="Contenuti grafici" :toggleable="true" :collapsed="true">
                      <ScrollPanel style="width: 100%; height: 40vh">
                        <div class="p-grid p-mt-3 p-mb-5">

                          <h3 class="p-col-12 p-mb-3">Gif</h3>

                          <div class="p-col-12">
                            <InputText id="gifSrc" v-model="pageParameters.gifSrc" type="text" placeholder="Link"/>
                          </div> 

                          <Message class="p-col-12 p-mt-5" severity="info">Per una migliore resa grafica usare immagini in formato quadrato e tutte della stessa dimensione.</Message>

                          <div class="p-d-flex p-col-12 p-ai-center">
                            <h3 class="">Immagini</h3>
                            <Button icon="pi pi-plus" class="p-ml-4 p-button-rounded purple-diagonal" @click="addImage()"/>
                          </div>

                          <div class="p-grid p-col-12 p-mt-3">

                            <div class="p-col-4 p-mb-3" v-for="(input, index) in pageParameters.images" :key='index'>
                              
                              <div class="p-field p-col-12 p-jc-center">

                                <InputText id="docName" type="text" placeholder="Link" v-model="input.itemImageSrc"/>
                                <InputText class="p-mt-2" id="docName" type="text" placeholder="Titolo" v-model="input.title"/>
                                <InputText class="p-mt-2" id="docName" type="text" placeholder="Descrizione" v-model="input.description"/>
                                <InputText class="p-mt-2" id="docName" type="text" placeholder="Anteprima" v-model="input.preview"/>

                                <Button label="Rimuovi immagine" icon="pi pi-trash" class="p-mt-2 purple-btn purple-diagonal" @click="removeImage(index)"/>
                              </div>

                            </div>
                          </div>

                          <Message class="p-col-12 p-mt-5" severity="info">Per una migliore resa grafica si consiglia di scegliere una immagine di anteprima in formato quadrato e delle stesse dimensioni di quelle precedenti.</Message>

                          <h3 class="p-col-12 p-mb-3">Video</h3>

                          <div class="p-col-12 p-lg-6">
                            <label for="videoSrc">Contenuto</label>
                            <InputText id="videoSrc" v-model="pageParameters.videoSrc" type="text" placeholder="Link"/>
                          </div> 

                          <div class="p-col-12 p-lg-6">
                            <label for="coverVideo">Anteprima</label>
                            <InputText id="coverVideo" v-model="pageParameters.coverVideo" type="text" placeholder="Link"/>
                          </div> 

                        </div>
                      </ScrollPanel>
                    </Fieldset>
                  </div>
                  
                  <!-- Sezione Timeline -->
                  <div class="p-field p-col-12">
                    <Fieldset class="overflow-break info-blockchain" legend="Blocco inferiore" :toggleable="true" :collapsed="true">
                      <ScrollPanel style="width: 100%; max-height: 40vh">
                        <div class="p-grid p-mt-3 p-mb-5">

                          <h3 class="p-col-12 p-mb-3">Testo informativo<i class="pi pi-info-circle p-ml-2" style="font-size: 1vw !important;"></i></h3>
                          <div class="p-col-12">
                            <TextArea id="presentationTextContent" rows="5" v-model="pageParameters.presentationTextContent" type="text" placeholder="Link"/>
                          </div>

                          <h3 class="p-col-12 p-mb-3 p-mt-5">Logo</h3>
                          <div class="p-col-12">
                            <InputText id="logoImg" v-model="pageParameters.logoImg" type="text" placeholder="Link"/>
                          </div> 

                          <h3 class="p-col-12 p-mb-3 p-mt-5">Icona messaggio</h3>
                          <div class="p-col-12">
                            <InputText id="messageLogo" v-model="pageParameters.messageLogo" type="text" placeholder="Link"/>
                          </div> 

                        </div>
                      </ScrollPanel>
                    </Fieldset>
                  </div>

              </div>

            </div>

          </ScrollPanel>

        </div>

      </div>

      <div class="p-col-4">
        <ScrollPanel style="width: 100%; height: 100vh;" >

          <div v-if="loadPage" class="">
            
            <div class="p-d-flex p-jc-center p-ai-center p0" :style="{ background: 'url(\''+pageParameters.backgroundImgMobile+'\')', height: '70vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative'}">

              <div class="overlay p-p-1 rounded-35 text-grey p-shadow-5" :style="{ bottom: '-'+pageParameters.upperTextBlockBottom+'%'}">
                <div class="p-text-center">
                  <div class="p-mb-4 p-mt-4">
                    <Image class="" :imageStyle="{ maxWidth: '35%' }" :src="pageParameters.logoImg" alt="Image"/>
                  </div>
                  <h4 class="p-mx-4" style="font-weight: unset !important;">{{pageParameters.primaryUpperText}}</h4>
                  <h4 class="p-mx-4 p-mt-3">{{pageParameters.secondaryUpperText}} <br/><br/></h4>
                </div>
                <div class="p-text-center">
                    <a href="#timeline" :class="linkClass" v-smooth-scroll>
                      <Button :class="btnClass" style="font-size: 1.3em" @click="changeButtonColor()">
                        <span class="blink_me">{{pageParameters.buttonText}}</span>
                      </Button>      
                    </a>
                </div>
              </div>

            </div>
            
            <div class="p-grid p-mx-3 p-ai-center p-jc-center" :style="{ marginTop: pageParameters.lowerTextBlockBottom+'%'}">
              <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
                | <br />
                | <br />
                | <br />
                | <br />
                <font-awesome-icon style="font-size: 4vw;" class="badge-icon dark-grey" :icon="['fas', 'arrow-circle-down']"/>
              </h3>
              
              <div class="p-col-10 p-mt-5 p-mb-5">
                <h2 class="text-grey p-text-center">{{pageParameters.lowerBlockText}}</h2>
              </div>
            </div>

            <div v-if="pageParameters.gifSrc" class="p-mt-3">
              <Image  :imageStyle="{ width: '100%' }" :src="pageParameters.gifSrc" alt="Image"/>
            </div>

            <div class="p-grid p-mx-3 p-ai-center p-jc-center">
              <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
                | <br />
                | <br />
                | <br />
                | <br />
                <!-- <font-awesome-icon style="font-size: 13vw;" class="badge-icon dark-grey" :icon="['fas', 'arrow-circle-down']"/> -->
              </h3>
              
              <div class="p-col-10 text-grey p-jc-center p-text-center pb0" >
                <h3 class="p-mx-4">Scopri tutta la filiera</h3>
                <div class="big-text">{{pageParameters.companyName}}</div>
              </div>
            </div>

            <!-- Dialog 1 Mobile-->
            <Dialog class="px0 py0" :contentStyle="{padding: '0px !important'}" v-model:visible="displayModalVideo" :style="{width: '100vw'}" :modal="true" :maximizable="true">
              <div class="player-container">
                <vue3-video-player :src="pageParameters.videoSrc"></vue3-video-player>
              </div>
            </Dialog>

          </div>

          <div v-if="loadTimeline" id="timeline" class="p-mb-5">


            <div>

              <div class="p-d-flex p-jc-center pt0 p0">
                
                  <div class="p-grid p-col-12 p-jc-center p-mt-2 p-ai-start py0 px0">
                    
                    <div v-if="(selectedIndex>0)" class="p-col-2 p-mb-1">
                      <Button class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-left" style="margin-top: 0.46rem;"  @click="shiftDocumentsRight()"/>
                    </div>
                    <div v-else class="p-col-2 p-mb-1">
                      <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="" style="margin-top: 0.46rem; color: white !important;"/>
                    </div>

                    <div class="p-col-8 pb0 p-d-flex p-jc-center">
                      <div class="py0 p-d-flex p-jc-center p-text-center" v-for="(input, index) in visibleDocuments" :key='index'>
                        <div class="p-jc-center p-ai-center">
                          <Avatar v-if="index==selectedIndex" class="cursor-pointer p-mx-1 timeline-badge-selected timeline-badge" shape="circle">
                            <font-awesome-icon :class="input.icon_class" :icon="input.icon" />
                          </Avatar>
                          <Avatar v-else class="cursor-pointer p-mx-1 timeline-badge" shape="circle" style="margin-top: 0.2rem;">
                            <font-awesome-icon :class="input.icon_class" :icon="input.icon" />
                          </Avatar>
                          <div v-if="index==selectedIndex" class="dark-grey" style="font-weight: bolder !important;">
                            | <br />
                            | <br />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div v-if="(docIdx<=documents.length-2)" class="p-col-2 p-mb-1">
                      <Button v-if="(docIdx<=documents.length-2)" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.46rem;"  @click="shiftDocumentsLeft()"/>
                    </div>
                    <div v-else class="p-col-2 p-mb-1">
                      <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.46rem; color: white !important;"/>
                    </div>

                  </div>

              </div>

            </div>

            <div v-if="selectedIndex!=-1" class="p-grid p-jc-center p-mt-2">
              <timeline-panel-V2
                :docName="selectedStep.docName" :timelineLocation="selectedStep.timelineInfo.timelineLocation" :timelineName="selectedStep.timelineInfo.timelineName" :timelineDescription="selectedStep.timelineInfo.timelineDescription"
                :tags="selectedStep.docTags" :panelClass="'p-col-9 p-grid p-jc-center box'" :imageUrl="selectedStep.timelineInfo.timelineImage" :parameters="parameters" :documentUrl="selectedStep.metaBlockchain.link" :blockchainLink="selectedStep.metaBlockchain.tx"
              >
              </timeline-panel-V2>

            </div>
            
            <div class="p-grid p-mx-2 p-mt-5">

              <Galleria :value="pageParameters.images" :numVisible="5" :showThumbnails="false" :circular="true" :showItemNavigators="true">
                <template #item="{item}">
                  <img :src="item.itemImageSrc" :alt="item.alt" style="width: 100%; display: block;" />
                </template>
                <template #caption="{item}">
                  <h4 style="margin-bottom: .5rem;">{{item.title}}</h4>
                  <p>{{item.description}}</p>
                </template>
              </Galleria>

            </div>

            <h3 class="p-col-12 p-text-center dark-grey" style="font-weight: bolder !important;">
              | <br />
              | <br />
              | <br />
              | <br />
              <font-awesome-icon style="font-size: 4vw;" class="badge-icon dark-grey" :icon="['fas', 'arrow-circle-down']"/>
            </h3>

            <div v-if="loadPage" id="contact-us" class="p-grid p-jc-center">
            <!-- <div v-if="loadPage" id="contact-us" class="p-grid p-jc-center" @click="openModalFeedback()"> -->
              <div class="p-col-10 p-grid p-shadow-5 p-mt-5 rounded-20 p-ai-center p-jc-center">
                <div class="p-col-4 p-text-center">
                  <Image class="" :imageStyle="{ maxWidth: '100%' }" :src="pageParameters.logoImg" alt="Image"/>
                </div>
                <div class="p-col-4 p-text-center">
                  <font-awesome-icon style="font-size: 4vw; color: darkgrey;" class="badge-icon fa-comment-dots" :icon="['fas', 'comment-dots']"/>
                </div>
              </div>
            </div>
            
          </div>

          <Dialog class="p-p-0" :show-header="false" v-model:visible="displayModalFeedback" style="width: 80vw; border-radius: 20px !important;" contentStyle="border-radius: 20px !important;" :modal="true" :maximizable="false">
            <div class="p-mt-4">
              <i class="pi pi-times" @click="displayModalFeedback=false"></i>
            </div>
            <div v-if="!messageSent" class="p-mt-5 p-mb-5">
              <div class="p-grid p-jc-end">
                <div class="p-col-9 message rounded-10 p-py-3 p-px-3">
                  Cerchi altre informazioni?
                </div>
              </div>
              <div>
                <Avatar class="cursor-pointer user-badge" shape="circle">
                  <Image class="" :imageStyle="{ maxWidth: '80%' }" :src="pageParameters.messageLogo" alt="Image"/>
                </Avatar>
              </div>

              <div class="p-mt-5 p-mb-2">
                <small>Siamo pronti a risponderti!</small>
              </div>

              <div class="p-grid p-jc-start py0 px0 p-ai-end message-2">

                <div class="p-col-10 p-grid" style="margin-left: 0px !important;">
                  <div class="p-mt-2 p-col-5 p-d-flex pl0 py0 p-mb-1">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Città" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-mt-2 p-col-7 p-d-flex pr0 py0 p-mb-1 pl0">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Mario Rossi" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-col-12 p-d-flex px0 py0">
                    <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="E-mail" style="background-color: lightgrey; border: none;"/>
                  </div>
                  <div class="p-col-12 p-mt-1 px0 py0">
                    <TextArea class="rounded-10 p-col-12" v-model="message" id="message" type="text" rows="5" placeholder="Messaggio..." style="background-color: lightgrey; border: none;"/>        
                  </div>
                </div>

                <div class="p-col-2 p-mb-1">
                  <Button class="p-mb-1 p-ml-1" icon="pi pi-send" style="font-size: 1.5em; background-color: #A7B182; color: white;"  @click="sendMessage()"/>
                </div>

              </div>
            </div>

          </Dialog>

          <div class="p-grid p-jc-center p-mt-5" style="margin-bottom: 4rem !important;">

            <div v-if="!showInfo" class="p-col-10 p-grid p-jc-center p-mt-5">
              <div class="p-grid p-col-8 p-ai-center pb0" >
                <i class="p-col-2 pi pi-check-circle px0"></i>
                <Image class="p-col-10 px0" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img924/2817/CBjoQz.png" alt="Image"/>
              </div>
              <div class="p-col-10 p-ai-center p-text-center pt0">
                Filiera tracciata su Blockchain
              </div>
              
            </div>

          </div>

          <footer-bar class="p-px-5"></footer-bar>
        </ScrollPanel>
      </div>
    </div>
    
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Toast from 'primevue/toast';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import ScrollPanel from 'primevue/scrollpanel';
import Fieldset from 'primevue/fieldset';

import Image from 'primevue/image';
import Dialog from 'primevue/dialog';
import Avatar from 'primevue/avatar';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Button from 'primevue/button';
import Galleria from 'primevue/galleria';

import NavigationBar from '../components/NavigationBar';
import TimelinePanelV2 from '../components/TimelinePanelV2';
import FooterBar from '../components/FooterBar';

import AccountService from '../../service/AccountService';
import TransactionService from '../../service/TransactionService';
import ProductService from '../../service/ProductService';

import { ref, onMounted } from 'vue';
import { useRoute,  /*  useRouter */ } from 'vue-router';

import { useToast } from 'primevue/usetoast';


export default {
  name: "ProductPage",
  components: { FooterBar, Image, Dialog, Avatar, InputText, Galleria, 
    TextArea, Button, TimelinePanelV2, Breadcrumb, Toast, RadioButton, Message, 
    MultiSelect, ScrollPanel, Fieldset, NavigationBar},
  setup(){

    onMounted(() => {
      console.log("account_id: ", account_id);
      console.log("product_id: ", product_id);
      if(page_id){
        loadProdInfo();
      }else{
        loadPage.value = true;
        loadTimeline.value = true;
        visibleDocuments.value = documents.value.slice(0,4);
        selectedStep.value = visibleDocuments.value[0];
      }

      setBreadCrumb();
    })

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());
    const transactionService = ref(new TransactionService());

    const route = useRoute();

    const toast = useToast();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const page_id = route_params.split('&')[1];
    const product_id = route_params.split('&')[2];

    const documents = ref([
      {
        "_id": "c3426278fb02b2b9624c286fbb00d4e0",
        "_rev": "15-efae91ff93617155a899f7bdd75152a0",
        "id": "c81a304e-1eac-45cd-b37a-ae3fac14d5c2",
        "id_account": "b31ae67e-359a-11ec-8d3d-0242ac130003",
        "id_cliente": "b31ae67e-359a-11ec-8d3d-0242ac130003",
        "docName": "Lorem Ipsum",
        "docDescr": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "timelineInfo": {
          "timelineLocation": "Lorem Ipsum",
          "timelineName": "Lorem Ipsum",
          "timelineDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n",
          "timelineImage": "https://imageshack.com/i/pmx6idX8p",
          "timelineIndex": 1
        },
        "docTags": [{"tagName": "loremipsum"},{"tagName": "loremipsum"},{"tagName": "loremipsum"}],
        "metaBlockchain": {
          "ipfs": "",
          "link": "",
          "tx": ""
        },
        "icon": [
          "fas",
          "industry"
        ],
        "icon_class": "badge-icon fa-industry",
        "pub_key": "be81d66ebe9cb3be062eb14e9f6fe28e1da8412c3b99ccc045620e950a8ea067",
        "supplier_id": "c0ee128a-2f83-4546-8d02-d3737007aa93",
        "status": "approved"
      },
      {
        "_id": "c3426278fb02b2b9624c286fbb00d4e0",
        "_rev": "15-efae91ff93617155a899f7bdd75152a0",
        "id": "c81a304e-1eac-45cd-b37a-ae3fac14d5c2",
        "id_account": "b31ae67e-359a-11ec-8d3d-0242ac130003",
        "id_cliente": "b31ae67e-359a-11ec-8d3d-0242ac130003",
        "docName": "Lorem Ipsum",
        "docDescr": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "timelineInfo": {
          "timelineLocation": "Lorem Ipsum",
          "timelineName": "Lorem Ipsum",
          "timelineDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.\n",
          "timelineImage": "https://imageshack.com/i/pmx6idX8p",
          "timelineIndex": 1
        },
        "docTags": [{"tagName": "loremipsum"},{"tagName": "loremipsum"}],
        "metaBlockchain": {
          "ipfs": "",
          "link": "",
          "tx": ""
        },
        "icon": [
          "fas",
          "industry"
        ],
        "icon_class": "badge-icon fa-industry",
        "pub_key": "be81d66ebe9cb3be062eb14e9f6fe28e1da8412c3b99ccc045620e950a8ea067",
        "supplier_id": "c0ee128a-2f83-4546-8d02-d3737007aa93",
        "status": "approved"
      }
    ]);
    const visibleDocuments = ref([]);

    const loadPage = ref(false);
    const loadTimeline = ref(false);

    const parameters = ref({
      'backgroundColor': "A7B182",
      'buttonBackgroundColor': "ffffff",
      'buttonIconColor': "949494",
      'extBorderColor': "000000",
      'extBorderRadius': 20,
      'extBorderShadow': "f5f5f5",
      'extBorderTickness': 0,
      'hastagColor': "d1d1d1",
      'primaryTextBodyColor': "ffffff",
      'primaryTextTitleColor': "ffffff"
    });
    const pageParameters = ref({
      "backgroundImgMobile": "https://imageshack.com/i/pmx6idX8p",
      "companyName": "Ohoskin",
      "primaryUpperText": "L’alternativa vegana e bio-based alla pelle animale, realizzata con un processo di economia circolare che sfrutta i sottoprodotti alimentari di arance e fichi d'india siciliani.",
      "secondaryUpperText": "Per un lusso senza sensi di colpa.",
      "buttonText": "Scropri la filiera!",
      "upperTextBlockBottom": "17",
      "lowerTextBlockBottom": "24",
      "lowerBlockText": "Sostenibile, vegano, italiano e trasparente",
      "gifSrc": "https://imageshack.com/i/pmx6idX8p",
      "videoSrc": "https://imageshack.com/i/pmx6idX8p",
      "coverVideo": "https://imageshack.com/i/pmx6idX8p",
      "images": [
        {
          "itemImageSrc": "https://imageshack.com/i/pmx6idX8p",
          "title": "titolo",
          "description": "descrizione",
          "previewImg": ""
        },
        {
          "itemImageSrc": "https://imageshack.com/i/pmx6idX8p",
          "title": "titolo",
          "description": "descrizione",
          "previewImg": ""
        },
        {
          "itemImageSrc": "https://imageshack.com/i/pmx6idX8p",
          "title": "titolo",
          "description": "descrizione",
          "previewImg": ""
        },
        {
          "itemImageSrc": "https://imageshack.com/i/pmx6idX8p",
          "title": "titolo",
          "description": "descrizione",
          "previewImg": ""
        }
      ],
      "presentationTextContent": "Scopri tutto il percorso che trasforma i sottoprodotti di arance e fichi d'india siciliani in un materiale vegano alternativo alla pelle. Adesso è anche trasparente, perché tutta la filiera è tracciata tramite blockchain con Blockvision",
      "logoImg": "https://imageshack.com/i/pmx6idX8p",
      "messageLogo": "https://imageshack.com/i/pmx6idX8p"
    });
    const displayBasic = ref(false);

    const tags = ref([ { tagName : "Lorem" }, { tagName : "Lorem" } ]);

    const displayModalVideo = ref(false);
    const showInfo = ref(false);

    const displayModalFeedback = ref(false);

    const pageName = ref("");

    const name = ref("");
    const surname = ref("");
    const mail = ref("");
    const message = ref("");

    const messageSent = ref(false);

    const selectedIndex = ref(0);
    const docIdx = ref(0);

    const selectedStep = ref();

    const btnClass = ref('rounded-10 p-button-raised p-button-text p-button-plain p-mt-3 p-mb-4 p-py-4 p-px-4');
    const linkClass = ref('cursor-pointer no-underline dark-grey');
    const btnClicked = ref(false);

    const ids = ref([]);
    const pageFor = ref(1);
    const options = ref([]);

    const home = ref({
      icon: 'pi pi-home', 
      to: '/',
    });

    const items = ref([]);

    const shiftDocumentsRight = () => {
      let idx = docIdx.value -1;

      if(idx >= -1){
        if(selectedIndex.value != docIdx.value){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
    }

    const shiftDocumentsLeft = () => {
      let idx = docIdx.value + 1;

      if(idx < documents.value.length){
        if(idx > 3){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
      
    }

    const loadProdInfo = () => {
      productService.value.getProductByID(product_id).then(data => {
        //console.log("DATA: ", data.docs[0]);
        
        let prod_data = data.docs[0];

        transactionService.value.getDocumentsByIds(prod_data.lista_docs).then( (data) => {
          console.log("Documenti: ", data);
          documents.value = data;
          visibleDocuments.value = data.slice(0,4);

          loadTimelineInfo(prod_data.timeline_id);
          loadPageInfo(prod_data.page_id);

          selectedStep.value = visibleDocuments.value[0];
        })

      })
    }

    const loadTimelineInfo = (id) => {
      accountService.value.getTimelineById(id).then(data => {
        /* console.log("Timeline data: ", data.docs[0].parameters); */
        parameters.value = data.docs[0].parameters;
        loadTimeline.value = true;
      })
    }

    const loadPageInfo = (id) => {
      accountService.value.getProductPageById(id).then(data => {
        pageParameters.value = data.docs[0].parameters;
        /* console.log("Pagina: ", pageParameters.value); */
        loadPage.value = true;
      })
      
    }

    const openBasic = () => {
      displayBasic.value = true;
    };

    const closeBasic = () => {
      displayBasic.value = false;
    };

    const openModalVideo = () => {
      displayModalVideo.value = true;
    };

    const openModalFeedback = () => {
      displayModalFeedback.value = true;
    }

    const sendMessage = () => {
      messageSent.value = true;
      accountService.value.sendMessage(account_id, product_id, name.value, surname.value, mail.value, message.value);
    }

    const selectStep = (index) => {

      docIdx.value = index;  

      if(index<4){
        selectedStep.value = (documents.value)[docIdx.value];
        selectedIndex.value = index;  
        
      }else{
        selectedStep.value = (documents.value)[docIdx.value];
      }

    }

    const changeButtonColor = () => {
      if(!btnClicked.value){
        btnClass.value = 'rounded-10 p-button-raised p-button-text p-button-plain p-mt-3 p-mb-4 p-py-4 p-px-4 btn-grey';
        linkClass.value = 'cursor-pointer no-underline text-white';
        btnClicked.value = !btnClicked.value;
      }
    }

    const setBreadCrumb = () => {
      if(page_id){
        (items.value).push(
          {
            label: 'Modifica pagina'
          }
        )
        toast.add({severity: 'warn', summary: 'Timeline già registrata', detail: "Ogni modifica sostituirà la configurazione corrente", life: 30000});
      }else{
        (items.value).push(
          {
            label: 'Crea pagina'
          }
        )
      }
    }

    const loadOptions = () => {
      switch (pageFor.value) {
        case 1:
          accountService.value.getProductLines(account_id).then(data => {
            /* console.log("DATA: ", data); */
            options.value = data;
          })
          break;

        case 2:
          accountService.value.getProductCategories(account_id).then(data => {
            /* console.log("DATA: ", data); */
            options.value = data;
          })
          break;

        case 3:
          productService.value.getProducts(account_id, 10000000).then(data => {
            /* console.log("DATA: ", data.docs); */
            options.value = data.docs;
          })
          break;
      
        default:
          break;
      }
    }
   
    return { 
      account_id, pageParameters, documents, pageName, showInfo, linkClass, parameters, home, items,
      displayModalVideo, displayModalFeedback, btnClass, tags, displayBasic, messageSent, selectedIndex, 
      loadPage, loadTimeline, name, surname, message, mail, selectedStep, visibleDocuments, docIdx, 
      ids, pageFor, options, page_id,
      shiftDocumentsRight, shiftDocumentsLeft, selectStep, openModalVideo, 
      openModalFeedback, changeButtonColor, openBasic, closeBasic, sendMessage, loadOptions
    }
  }
};
</script>

<style scoped>

  .user-badge{
    width: 4rem !important;
    height: 4rem !important;
  }

  .btn-grey{
    background-color: rgb(172, 172, 172) !important;
    color: white !important;
  }

  .overlay {
    height: auto;
    text-align: center;
    width: 85%;
    position: absolute;
    left: 7.5%;
    z-index: 9;
    background-color: white;
  }

  .big-text{
    font-size: 5vw;
    font-weight: bold;
  }

  .timeline-badge-selected{
    background-color: rgb(75, 75, 75);
    color: white;
    height: 3.8rem !important;
    width: 3.8rem !important;
  }

  .timeline-badge{
    width: 3.4rem;
    height: 3.4rem;
  }

  .message{
    background-color: lightgrey;
    font-size: 3.9vw;
    position: relative;
  }

  .message:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-right: solid 23px transparent;
  }

  .message-2{
    position: relative;
  }

  .message-2:after {
    content:'';
    position: absolute;
    top: 94%;
    left: 80%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-left: solid 23px transparent;
  }


  ::v-deep(.p-avatar.p-avatar-xl){
    width: 8rem !important;
    height: 8rem !important;
  }

  ::v-deep(.p-avatar.p-avatar-lg){
    width: 6rem !important;
    height: 6rem !important;
  }
  
  ::v-deep(.p-dialog .p-dialog-content){
    padding: 0px !important;
  }
  
  .blink_me {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }

  ::v-deep(.pi .pi-angle-right .p-button-icon){
    font-size: 35px !important;
  }

  .pi .pi-angle-right .p-button-icon{
    font-size: 35px !important;
  }

  .menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .menu-link {
    padding: 8px;
    text-decoration: none;
    font-size: 8vw;
    color: #818181;
    display: block;
    transition: 0.3s;
    font-weight: bolder;
  }

  .menu-link:hover {
    color: #f1f1f1 !important;
  }

  .menu-link:focus {
    color: #f1f1f1 !important;
  }

  .menu-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 15vw;
  }

  ::v-deep(.step-title){
    font-size: 1.3vw !important;
  }

  ::v-deep(.step-small-text){
    font-size: 1vw !important;
  }

  ::v-deep(.step-small-icon){
    font-size: 1vw !important;
  }

  ::v-deep(.step-description-text){
    font-size: 1.1vw !important;
  }

</style>

