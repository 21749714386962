<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>
      <Toast />

    <div>

      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Aggiungi prodotti" icon="pi pi-plus" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="$router.push('/productLines/createProductLine/'+account_id+'&'+line_id)"/>
          <Button label="Aggiungi categoria" icon="pi pi-plus" class="purple-btn purple-diagonal p-mt-3 p-mb-5" style="font-size: 1.5em" @click="$router.push('/productCategories/createProductCategory/'+account_id+'&'+line_id)"/>
        </div>

        <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
            <div class="p-col-12">
              <div class="p-d-flex p-ai-center"><h3>Consenti modifica dei campi</h3> <InputSwitch class="p-ml-3" v-model="editable" /></div>

              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="line_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Nome</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="line_name" id="ID" type="text" placeholder="Nome" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Descrizione</h3>
                <span class="p-float-label p-mt-2">
                  <TextArea v-model="line_description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="!editable"/>
                </span>
              </div>

              <Button v-if="editable" label="Salva modifiche" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="updateLine()"/>

            </div>
          </div>
          
        </div>

        <div class="p-grid p-col-12 p-jc-start p-ai-stretch" style="padding-right: 0px !important; margin-right: 0px !important;">

          <div v-if="productCategories.length" class="p-col-12 p-lg-6 overflow-break">
            <Card>
              <template #content>
                <product-categories-table :showSelectedColumns="false" :showRowDetail="true" class="p-mt-3" title="Categorie prodotto della Linea" :productCategories="productCategories"></product-categories-table>
              </template>
            </Card>
          </div>
           

          
          <div v-if="line_products.length" class="p-col-12 p-lg-6 overflow-break" style="padding-right: 0px !important; margin-right: 0px !important;">
            <Card>
              <template #content>
                <products-table class="p-mt-3" title="Lista prodotti registrati" :products="line_products" :showRowDetail="true" :lineSelectable="false" 
                :showRemoveSwitch="true" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
                :showCat="true" :showLine="true" :showTimeline="true" :showPage="true" :showDocs="true" :showSelectedColumns="false" @removeProduct="removeFromLine"
                ></products-table>
              </template>
            </Card>
          </div>
          
        </div>

      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';

import NavigationBar from '../components/NavigationBar';
import ProductsTable from '../components/ProductsTable';
import ProductCategoriesTable from '../components/ProductCategoriesTable';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Card, ProductsTable, Toast, ProductCategoriesTable, Button, InputText, TextArea, InputSwitch},
  setup(){

    onMounted(() => {
        loadLineInfo();
        loadLineCategories();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const line_id = route_params.split('&')[1];

    const line_name = ref();
    const line_description = ref();
    const line_products = ref([]);

    const editable = ref(false);

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const productCategories = ref([]);

    const toast = useToast();

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Linee',
        to: '/productLines/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    const loadLineInfo = () => {
      accountService.value.getLineById(line_id).then(data => {
        console.log("RICARICATI");
        line_products.value = data.docs[0].products;
        line_products.value.forEach(e => {
          if(e.id_categoria){
            e.removable = false;
          }else{
            e.removable = true;
          }
        })
        line_name.value = data.docs[0].name;
        line_description.value = data.docs[0].description;
      })
    }

    const loadLineCategories = () => {
        accountService.value.getCategoriesByLineId(line_id).then(data => {
            console.log("productCategories: ",data);
            productCategories.value = data;
        })
    };

    const removeFromLine = (id) => {
      productService.value.removeProductLine(id).then( () => {
        toast.add({severity: 'info', summary: 'Product Removed', detail: 'The selected product ('+id+') has been removed from current line', life: 3000});
        line_products.value = [];
        loadLineInfo();
      })
    }

    const updateLine = () => {
      accountService.value.registerLine(account_id, line_name.value, line_description.value, null, line_id).then( response => {
        if(response == 200){
          router.go();
        }
      });
    }

    return { account_id, line_name, line_description, line_id, productCategories, line_products, home, items, removeFromLine, editable, updateLine}
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }
  

  ::v-deep(.step-info:before) {
    display: none !important;
  }

  ::v-deep(.step-info:after) {
    display: none !important;
  }
</style>

