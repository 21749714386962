<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>
    <div class="">
      
      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Visualizza pagina prodotto" icon="pi pi-eye" class="purple-btn purple-diagonal p-mt-3 p-mb-5" style="font-size: 1.5em" @click="showPage()"/>      
        </div>

        <div class="p-grid p-col-12 p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3 p-ai-stretch">
            <div class="p-d-flex p-ai-center p-col-12"><h3>Consenti modifica dei campi</h3> <InputSwitch class="p-ml-3" v-model="editable" /></div>

            <div class="p-col-12 p-md-6">
              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="product_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">ID Tag</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="tag_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Nome</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="product_name" id="ID" type="text" placeholder="Nome" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Descrizione</h3>
                <span class="p-float-label p-mt-2">
                  <TextArea v-model="product_description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="!editable"/>
                </span>
              </div>

            </div>

            <div class="p-col-12 p-md-6">

              <div v-if="product_line">
                <h3 class="p-mt-5">Linea</h3>
                <span class="p-float-label p-mt-2">
                  <Dropdown v-model="product_line" :options="lines" optionLabel="name" @change="resetMetadata()" optionValue="id" placeholder="Scegli linea" :disabled="!editable"/>

                </span>
              </div>

              <div v-if="product_category" >
                <h3 class="p-mt-5">Categoria</h3>
                <Message class="" severity="warn">Modificando la categoria verranno modificati in automatico anche nome e descrizione del prodotto.</Message>  
                <span class="p-float-label p-mt-2">
                  <Dropdown v-model="product_category" :options="categories" optionLabel="name" @change="updateMetadata()" optionValue="id" placeholder="Scegli categoria" :disabled="!editable"/>
                </span>
              </div>

              <div v-if="timelines">
                <h3 class="p-mt-5">Timeline</h3>
                
                <Message class="p-mx-1" severity="info">E' possibile visualizzare un'anteprima della timeline nel pannello in fondo alla pagina.</Message>  
                <span class="p-float-label p-mt-2 p-grid p-jc-between p-mx-1">
                  <Dropdown :disabled="!editable" class="p-col-12" v-model="timeline" :options="timelines" optionLabel="timeline_name" :filter="true" placeholder="Scegli timeline" :showClear="true">
                    <template #value="slotProps">
                      <div class="p-d-flex p-ai-center p-jc-start" v-if="slotProps.value">
                        <AvatarGroup class="mb-3">
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.value.parameters.primaryTextTitleColor}"/>
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.value.parameters.buttonBackgroundColor}"/>
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.value.parameters.buttonIconColor}"/>
                        </AvatarGroup>
                        <div class="p-ml-5">{{slotProps.value.timeline_name}}</div>
                      </div>
                    </template>
                    
                    <template #option="slotProps">
                      <div class="p-d-flex p-ai-center p-jc-start">
                        <AvatarGroup class="mb-3">
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.option.parameters.primaryTextTitleColor}"/>
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.option.parameters.buttonBackgroundColor}"/>
                          <Avatar class="p-shadow-2" size="small" shape="circle" :style="{backgroundColor: '#'+slotProps.option.parameters.buttonIconColor}"/>
                        </AvatarGroup>
                        <div class="p-ml-5">{{slotProps.option.timeline_name}}</div>
                      </div>
                    </template>
                </Dropdown>
                </span>
              </div>

              <div v-if="pages">
                <h3 class="p-mt-5">Pagina</h3>
                
                <span class="p-float-label p-mt-2 p-grid p-jc-between p-mx-1">
                  <Dropdown :disabled="!editable" class="p-col-12" v-model="page" :options="pages" optionLabel="page_name" :filter="true" placeholder="Scegli pagina" :showClear="true">
                    <template #value="slotProps">
                      <div class="p-d-flex p-ai-center p-jc-start" v-if="slotProps.value">
                        <AvatarGroup class="mb-3">
                          <Avatar size="small" shape="circle" :image="slotProps.value.parameters.backgroundImgMobile"/>
                          <Avatar size="small" shape="circle" :image="slotProps.value.parameters.coverVideo"/>
                          <Avatar size="small" shape="circle" :image="slotProps.value.parameters.images[0].itemImageSrc"/>
                        </AvatarGroup>
                        <div class="p-ml-5">{{slotProps.value.page_name}}</div>
                      </div>
                    </template>
                    
                    <template #option="slotProps">
                      <div class="p-d-flex p-ai-center p-jc-start">
                        <AvatarGroup class="mb-3">
                          <Avatar size="small" shape="circle" :image="slotProps.option.parameters.backgroundImgMobile"/>
                          <Avatar size="small" shape="circle" :image="slotProps.option.parameters.coverVideo"/>
                          <Avatar size="small" shape="circle" :image="slotProps.option.parameters.images[0].itemImageSrc"/>
                        </AvatarGroup>
                        <div class="p-ml-5">{{slotProps.option.page_name}}</div>
                      </div>
                    </template>
                </Dropdown>
                </span>
              </div>

            </div>

            <Button v-if="editable" label="Salva modifiche" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="updateProduct()"/>
          </div>
          
        </div>

        <div class="p-grid p-col-12 p-jc-start p-ai-stretch" style="padding-right: 0px !important; margin-right: 0px !important;">

          <div v-if="productDocuments.length" class="p-col-12 p-lg-6 overflow-break">
            <Card>
              <template #content>
                <documents-table :documentSelectable="true" @selectedDocuments="setDocsToRemove" title="Documenti registrati" class="p-mt-5" :showRowDetail="true" :documents="productDocuments" :rowExpandable="false" :account_id="account_id"></documents-table>
              </template>
            </Card>
            <Button v-if="docsToRemove.length" label="Rimuovi documenti selezionati" icon="pi pi-trash" class="p-col-12 purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="removeDocuments()"/>
          </div>
           

          
          <div v-if="assignableDocuments.length" class="p-col-12 p-lg-6 overflow-break" style="padding-right: 0px !important; margin-right: 0px !important;">
            <Card>
              <template #content>
                <documents-table :documentSelectable="true" @selectedDocuments="setDocsToRegister" title="Documenti registrabili" class="p-mt-5" :showRowDetail="true" :documents="assignableDocuments" :rowExpandable="false" :account_id="account_id"></documents-table>
              </template>
            </Card>
            <Button v-if="docsToRegister.length" label="Registra documenti selezionati" icon="pi pi-save" class="p-col-12 purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="registerDocuments()"/>
          </div>
          
        </div>

      </div>
      
    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown'
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Message from 'primevue/message';

import NavigationBar from '../components/NavigationBar';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import ProductService from '../../service/ProductService';
import AccountService from '../../service/AccountService';
import TransactionService from '../../service/TransactionService';

import DocumentsTable from '../components/DocumentsTable';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Card, DocumentsTable, Button, InputText, TextArea, InputSwitch, Dropdown, Avatar, AvatarGroup, Message },
  setup(){

    onMounted(() => {
        loadProductInfo();
        loadTimelines();
        loadPages();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const product_id = route_params.split('&')[1];

    const product_name = ref();
    const product_description = ref();
    const tag_id = ref();

    const productDocuments = ref([]);
    const assignableDocuments = ref([]);
    const docsToRegister = ref([]);
    const docsToRemove = ref([]);

    const product_line = ref();
    const product_category = ref();

    const showTimelines = ref(false);
    const timelines = ref([]);

    const showPages = ref(false);
    const pages = ref([]);
    const companyName = ref("");

    const loadTimeline = ref(false);

    const editable = ref(true);

    const lines = ref([]);
    const categories = ref([]);

    const productService = ref(new ProductService());
    const transactionService = ref(new TransactionService());
    const accountService = ref(new AccountService());

    const loadProductInfo = () => {
      productService.value.getProductByID(product_id).then(data => {
        /* console.log("DATA: ", data); */
        let prod_data = data.docs[0];
        product_name.value = prod_data.metadata.name;
        product_description.value = prod_data.metadata.description;

        if(prod_data.lista_docs[0]){
          loadDocuments(prod_data.lista_docs);
        }

        tag_id.value = prod_data.id_tag;

        if(prod_data.timeline_id){
          accountService.value.getTimelineById(prod_data.timeline_id).then(data => {
            if(data.docs[0]){
              timeline.value = data.docs[0];
              loadTimeline.value = true;
            }
          })
        }

        if(prod_data.page_id){
          accountService.value.getProductPageById(prod_data.page_id).then(data => {
            if(data.docs[0]){
              page.value = data.docs[0];
              companyName.value = data.docs[0].parameters.companyName;
              console.log("Company: ", companyName.value);
            }
          })
        }

        if(prod_data.id_linea){
          accountService.value.getLineById(prod_data.id_linea).then(data => {
            /* console.log("Linea: ", data.docs[0]); */
            product_line.value = data.docs[0].id;
            loadProductLines();
            loadCategoriesByLineId();
          })
        }

        if(prod_data.id_categoria){
          accountService.value.getCategoryById(prod_data.id_categoria).then(data => {
            /* console.log("Categoria: ", data); */
            product_category.value = data.id;
          })
        }

        loadAssignableDocuments(prod_data.lista_docs);

      })
    }

    const loadDocuments = (docs) => {
      docs.forEach(async element => {
          transactionService.value.getDocumentById(element).then( data => {
          /* productDocuments.value = data.docs; */
          productDocuments.value.push(data.docs[0]);
        })
      });
      
    }

    const loadAssignableDocuments = (docs) => {

      transactionService.value.getDocuments(account_id).then( data => {
        /* console.log("Documenti: ", data); */

        data.forEach( element => {
          if(!docs.includes(element.id)){
           assignableDocuments.value.push(element); 
          }
        });

      })

      
    }

    const loadProductLines = () => {
      accountService.value.getProductLines(account_id).then(data => {
        lines.value = data;
      })
    }

    const loadCategoriesByLineId = () => {
      /* console.log("Product Line: ", product_line.value); */
      accountService.value.getCategoriesByLineId(product_line.value).then(data => {
        categories.value = data;
      })
    }

    const loadTimelines = () => {
      accountService.value.getTimelines(account_id).then(data => {
        /* console.log("Timelines: ", data.docs); */
        timelines.value = data.docs;
        showTimelines.value = true;
      })
    }

    const loadPages = () => {
      accountService.value.getPages(account_id).then(data => {
        /* console.log("Pages: ", data.docs); */
        pages.value = data.docs;
        showPages.value = true;
      })
    }

    const updateProduct = () => {
      let t_id = timeline.value ? timeline.value.id : "";
      productService.value.registerProduct(product_id, product_line.value, product_category.value, t_id, product_name.value, product_description.value).then(response => {
        if(response == 200){
          router.go();
        }
      })
    }

    const updateMetadata = () => {
      let cat = categories.value.find(e => e.id==product_category.value);
      /* console.log("CAAT: ", cat); */
      product_description.value = cat.description;
      product_name.value = cat.name;
    }

    const resetMetadata = () => {
      categories.value = {};
      loadCategoriesByLineId();
      product_description.value = "";
      product_name.value = "";
      product_category.value = {};
    }

    const showPage = () => {
      localStorage.account = account_id;
      localStorage.product = product_id;
      router.push("/loadProductPage/"+account_id+"&"+product_id+"&"+companyName.value);
    }

    const setDocsToRegister = (rows) => {
      console.log("Righe: ", rows.value);
      docsToRegister.value = rows.value;
      console.log("Documenti selezionati da registrare: ", docsToRegister.value);
    }

    const setDocsToRemove = (rows) => {
      console.log("Righe: ", rows.value);
      docsToRemove.value = rows.value;
      console.log("Documenti selezionati da rimuovere: ", docsToRemove.value);
    }

    const removeDocuments = () => {
      let ids = [];
      docsToRemove.value.forEach( e => {
        ids.push(e.id)
      })

      console.log("ids: ", ids, "Prodotto: ", product_id);

      accountService.value.removeProductDocuments(product_id, ids).then( () => {
        router.go();
      })

    }

    const registerDocuments = () => {
      let ids = [];
      docsToRegister.value.forEach( e => {
        ids.push(e.id)
      })

      console.log("ids: ", ids, "Prodotto: ", product_id);

      accountService.value.registerProductDocuments(product_id, ids).then( () => {
        router.go();
      })
    }

    const timeline = ref();
    const page = ref();

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Prodotti',
        to: '/products/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    return { product_id, product_name, product_description, product_line, product_category, tag_id, showTimelines, loadTimeline, updateMetadata,
      productService, home, items, account_id, productDocuments, timeline, loadTimelines, timelines, editable, lines, categories, updateProduct, resetMetadata, showPage,
      pages, showPages, page, assignableDocuments, setDocsToRegister, registerDocuments, docsToRegister, docsToRemove, setDocsToRemove, removeDocuments
    }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }
  

  ::v-deep(.step-info:before) {
    display: none !important;
  }

  ::v-deep(.step-info:after) {
    display: none !important;
  }
</style>

