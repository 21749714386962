<template>
     <div>
        <Toast />
        <div class="card">
            <DataTable :value="products" :resizableColumns="true" :paginator="true" :rows="5" columnResizeMode="expand" responsiveLayout="scroll" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" stripedRows
                :rowsPerPageOptions="[5,10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters" filterDisplay="row" removableSort @rowSelect="onRowSelect" @rowSelectAll="onRowSelect" @rowUnselectAll="onRowUnselect" @rowUnselect="onRowUnselect" v-model:selection="lineSelectedProducts">

                <template #header>
                    <div class="table-header-container">
                        <div class="p-d-flex p-jc-between p-ai-center" style="text-align:left">
                            <h2>{{title}}</h2>
                            <MultiSelect v-if="showSelectedColumns" v-model="selectedColumns" :options="columns" placeholder="Select Columns" style="width: 20em"/>
                        </div>
                        <div v-if="showRemoveSwitch" class="p-d-flex p-ai-center p-mt-3">
                            Consenti rimozione delle righe <InputSwitch class="p-ml-3" v-model="removable" />
                        </div>
                    </div>
                </template>
                
                <Column v-if="lineSelectable" selectionMode="multiple" headerStyle="width: 3em"></Column>

                <Column v-if="showRowDetail" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-search" @click="showDetail(slotProps.data)" class="purple-diagonal purple-btn"></Button>
                    </template>
                </Column>

                <Column v-if="removable" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <!-- <Button :disabled="!slotProps.data.removable" type="button" icon="pi pi-trash" @click="$emit('removeProduct', slotProps.data.id)" class="purple-diagonal purple-btn"></Button> -->
                        <Button type="button" icon="pi pi-trash" @click="$emit('removeProduct', slotProps.data.id)" class="purple-diagonal purple-btn"></Button>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID Tag') && showIdTag" :sortable="true" field="id_tag" header="ID Tag" filterField="id_tag">
                    <template #body="slotProps">
                        {{slotProps.data.id_tag}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID del tag'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID') && showID" :sortable="true" field="id" header="ID" filterField="id">
                    <template #body="slotProps">
                        {{slotProps.data.id}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nome') && showName" :sortable="true" field="metadata.name" header="Nome" filterField="metadata.name">
                    <template #body="slotProps">
                        {{slotProps.data.metadata.name}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per nome'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descrizione') && showDescription" :sortable="true" field="metadata.description" header="Descrizione" filterField="metadata.description">
                    <template #body="slotProps">
                        {{slotProps.data.metadata.description}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID Linea') && showLine" :sortable="true" field="id_linea" header="ID Linea" filterField="id_linea">
                    <template #body="slotProps">
                        {{slotProps.data.id_linea}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID Categoria') && showCat" :sortable="true" field="id_categoria" header="ID Categoria" filterField="id_categoria">
                    <template #body="slotProps">
                        {{slotProps.data.id_categoria}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Timeline') && showTimeline" field="has_timeline" header="Timeline" dataType="boolean" style="min-width:6rem">
                    <template #body="{data}">
                        <i class="pi" :class="{'true-icon pi-check-circle': data.has_timeline, 'false-icon pi-times-circle': !data.has_timeline}"></i>
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Pagina') && showPage" field="has_page" header="Pagina" dataType="boolean" style="min-width:6rem">
                    <template #body="{data}">
                        <i class="pi" :class="{'true-icon pi-check-circle': data.has_page, 'false-icon pi-times-circle': !data.has_page}"></i>
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <TriStateCheckbox v-model="filterModel.value" @change="filterCallback()"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('# Documenti') && showDocs" field="lista_docs.length" header="# Documenti" dataType="numeric" style="min-width:6rem">
                    <template #body="slotProps">
                        {{slotProps.data.lista_docs.length}}
                    </template>
                </Column>
                
                <template #paginatorLeft>
                    <Button type="button" icon="pi pi-refresh" @click="$emit('reload')" class="p-button-text" />
                </template>
            </DataTable>
        </div>
      </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: "Products_Table",
  components: { DataTable, Column, Button, Toast, InputSwitch, MultiSelect, InputText, TriStateCheckbox },
  props : {
      title : String,
      products : Array,
      showRowDetail : Boolean,
      lineSelectable : Boolean,
      showRemoveSwitch: Boolean,
      selectedAll : Boolean,
      showIdTag: Boolean,
      showID: Boolean,
      showName: Boolean,
      showDescription: Boolean,
      showCat: Boolean,
      showLine: Boolean,
      showTimeline: Boolean,
      showPage: Boolean,
      showDocs: Boolean,
      showSelectedColumns: Boolean,
      setRemovable: Boolean
  },
  emits: ["reload", "selectedTags", "removeProduct"],
  setup(props, context){

    onMounted(() => {
        if(props.selectedAll){
            lineSelectedProducts.value = props.products;
            context.emit("selectedTags", lineSelectedProducts) 
        }
        removable.value = props.setRemovable;
    })

    const toast = useToast();

    const lineSelectedProducts = ref();

    const router = useRouter();

    const removable = ref(false);

    const showDetail  = (data) => {
        console.log("Data: ", data);
        router.push('/productDetail/'+data.id_account+'&'+data.id);
    }

    const rowClassFunction = () => {
        return "overflow-break";
    }

    const onRowSelect = (event) => {
        if(event.data.id_tag){
            toast.add({severity: 'info', summary: 'Product Selected', detail: 'Name: ' + event.data.id_tag, life: 3000});
        }
        context.emit("selectedTags", lineSelectedProducts) 
    };

    const onRowUnselect = (event) => {
        if(event.data){
            toast.add({severity: 'warn', summary: 'Product Unselected', detail: 'Name: ' + event.data.id_tag, life: 3000});
        }
        context.emit("selectedTags", lineSelectedProducts)
    };

    const selectedColumns = ref(['ID Tag','ID','Nome','ID Linea','ID Categoria','Timeline','Pagina','# Documenti']);
    const columns = ref(['ID Tag','ID','Nome','Descrizione','ID Linea','ID Categoria','Timeline','Pagina','# Documenti']);

    const filters = ref({
        'metadata.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'metadata.description': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'id_linea': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'id_categoria': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'id_tag': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'has_timeline': {value: null, matchMode: FilterMatchMode.EQUALS},
        'has_page': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
    });

    return { lineSelectedProducts, removable, showDetail, rowClassFunction, onRowSelect, onRowUnselect, filters, selectedColumns, columns }
  }
};
</script>

<style scoped>

</style>

