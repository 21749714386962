<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>

     <div class="p-mx-3">
      <div class="p-grid p-jc-end p-mt-3">
        <Button label="Nuova" icon="pi pi-plus" class="purple-btn purple p-mt-3 p-col-12 p-md-2 p-mr-3" style="font-size: 1.5em" @click="$router.push('/configureTimelineV2/'+account_id)"/>
      </div>
      <Message class="p-mx-2" severity="info" v-if="!timelines.length">Per prima cosa registra una timeline.</Message>  
      <div v-else class="">
        <timelines-table-v2 :timelines="timelines" :showDetails="true" :showSelectedColumns="true" title="Lista timelines"></timelines-table-v2>
      </div>
    </div>

  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Message from 'primevue/message';

import TimelinesTableV2 from '../components/TimelinesTableV2';

import { useRoute } from 'vue-router';

import { ref, onMounted } from 'vue';

import AccountService from '../../service/AccountService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Button, TimelinesTableV2, Message },
  setup(){

    onMounted(() => {
      loadTimelines();
    })

    const route = useRoute();

    const account_id = route.params.data;

    const accountService = ref(new AccountService());

    const timelines = ref([]);

    const loadTimelines = () => {
      accountService.value.getTimelines(account_id).then(data => {
        console.log("Timelines: ", data);
        timelines.value = data.docs;
      })
    }

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Anagrafica timelines'},
    ]);

    return { home, items, account_id, timelines }
  }
};
</script>

<style scoped>

</style>

