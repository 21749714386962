<template>
  <main class="product-passport-container" title="">

    <!-- Product tab -->
    <div v-if="selectedTab==0" style="overflow: hidden !important">
      <div class="p-d-flex p-text-center p-jc-center p-mt-5" style="width: 100vw; height: 25vh;">
        <Image class="" :imageStyle="{ maxHeight: '100%' }" src="https://imageshack.com/i/poh2erk9p" alt="Image"/>
      </div>

      <!-- Product metadata -->
      <div class="p-grid p-mx-3 p-mt-3">

        <!-- BRAND -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            Brand
          </div>
          <div class="product-metadata-content p-grid p-ai-center">
            <div class="p-col-2 no-pr">
              <Image class="" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img924/8707/zQxJHu.png" alt="Image"/>
            </div>
            <div class="p-col-10">
              BRAND
            </div>
          </div>
        </div>

        <!-- COLLECTION -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            Collezione
          </div>
          <div class="product-metadata-content p-grid p-ai-center">
            <div class="p-col-2 no-pr">
              <Image class="" :imageStyle="{ maxWidth: '100%' }" src="https://imageshack.com/i/poWWO7B7p" alt="Image"/>
            </div>
            <div class="p-col-10">
              SS 2022/2023
            </div>
          </div>
        </div>

        <!-- PRODUCT -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            Prodotto
          </div>
          <div class="product-metadata-content p-grid p-ai-center">
            <div class="p-col-2 no-pr">
              <Image class="" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img923/6638/50EDUK.png" alt="Image"/>
            </div>
            <div class="p-col-10">
              BLACK T-SHIRT
            </div>
          </div>
        </div>

        <!-- LOCATION -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            Made in
          </div>
          <div class="product-metadata-content p-grid p-ai-center">
            <div class="p-col-2 no-pr">
              <Image class="" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img922/2019/jFMiN5.png" alt="Image"/>
            </div>
            <div class="p-col-10">
              ITALY
            </div>
          </div>
        </div>

        <!-- GARMENTS -->
        <div class="p-col-6 product-metadata-title p-ai-center p-d-flex">
          Materiali
        </div>
        <div class="p-col-6 p-grid product-metadata-content">
          <div class="p-col-3 no-pr">
            98%
          </div>
          <div class="p-col-9 no-pl">
            Cotone Organico
          </div>
          <div class="p-col-3 no-pr">
            2%
          </div>
          <div class="p-col-9 no-pl">
            Elastane
          </div>
        </div>

        <!-- MAINTENANCE -->
        <div class="p-col-6 product-metadata-title p-ai-center p-d-flex">
          Mantenimento
        </div>
        <div class="p-col-6 p-d-flex">
          <Image class="" :imageStyle="{ maxWidth: '80%' }" src="https://imageshack.com/i/pmDv8TQKp" alt="Image"/>
          <Image class="" :imageStyle="{ maxWidth: '80%' }" src="https://imageshack.com/i/pmFzpzkLp" alt="Image"/>
          <Image class="" :imageStyle="{ maxWidth: '80%' }" src="https://imageshack.com/i/pmZxfNqYp" alt="Image"/>
          <Image class="" :imageStyle="{ maxWidth: '80%' }" src="https://imageshack.com/i/pnBSIWGRp" alt="Image"/>
          <Image class="" :imageStyle="{ maxWidth: '80%' }" src="https://imageshack.com/i/pnEYx2azp" alt="Image"/>
        </div>

        <!-- ID PRODUCT -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            ID Prodotto
          </div>
          <div class="product-metadata-content">
            B000005334LFS5003
          </div>
        </div>

         <!-- ID NFC -->
        <div class="p-col-6">
          <div class="product-metadata-title p-mb-1">
            ID NFC
          </div>
          <div class="product-metadata-content">
            61239984
          </div>
        </div>

        <!-- Tracked supply chain button -->
        <div class="p-col-12 p-d-flex p-ai-center">
          <div class="p-shadow-1 p-d-flex p-ai-center p-jc-center pulse-grey-20 rounded-10 external-link-btn" :style="{ backgroundColor: '#' + timelineStyle.buttonBackgroundColor, color: '#' + timelineStyle.buttonIconColor }">
            <i class="pi pi-external-link"></i>
          </div>
          <div class="product-metadata-content p-ml-3" style="text-decoration: underline;">
            Filiera tracciata<br />con tecnologia blockchain
          </div>
        </div>

      </div>
    </div>

    <!-- Supply chain -->
    <div v-show="selectedTab==1" style="overflow-y: hidden !important;">
      <div id='map'></div>

      <!-- Menù -->
      <div id="menu" class="p-d-flex p-ai-center">
        <i class="pi pi-globe p-mr-2"></i><InputSwitch v-model="visualizeFlat" @change="changeMapVisualization()" /><i class="pi pi-map p-ml-2"></i>
      </div>
      
      <!-- Supply chain summary -->
      <div id="supplyChainSummary" class="p-grid">
        <div class="p-col-12 no-p" @click="showSummary=!showSummary">
          <i v-if="showSummary" class="pi pi-angle-up"></i>
          <div v-else class="p-ai-center p-d-flex">
            <i class="pi pi-bars" style="font-size: 2vh;"></i><span class="p-ml-2">Resoconto di filiera</span>
          </div>
        </div>
        <div v-if="showSummary" class="p-grid p-col-12">
          <div class="p-col-12 no-pr no-pl p-pb-1 p-d-flex p-jc-between">
            <span>N° step di produzione</span>
            <span class="p-mr-2 p-ml-1">4</span>
          </div>
          <div class="p-col-12 no-pr no-pl p-pb-1 p-d-flex p-jc-between">
            <span>N° fornitori</span>
            <span class="p-mr-2 p-ml-1">4</span>
          </div>
          <div class="p-col-12 no-pr no-pl p-pb-1 p-d-flex p-jc-between">
            <span>N° Paesi</span>
            <span class="p-mr-2 p-ml-1">1</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom panel to select the current tab --> <!-- :style="[ selectedTab == 1 ? 'bottom: 2vh;' : 'bottom: 0;']" -->
    <div class="tab-selection">
      <!-- Selected tab has a displayed top border -->
      <div class="p-grid p-mb-2">
        <div class="p-col-3 tab-selected-top-border" :class="{'p-offset-3' : selectedTab==1, 'p-offset-6' : selectedTab==2, 'p-offset-9' : selectedTab==3}"></div>
      </div>
      <div class="p-grid">
        <div class="p-col-3 tab p-ai-center p-jc-center p-text-center p-py-3" @click="selectTab(0)" :class="{'selected-tab' : selectedTab==0}" :style="[ selectedTab==1 ? 'border-right: none !important;' : 'border-right: 1px solid #ABABAB;']">
          <Image class="" :imageStyle="{ maxWidth: '30%' }" src="https://imagizer.imageshack.com/img924/8119/QFvbgf.png" alt="Image"/>
          <div>Prodotto</div>
        </div>
         <div class="p-col-3 tab p-ai-center p-jc-center p-text-center p-py-3" @click="selectTab(1)" :class="{'selected-tab' : selectedTab==1}" :style="[ selectedTab==2 ? 'border-right: none !important;' : 'border-right: 1px solid #ABABAB;']">
          <Image class="" :imageStyle="{ maxWidth: '30%' }" src="https://imagizer.imageshack.com/img924/9504/a8rzRS.png" alt="Image"/>
          <div>Filiera</div>
        </div>
         <div class="p-col-3 tab p-ai-center p-jc-center p-text-center p-py-2" @click="selectTab(2)" :class="{'selected-tab' : selectedTab==2}" :style="[ selectedTab==3 ? 'border-right: none !important;' : 'border-right: 1px solid #ABABAB;']">
          <Image class="" :imageStyle="{ maxWidth: '30%' }" src="https://imagizer.imageshack.com/img922/9909/c1IGyJ.png" alt="Image"/>
          <div>#whoMade<br />MyClothes</div>
        </div>
         <div class="p-col-3 tab p-ai-center p-jc-center p-text-center p-py-2" @click="selectTab(3)" :class="{'selected-tab' : selectedTab==3}">
          <Image class="" :imageStyle="{ maxWidth: '30%' }" src="https://imagizer.imageshack.com/img923/9186/t9BvyF.png" alt="Image"/>
          <div>Carbon<br />Token</div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>

import Image from 'primevue/image';
import InputSwitch from 'primevue/inputswitch';

import AccountService from '../../service/AccountService';
import TransactionService from '../../service/TransactionService';
import ProductService from '../../service/ProductService';

import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { ref, onMounted } from 'vue';

export default {
  name: "ProductPassport",
  components: { Image, InputSwitch },
  setup(){

    onMounted(() => {
      console.log("account_id: ", account_id);
      console.log("product_id: ", product_id);
      
      //On page mounting all information about product are loaded
      loadProductPassportInfo();
      
      prepareSupplyChainMap();

    })

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());
    const transactionService = ref(new TransactionService());

    const account_id = localStorage.account;
    const product_id = localStorage.product;

    const documents = ref([]);
    
    /* Loading information about the product using its ID. Product info are:
      - Timeline ID -> used to retrieve all style of the timeline block;
      - Product Page ID -> used to retrieve all the meta about the page of the product;
      - Lista documents -> all the documents linked to the specific product;
      - Metadata
    */
    const loadProductPassportInfo = () => {
      productService.value.getProductByID(product_id).then(data => {
        
        let prod_data = data.docs[0];

        //Retrieving information for all documents 
        transactionService.value.getDocumentsByIds(prod_data.lista_docs).then( (data) => {
          documents.value = data;

          /* After documents are loaded both timeline settings and page settings are retrieved using their ID */
          loadTimelineStyle(prod_data.timeline_id);
          loadProductPassportMeta(prod_data.page_id);
        })

      })
    }

    const timelineStyle = ref({});
    const loadTimeline = ref(false);

    /* Loading timeline style */
    const loadTimelineStyle = (id) => {
      accountService.value.getTimelineById(id).then(data => {
        timelineStyle.value = data.docs[0].parameters;

        loadTimeline.value = true;
      })
    }

    const passportMetadata = ref({});
    const loadPassportMetadata = ref(false);

    /* Loading product passport metadata, content and all */
    const loadProductPassportMeta = (id) => {
      accountService.value.getProductPageById(id).then(data => {
        passportMetadata.value = data.docs[0].parameters;
        document.title = passportMetadata.value.pageTitle;
        
        //Only after the loading of the metadata the slideshow can be shown
        loadPassportMetadata.value = true;
      })
      
    }

    const selectedTab = ref(1);

    const selectTab = (index) => {
      selectedTab.value = index;
    }

    const map = ref();

    const suppylChainSteps = ref([
      {
        'latLon' : [15.110462, 37.540741],
        'iconUrl' : 'https://imagizer.imageshack.com/img923/2102/JYKaZ6.png',
        'location' : 'Catania, Italia',
        'stepName' : 'Brevetto di Ohoskin'
      },
      {
        'latLon' : [14.181969, 37.539870],
        'iconUrl' : 'https://imageshack.com/i/pmcFAQppp',
        'location': 'Sicilia, Italia',
        'stepName' : 'Raccolta dei sottoprodotti'
      },
      {
        'latLon' : [15.045128, 37.483019],
        'iconUrl' : 'https://imageshack.com/i/pnsnwhj9p',
        'location': 'Catania, Italia',
        'stepName' : 'Produzione biopolimero'
      },
      {
        'latLon' : [9.082040, 45.644508],
        'iconUrl' : 'https://imageshack.com/i/pmnidK7Mp',
        'location' : 'Cogliate, Italia',
        'stepName' : 'Spalmatura'
      }
    ])

    const visualizeFlat = ref(false);

    const changeMapVisualization = () => {
      if(visualizeFlat.value){
        map.value.setProjection('mercator');
        map.value.setZoom(1.5);
        map.value.setCenter([12.496366, 41.902782]);
      }else{
        map.value.setProjection('globe');
        map.value.setZoom(1.5);
        map.value.setCenter([12.496366, 41.902782]);
      }
    }

    const showSummary = ref(false);

    const prepareSupplyChainMap = () => {

      //Create map using access token
      mapboxgl.accessToken = "pk.eyJ1IjoiYmxvY2t2aXNpb25tIiwiYSI6ImNsN3M1eXh1dzAzbm0zcXFucjgycGx6aWIifQ.N30gHRpZicQDW11W2XopZw";
      map.value = new mapboxgl.Map({
        container: "map",
        style: "mapbox://styles/mapbox/light-v9",
        center: [12.496366, 41.902782],
        zoom: 1.5,
        projection: 'globe' //The map is standard displayed as a globe
      });

      //Adding zoom control and navigation
      const nav = new mapboxgl.NavigationControl({
        visualizePitch: true
      });
      map.value.addControl(nav, 'top-left');

      map.value.addControl(new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: false
        },
        trackUserLocation: true,
          showUserHeading: true
      }));

      //Adding fog
      map.value.on('load', () => {
        map.value.setFog({});
      });

      //Adding popup and marker to display supply chain
      const popup = new mapboxgl.Popup({ offset: 35, closeOnClick: false, closeButton: false, anchor: 'bottom', className: 'class1' })
        .setLngLat([12.496366, 41.902782])
        .setHTML('<div class="p-d-flex p-jc-center p-p-2" style="max-width: 40vw;"><h2 id="popup-title-supply" class="p-text-center">Scopri la filiera di questo capo!</h2></div>')

      popup.addTo(map.value);

      const showSupplyChainMarker = document.createElement('div');
      showSupplyChainMarker.style.backgroundColor = '#6B6B6B'
      showSupplyChainMarker.style.width = '50px';
      showSupplyChainMarker.style.height = '50px';
      showSupplyChainMarker.style.backgroundSize = '100%';
      showSupplyChainMarker.style.backgroundColor = '#ABABAB';
      showSupplyChainMarker.style.borderRadius = '50%';

      //On click the supply chain is displayed
      showSupplyChainMarker.addEventListener('click', function(){
        showSupplyChainMarker.style.background = 'rgb(178,163,238)';
        showSupplyChainMarker.style.background = 'linear-gradient(45deg, rgba(178,163,238,1) 0%, rgba(192,175,221,1) 33%, rgba(131,151,207,1) 66%, rgba(63,77,105,1) 100%)';
        document.getElementById('popup-title-supply').classList.add('gradient-text');
        
        setTimeout(function () {
          marker.remove();
          popup.remove();

          const timeout = map.value.getZoom() < 2 ? 1000 : 250;

          const target = {
            center: [12.496366, 41.902782],
            zoom: 4,
            bearing: 0,
            pitch: 0
          }

          map.value.flyTo({
            ...target, // Fly to the selected target
            duration: timeout, // Animate over 12 seconds
            essential: true // This animation is considered essential with
            //respect to prefers-reduced-motion
          });

          setTimeout(() => {
            addMarkesAndPopups();
          }, timeout);

        }, 250);
      })

      // Add markers to the map.
      const marker = new mapboxgl.Marker(showSupplyChainMarker);
      marker.setLngLat([12.496366, 41.902782])
      marker.addTo(map.value);

      selectedTab.value = 0;
    }

    const popups = ref([]);
    const markers = ref([]);

    const removeAllPopups = () => {
      popups.value.forEach((p) => {
        p.remove();
      })
    }

    const addLineToMap = (to,id) => {
      map.value.addSource('route'+id.trim(), {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': [
              [12.496365, 41.902782],
              to
            ]
          }
        }
      });

      map.value.addLayer({
        'id': id.trim(),
        'type': 'line',
        'source': 'route'+id.trim(),
        'layout': {},
        'paint': {
          'line-color': '#ABABAB',
          'line-width': 1
        }
      });
    }

    const addMarkesAndPopups = () => {
      const centerMarker = document.createElement('div');
      centerMarker.style.width = '35px';
      centerMarker.style.height = '35px';
      centerMarker.style.border = '2px solid white'
      centerMarker.style.backgroundSize = '100%';
      centerMarker.style.background = 'linear-gradient(45deg, rgba(178,163,238,1) 0%, rgba(192,175,221,1) 33%, rgba(131,151,207,1) 66%, rgba(63,77,105,1) 100%)';
      centerMarker.style.borderRadius = '50%';

      const marker = new mapboxgl.Marker(centerMarker);
      marker.setLngLat([12.496365, 41.902782]);
      marker.addTo(map.value);

      markers.value.push(marker);

      suppylChainSteps.value.forEach(step => {
        addLineToMap(step.latLon, step.stepName);
        const markerInnerHtml = document.createElement('div');
        markerInnerHtml.style.backgroundImage = 'url('+step.iconUrl+')';
        markerInnerHtml.style.width = '35px';
        markerInnerHtml.style.height = '35px';
        markerInnerHtml.style.border = '2px solid white'
        markerInnerHtml.style.backgroundSize = '100%';
        markerInnerHtml.style.backgroundColor = '#ABABAB';
        markerInnerHtml.style.borderRadius = '50%';

        const marker = new mapboxgl.Marker(markerInnerHtml);
        marker.setLngLat(step.latLon);
        marker.addTo(map.value);

        markers.value.push(marker);

        markerInnerHtml.addEventListener('click', function(){
          removeAllPopups();
          const popup = new mapboxgl.Popup({ offset: 25, closeOnClick: false, closeButton: true, anchor: 'left' });
          popup.setLngLat(step.latLon)
          popup.setHTML(
            '<div class="p-grid p-mt-2 p-ml-2">'+
              '<div class="p-col-12 p-d-flex p-ai-center no-p">'+
                '<i class="pi pi-map-marker p-mr-1"></i><h4 id="" class="p-text-center">'+step.location+'</h4>'+
              '</div>'+
              '<div class="p-col-12 p-d-flex p-ai-center no-p">'+
                '<i class="pi pi-angle-right p-mr-1 p-ml-3"></i><h5 id="" class="p-text-center" style="text-decoration: underline;">'+step.stepName+'</h5><i class="pi pi-external-link p-ml-1" style="font-size: 1.5vh;"></i>'+
              '</div>'+
            '</div>'
          )

          popup.addTo(map.value);
          popups.value.push(popup);

          const target = {
            center: step.latLon,
            zoom: 6.5,
            bearing: 0,
            pitch: 0
          }

          map.value.flyTo({
            ...target, // Fly to the selected target
            duration: 2000, // Animate over 12 seconds
            essential: true // This animation is considered essential with
            //respect to prefers-reduced-motion
          });
        });
      });
    }

    /* const xDown = ref();
    const yDown = ref(); */

    /* const getTouches = (evt) => {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }  */                                                    
                                                                            
    /* const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];                                      
        xDown.value = firstTouch.clientX;                                      
        yDown.value = firstTouch.clientY;                                      
    };                                               
                                                                            
    const handleTouchMove = (evt) => {
        if ( ! xDown.value || ! yDown.value ) {
            return;
        }

        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown.value - xUp;
        var yDiff = yDown.value - yUp;
                                                                            
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
            if ( xDiff > 0 ) {
                //Scorri verso destra
              } else {
                //Scorri verso sinistra
            }                       
        } 
        xDown.value = null;
        yDown.value = null;                                             
    } */
   
    return { 
      account_id,
      product_id,
      documents, 
      timelineStyle,
      loadTimeline,
      passportMetadata,
      loadPassportMetadata,
      selectedTab,
      visualizeFlat,
      suppylChainSteps,
      showSummary,
      changeMapVisualization,
      selectTab
    }
  }
};
</script>

<style scoped>
  #marker {
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  #map{
    position: absolute; top: 0; bottom: 0; height: 100vh; width: 100%;
  }

  ::v-deep(.mapboxgl-canvas){
    opacity: 0.5;
  }

  #menu {
    position: absolute;
    background: #fff;
    padding: 10px;
    margin-top: 10px;
    left: 50px;
    font-family: 'Open Sans', sans-serif;
    border-radius: 4px;
  }

  #supplyChainSummary{
    position: absolute;
    background-color: white;
    max-width: 50vw;
    padding: 10px;
    top: 75px;
    right: 0px;
    font-size: 1.2vh;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  ::v-deep(.mapboxgl-popup-tip){
    display: none !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: rgba(0, 0, 0, 0.38) !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: white;
  }

</style>

