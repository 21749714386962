<template>
     <div>

        <DataTable :value="pages" :resizableColumns="true" :paginator="true" :rows="5" columnResizeMode="expand" responsiveLayout="scroll" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" stripedRows
                :rowsPerPageOptions="[5,10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters" filterDisplay="row" removableSort>

                <template #header>
                    <div class="table-header-container">
                        <div class="p-d-flex p-jc-between p-ai-center" style="text-align:left">
                            <h2>{{title}}</h2>
                            <MultiSelect v-if="showSelectedColumns" v-model="selectedColumns" :options="columns" placeholder="Select Columns" style="width: 20em"/>
                        </div>
                    </div>
                </template>
                
                <!-- <Column v-if="lineSelectable" selectionMode="multiple" headerStyle="width: 3em"></Column> -->

                <Column v-if="showDetails" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-search" @click="showDetail(slotProps.data)" class="purple-diagonal purple-btn"></Button>
                    </template>
                </Column>

                <!-- <Column v-if="removable" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-trash" @click="$emit('removeProduct', slotProps.data.id)" class="purple-diagonal purple-btn"></Button>
                    </template>
                </Column> -->

                <Column v-if="selectedColumns.includes('ID')" :sortable="true" field="id" header="ID" filterField="id">
                  <template #body="slotProps">
                    {{slotProps.data.id}}
                  </template>
                  <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nome')" :sortable="true" field="page_name" header="Nome" filterField="page_name">
                  <template #body="slotProps">
                    {{slotProps.data.page_name}}
                  </template>
                  <template #filter="{filterModel,filterCallback}">
                    <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per nome'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Immagine Sfondo')" :sortable="true" field="parameters.backgroundImgMobile" header="Immagine Sfondo">
                  <template #body="slotProps">
                    <div class="p-col-12 p-d-flex p-jc-center p-text-center p-ai-center">
                      <Avatar :image="slotProps.data.parameters.backgroundImgMobile"  class="mr-2 cursor-pointer p-mx-2" size="large" shape="square"/>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Gif')" :sortable="true" field="parameters.backgroundImgMobile" header="Gif">
                  <template #body="slotProps">
                    <div class="p-col-12 p-d-flex p-jc-center p-text-center p-ai-center">
                      <Avatar :image="slotProps.data.parameters.gifSrc"  class="mr-2 cursor-pointer p-mx-2" size="large" shape="square"/>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Anteprima Video')" :sortable="true" field="parameters.backgroundImgMobile" header="Anteprima Video">
                  <template #body="slotProps">
                    <div class="p-col-12 p-d-flex p-jc-center p-text-center p-ai-center">
                      <Avatar :image="slotProps.data.parameters.coverVideo"  class="mr-2 cursor-pointer p-mx-2" size="large" shape="square"/>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Immagini')" :sortable="true" field="parameters.backgroundImgMobile" header="Immagini">
                  <template #body="slotProps">
                    <div class="p-col-12 p-d-flex p-jc-center p-text-center p-ai-center">
                      <div v-for="(input, index) in slotProps.data.parameters.images" :key='index'>
                        <Avatar :image="input.itemImageSrc"  class="mr-2 cursor-pointer p-mx-2" size="large" shape="square"/>
                      </div>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Logo')" :sortable="true" field="parameters.backgroundImgMobile" header="Logo">
                  <template #body="slotProps">
                    <div class="p-col-12 p-d-flex p-jc-center p-text-center p-ai-center">
                      <Avatar :image="slotProps.data.parameters.logoImg"  class="mr-2 cursor-pointer p-mx-2" size="large" shape="square"/>
                    </div>
                  </template>
                </Column>
                
                <template #paginatorLeft>
                    <Button type="button" icon="pi pi-refresh" @click="$emit('reload')" class="p-button-text" />
                </template>
            </DataTable>
      </div>
</template>

<script>

import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import Avatar from 'primevue/avatar';

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: "Timelines",
  components: { Button, DataTable, Column, InputText, MultiSelect, Avatar },
  props : {
      pages: Array,
      showDetails: Boolean,
      assignable: Boolean,
      title: String,
      showSelectedColumns: Boolean
  },
  emits: ["assignTimeline"],
  setup(){

    onMounted(() => {

    })

    const router = useRouter();

    const showDetail  = (data) => {
        console.log("Data: ", data);
        router.push('/pages/productPageDetailV2/'+data.account_id+'&'+data.id);
    }

    const selectedColumns = ref(['ID','Nome','Immagine Sfondo','Gif','Anteprima Video']);
    const columns = ref(['ID','Nome','Immagine Sfondo','Gif','Anteprima Video','Immagini','Logo']);

    const filters = ref({
        'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'page_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    });

    return { showDetail, selectedColumns, columns, filters }
  }
};
</script>

<style scoped>

 ::v-deep(.step-small-text){
    font-size: 0.9vw !important;
  }

  ::v-deep(.step-small-icon){
    font-size: 0.7vw !important;
  }

  ::v-deep(.step-description-text){
    font-size: 1.1vw !important;
  }
  
  .card {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
  }

  .product-name {
    font-size: 1.5rem;
    font-weight: 700;
  }

  ::v-deep(.product-list-item) {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }

  ::v-deep(.product-list-item img) {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-right: 2rem;
  }

  ::v-deep(.product-list-item .product-list-detail) {
    flex: 1 1 0;
  }

  ::v-deep(.product-list-item .product-list-action) {
    display: flex;
    flex-direction: column;
  }

  ::v-deep(.product-list-item .p-button) {
    margin-bottom: .5rem;
  }
  

  ::v-deep(.product-grid-item) {
    margin: .5rem;
    border: 1px solid var(--surface-border);
  }
    
  ::v-deep(.product-grid-item .product-grid-item-bottom) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ::v-deep(.product-grid-item img) {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin: 2rem 0;
    max-width: 100%;
    border-radius: 5%;
  }

  ::v-deep(.product-grid-item .product-grid-item-content) {
    text-align: center;
  }

</style>



