<template>
     <div>
        <div class="card">
            <DataTable :value="productLines" :resizableColumns="true" :paginator="true" :rows="5" columnResizeMode="expand" responsiveLayout="scroll" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" stripedRows
                :rowsPerPageOptions="[5,10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters" filterDisplay="row" removableSort>

                <template #header>
                    <div class="p-d-flex p-jc-between p-ai-center" style="text-align:left">
                        <h2>{{title}}</h2>
                        <MultiSelect v-model="selectedColumns" :options="columns" placeholder="Select Columns" style="width: 20em"/>
                    </div>
                </template>

                <Column v-if="showRowDetail" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-search" @click="showDetail(slotProps.data)" class="purple purple-btn"></Button>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nome')" :sortable="true" field="name" header="Nome" filterField="name">
                    <template #body="slotProps">
                        {{slotProps.data.name}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per nome'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descrizione')" filterField="description" field="description" :sortable="true" header="Descrizione">
                    <template #body="slotProps">
                        {{slotProps.data.description}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>
                
                <Column v-if="selectedColumns.includes('ID')" filterField="id" :sortable="true" field="id" header="ID">
                    <template #body="slotProps">
                        {{slotProps.data.id}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>
                
                <template #paginatorLeft>
                    <Button type="button" icon="pi pi-refresh" @click="loadProductLines()" class="p-button-text" />
                </template>
            </DataTable>
        </div>
      </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import { useRouter, useRoute } from 'vue-router';

import { ref, onMounted } from 'vue';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: "ProductLinesTable",
  components: { DataTable, Column, Button, InputText, MultiSelect },
  props: {
      title: String,
      showRowDetail : Boolean,
      productLines: Array
  },
  setup(){
    onMounted(() => {
    })


    const expandedRows = ref([]);

    const router = useRouter();
    
    const filters = ref({
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
    });

    const route = useRoute();

    const account_id = route.params.data;

    const showDetail  = (data) => {
        console.log(data);
        router.push('/lineDetail/'+data.id_account+'&'+data.id);
    }

    const selectedColumns = ref(['Nome','ID']);
    const columns = ref(['Nome','Descrizione','ID']);

    return { expandedRows, account_id, filters, selectedColumns, columns, showDetail}
  }
};
</script>

<style scoped>

</style>

