<template>
    <div @touchstart="handleTouchStart" @touchmove="handleTouchMove" class="image-container p-d-flex p-ai-center">
        <Image id="slideshow-img" v-if="currentSlideshowContent.type == 'img'" :imageStyle="{ maxWidth: '100%', height: '' }" :src="currentSlideshowContent.itemImageSrc" alt="Image"/>
        <video v-else style="width: 100% !important;" controls :src="currentSlideshowContent.itemImageSrc" playsInLine></video>
    </div> 

    <div class="p-col-12 p-pl-3 slideshow-caption p-py-3" style="margin-top: -4px !important;">
        <h4 class="sans-bold" style="margin-bottom: .5rem;">{{currentSlideshowContent.title}}</h4>
        <p>{{currentSlideshowContent.description}}</p>
    </div>

    <div class="p-d-flex p-jc-center p-text-center p-mt-2 p-ai-center" style="width: 100%;">
        <span class="p-mr-1" v-for="(input, index) in slideshowContent" :key='index' style="border-radius: 50%; " :style="[index==imgIndex ? {width: '20px', height: '20px', backgroundColor: '#B8BE9C'} : {width: '10px', height: '10px', backgroundColor: '#575756'}]"></span>
    </div>  

</template>

<script>

import Image from 'primevue/image';
import { onMounted } from '@vue/runtime-core';

import { ref } from 'vue';

export default {
    name: "Slideshow",
    components: { Image },
    props: {
        slideshowContent : Array
    },
    setup(props){

        onMounted(() => {

        })

        const currentSlideshowContent = ref(props.slideshowContent[0]);
        const imgIndex = ref(0);

        const swipeRight = () => {
            if(imgIndex.value < props.slideshowContent.length){
                imgIndex.value += 1;
                currentSlideshowContent.value = props.slideshowContent[imgIndex.value];
            }
        }

        const swipeLeft = () => {
            if(imgIndex.value > 0){
                imgIndex.value += -1;
                currentSlideshowContent.value = props.slideshowContent[imgIndex.value];
            }
        }

        const xDown = ref(null);                                                        
        const yDown = ref(null);

        const getTouches = (evt) => {
            return evt.touches ||             // browser API
                evt.originalEvent.touches; // jQuery
        }                                                     
                                                                                
        const handleTouchStart = (evt) => {
            const firstTouch = getTouches(evt)[0];                                      
            xDown.value = firstTouch.clientX;                                      
            yDown.value = firstTouch.clientY;                                      
        };                                               
                                                                                
        const handleTouchMove = (evt) => {
            if ( ! xDown.value || ! yDown.value ) {
                return;
            }

            var xUp = evt.touches[0].clientX;                                    
            var yUp = evt.touches[0].clientY;

            var xDiff = xDown.value - xUp;
            var yDiff = yDown.value - yUp;
                                                                                
            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
                if ( xDiff > 0 ) {
                    swipeRight();
                } else {
                    swipeLeft();
                }                       
            } 
            /* reset values */
            xDown.value = null;
            yDown.value = null;                                             
        }

        return { 
            imgIndex,
            currentSlideshowContent,
            swipeRight,
            swipeLeft,
            handleTouchStart,
            handleTouchMove
        }
    }
    
}

</script>

<style scoped>

.image-container{
    width: 100%;
    aspect-ratio: 1/1;
    background-color: #1a1a1a;
}
    
</style>

