<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items" />
    <Toast />

    <div class="p-ml-4 p-mt-5">
      <Message v-if="!line_id" class="p-mr-4" severity="info">Per registrare la linea è necessario assegnarle un nome e una descrizione e specificare quanti prodotti dovrà contenere.</Message>  
      <Message v-if="line_id" class="p-mr-4" severity="info">Inserisci il numero di prodotti che desideri aggiungere alla linea.</Message>  
    </div>

    <div class="p-mt-5 p-mx-3 p-mb-5">
      <div class="p-grid p-jc-between p-mr-2 p-ml-3 p-mt-5">

        <div class="p-fluid p-formgrid p-col-12 p-grid p-md-7 p-shadow-2 rounded-5 p-p-3">
          <div class="p-col-12">

            <h3>Nome linea</h3>
            <span class="p-float-label p-mt-2">
              <InputText v-model="name" id="name" type="text" placeholder="Nome" :disabled="line_id"/>
            </span>

            <div >
              <h3 class="p-mt-5">Descrizione linea</h3>
              <span class="p-float-label p-mt-2">
                <TextArea v-model="description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="line_id"/>
              </span>
            </div>

            <div class="p-grid p-ai-center p-vertical-container p-mt-5 p-mb-5">
              <h3 class="p-col-12">Numero prodotti</h3>
              <span class="p-float-label p-mt-2 p-ml-2">
                <InputText v-model="limit" id="limit" type="text" autocomplete="off" @keydown.enter="loadProds()" v-tooltip.bottom.focus="'Premi invio per caricare i prodotti'" placeholder="Numero prodotti" @click="setFlag()" @keypress="isNumber($event)"/>
              </span>
              <span class="p-float-label p-mt-2 p-ml-4">
                <Button :disabled="!limit" type="button" icon="pi pi-download" @click="loadProds()" class="purple purple-btn"></Button>
              </span>
            </div>

            <div v-if="showProducts && description && name && limit" class="p-col-12" style="padding-left: 0px !important; padding-right: 0px !important">
              <div class="p-mt-4">
                <Button label="Registra" v-if="!line_id" :disabled="!selectedTags || !selectedTags.length"  icon="pi pi-pencil" class="purple purple-btn" style="font-size: 1.5em" @click="onSave()"/>
                <Button label="Aggiungi" v-if="line_id" :disabled="!selectedTags || !selectedTags.length"  icon="pi pi-plus" class="purple purple-btn" style="font-size: 1.5em" @click="registerProducts()"/>
              </div>
            </div>

          </div>
        </div>

        <div class="p-col-12 p-md-5 p-shadow-2 rounded-5 p-p-3" style="max-eight: 80vh !important">
          <Message v-if="!showProducts" severity="info">Una volta inserito il numero di prodotti da registrare sarà possibile selezionarli da questo riquadro.</Message>
          <Message v-if="limit>products.length && showProducts && !line_id" severity="warn">Il numero di prodotti richiesto <b>({{limit}})</b> è superiore al numero di prodotti liberi <b>({{products.length}})</b>. Saranno assegnati alla linea solo {{products.length}} prodotti</Message>
          <Message v-if="limit>products.length && showProducts && line_id" severity="warn">Il numero di prodotti richiesto <b>({{limit}})</b> è superiore al numero di prodotti liberi <b>({{products.length}})</b>. Saranno aggiunti alla linea solo {{products.length}} prodotti</Message>

          <div v-if="showProducts">
            <h4 v-if="limit<=products.length">I prodotti selezionati ({{selectedTags.length}}) saranno assegnati alla linea di prodotto corrente.</h4>
            <products-table class="p-mt-3" title="Lista tag registrabili" :products="products" :showRowDetail="false" :lineSelectable="true" 
                :showRemoveSwitch="false" :selectedAll="true" :showIdTag="true" :showID="false" :showName="false" :showDescription="false" 
                :showCat="false" :showLine="false" :showTimeline="false" :showPage="false" :showDocs="false" :showSelectedColumns="false" @reload="reload" @selectedTags="setSelectedTags">
            </products-table>
          </div>

        </div>
        
      </div>
    </div>

    <Dialog header="Conferma" :showHeader="!showSpinner" v-model:visible="showConfirm" :style="{width: '50vw'}" :modal="true">
      <div v-if="!showSpinner">
        Vuoi procedere al caricamento della linea? Nessuna transazione verrà scritta su blockchain.
      </div>

      <div v-else>
        <div class="p-text-center p-mt-3">
          <h2>Attendere il caricamento del documento.</h2>
          <ProgressSpinner />
        </div>
      </div>

      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirm" />
        <Button label="Si" icon="pi pi-check" @click="registerLine()" />
      </template>
    </Dialog>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

import NavigationBar from '../components/NavigationBar';
import ProductService from '../../service/ProductService';
import AccountService from '../../service/AccountService';
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useToast } from 'primevue/usetoast';


export default {
  name: "Create supplier",
  components: {  NavigationBar, ProductsTable, Breadcrumb, Toast, Message, InputText, TextArea, Button, Dialog, ProgressSpinner },

  setup(){
    const toast = useToast();

    onMounted(() => {
      setBreadCrumb();
      if(line_id){
        loadLineInfo();
      }
      //loadProds(account_id, 1000000);
    })

    const productService = ref(new ProductService());
    const accountService = ref(new AccountService());

    const products = ref([]);

    const selectedTags = ref([]);

    const limit = ref();
    const name = ref();
    const description = ref();

    const selectedAll = ref(true);
    const showProducts = ref(false);

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const line_id = route_params.split('&')[1];

    const line_products = ref([]);

    const home = ref({
      icon: 'pi pi-home', 
      to: '/',
    });

    const items = ref([]);

    const setBreadCrumb = () => {
      if(line_id){
        (items.value).push(
          {
            label: 'Linea ('+line_id+')',
            to: '/lineDetail/'+account_id+'&'+line_id
          },
          {
            label: 'Modifica linea'
          }, 
        )
        /* toast.add({severity: 'warn', summary: 'Timeline già registrata', detail: "Ogni modifica sostituirà la configurazione corrente", life: 30000}); */
      }else{
        (items.value).push(
          {
            label: 'Linee prodotto',
            to: '/productLines/'+account_id
          },
          {
            label: 'Crea nuova linea'
          },
        )
      }
    }

    const loadProds = () => {
      showProducts.value = false;

      productService.value.getFreeProducts(account_id, limit.value).then(data => {
        console.log("Prodotti", data.docs);
        products.value = data.docs;
        showProducts.value = true;
      })
    };

    const reload = () => {
      loadProds(account_id, limit.value)
    }

    const setSelectedTags = (rows) => {
      console.log("Righe: ", rows.value);
      selectedTags.value = rows.value;
    }

    const loadLineInfo = () => {
      accountService.value.getLineById(line_id).then(data => {
        line_products.value = data.docs[0].products;
        name.value = data.docs[0].name;
        description.value = data.docs[0].description;
      })
    }

    const showConfirm = ref(false);
    const enableUpload = ref(false);
    const showSpinner = ref(false);

    const closeConfirm = () => {
      showConfirm.value = false;
    }

    const onSave = () => {
      showConfirm.value = true;
    }

    const registerLine = () => {
      accountService.value.registerLine(account_id, name.value, description.value, selectedTags.value, null).then( response => {
        if(response == 200){
          toast.add({severity: 'success', summary: 'Success', detail: 'Line uploaded', life: 5000});
          name.value = "";
          description.value = "";
          limit.value = "";
          products.value = [];
          selectedTags.value = [];
          showProducts.value = false;
          showSpinner.value = false;
          showConfirm.value = false;
        }
      });
    }

    const registerProducts = () => {
      productService.value.registerProductLine(line_id, selectedTags.value).then( response => {
        if(response == 200){
          toast.add({severity: 'success', summary: 'Success', detail: 'Line updated', life: 5000});
          limit.value = "";
          products.value = [];
          selectedTags.value = [];
          showProducts.value = false;
        }
      });
    }

    const isNumber = (evt) => {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }

    const setFlag = () => {
      showProducts.value = false;
    }

    return { selectedTags, setFlag, line_products, line_id, home, isNumber, showConfirm, 
      items, showProducts, loadProds, onSave, account_id, products, productService, enableUpload, showSpinner, closeConfirm,
      selectedAll, reload, limit, name, description, registerLine, setSelectedTags, registerProducts}
  }
};
</script>

<style scoped>
  .a {
    max-height: 80vh !important;
  }
</style>

