<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items" />
    <Toast />

    <div class="p-ml-4 p-mt-5">
      <Tag class="p-mr-2 purple p-p-2" rounded><h2>Configura Timeline</h2><InputSwitch class="p-ml-3" v-model="configureTimeline" /></Tag>
      <Message v-if="!configureTimeline" class="p-mr-5" severity="warn">Una volta caricato il documento <b>non sarà più possibile</b> inserire le informazioni relative alla timeline. Se si desidera configurare il pannello visualizzare la sezione apposita tramite il tasto sopra.</Message>  
    </div>

    <div class="p-mt-5 p-mx-3">

      <div class="p-grid p-jc-between p-ml-2 p-mr-3 p-mt-5">
        <div class="p-field p-col-12 p-shadow-2 rounded-5 p-p-3">
          <h2 class="p-mb-3">File</h2>
          <FileUpload id="document" :showUploadButton="((docName != null) & (docDescr != null) & (selectedProducts.length | allLine | allCategory)) == 0 ? false : true" name="file" @uploader="onUpload" :customUpload="true" :multiple="false" :maxFileSize="100000000">
              <template #empty>
                  <p>Trascina o scegli il file da caricare</p>
              </template>
          </FileUpload>
          <Message class="" severity="warn">E' possibile caricare un solo file per volta.</Message>
        </div>
      </div>
     
      <div class="p-grid p-jc-between p-mx-3 p-ai-start">
        <div class="p-fluid p-formgrid p-grid p-col-12 p-md-7 p-shadow-2 rounded-5">
          <h2 class="p-ml-2 p-mb-3">Dati documento</h2>
          <div class="p-field p-col-12">
            <label for="docName">Nome</label>
            <InputText id="docName" type="text" v-model="docName"/>
          </div>

          <div class="p-field p-col-12">
            <label for="docDescr">Descrizione</label>
            <TextArea v-model="docDescr" id="description" type="text"/>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <div class="p-grid p-col-12">
              <label for="line">Linea</label>
              <Dropdown class="p-col-12" id="line" v-model="selectedLine" :showClear="true" @change="loadLineCategories()" :options="productLines" optionLabel="name" optionValue="id" placeholder="Select a Line" />
              <div class="p-col-12 p-mt-2 p-d-flex p-ai-center">
                <Checkbox id="allLine" v-model="allLine" :binary="true" @change="selectAll" :disabled="!selectedLine"/>
                <label class="p-ml-2" for="allLine">Valido per tutta la linea</label>
              </div>
            </div>
          </div>
          
          <div v-if="!allLine && selectedLine" class="p-field p-col-12 p-md-6">
            <div class="p-grid p-col-12">
              <label for="category">Categoria</label>
              <MultiSelect class="p-col-12" v-model="selectedCategories" :options="lineCategories" @change="loadCategoryProducts()" optionLabel="name" optionValue="id" placeholder="Select Categories" />
              <div class="p-col-12 p-mt-2 p-d-flex p-ai-center">
                <Checkbox id="allCategory" v-model="allCategory" :binary="true" @change="selectAll"  :disabled="!selectedCategories.length"/>
                <label class="p-ml-2" for="allCategory">Valido per tutta la categoria</label>
              </div>
            </div>
          </div>

          <Message v-if="!(allLine || allCategory) && products.length" class="p-mx-2" severity="info">Seleziona i prodotti per cui registrare il documento ({{selectedProducts.length == 0 ? '0' : selectedProducts.length}})</Message>
        </div>

        <div class="p-col-12 p-md-5 p-shadow-2 rounded-5">
          <Message v-if="allLine" class="p-mx-2" severity="info">Il documento verrà registrato per tutti i prodotti della linea selezionata</Message>
          <Message v-if="allCategory" class="p-mx-2" severity="info">Il documento verrà registrato per tutti i prodotti delle categorie selezionate</Message>
          <Message v-if="!(allLine || allCategory) && !products.length" class="p-mx-2" severity="info">Scegli una linea e una categoria per visualizzare i prodotti selezionabili</Message>
          
          <products-table class="p-mt-3" v-if="products.length && !(allLine || allCategory)" title="Lista prodotti registrabili" :products="products" :showRowDetail="true" :lineSelectable="true" 
          :showRemoveSwitch="false" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="false" :showName="true" :showDescription="false" 
          :showCat="false" :showLine="false" :showTimeline="false" :showPage="false" :showDocs="false" :showSelectedColumns="false" @selectedTags="setSelectedProducts"
          ></products-table>
        </div>
      </div>

      <div v-if="configureTimeline" class="p-grid p-jc-between p-ml-2 p-mr-3 p-mt-5">
        <div class="p-field p-col-12 p-shadow-2 rounded-5 p-p-3">
          <div class="p-d-flex p-ai-center">
            <h2 class="p-ml-2">Hastags</h2>
            <Button icon="pi pi-plus" class="p-ml-4 p-button-rounded purple-diagonal" @click="addTag()"/>
          </div>
          <div class="p-grid p-col-12">
            <div v-for="(input, index) in tags" :key='index'>

              <div class="p-field p-col-12">
                <label for="docName">Nome</label>
                <div class="p-my-auto">
                  <InputText id="docName" type="text" :placeholder="input.tagName" v-model="input.tagName"/>
                  <Button icon="pi pi-trash" class="p-ml-3 p-button-rounded purple-diagonal" @click="removeTag(index)"/>
                </div>
              </div>

            </div>
          </div>
          
          <h2 class="p-mt-5 p-ml-2">Configurazione timeline</h2>
          <div class="p-grid">
            <div class="p-md-12">
              <div class="p-fluid p-formgrid p-grid p-col-12">
                <div class="p-field p-col-12 p-md-6">
                  <label for="timelineName">Nome</label>
                  <InputText id="timelineName" type="text" v-model="timelineName"/>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="timelineLocation">Luogo</label>
                  <InputText id="timelineLocation" type="text" v-model="timelineLocation"/>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="timelineImage">Immagine (Link)</label>
                  <InputText id="" v-model="imageUrl" type="text"/>
                </div>

                <div class="p-field p-col-12 p-md-6">
                  <label for="timelineDescription">Descrizione</label>
                  <TextArea id="timelineDescription" type="text" v-model="timelineDescription"/>
                </div>

                <div class="p-field p-col-12 p-md-6">
                    <label for="Icon">Icona</label>
                    <Dropdown v-model="selectedIcon" :options="icons" @change="setSelectedIcon()" optionLabel="reference" placeholder="Scegli icona">
                      <template #value="slotProps">
                          <div><font-awesome-icon v-if="slotProps.value.class" :icon="slotProps.value.icon" class="p-mr-3"/> {{slotProps.value.reference}}</div>
                      </template>
                      <template #option="slotProps">
                          <div><font-awesome-icon v-if="slotProps.option.class" :icon="slotProps.option.icon" class="p-mr-3"/> {{slotProps.option.reference}}</div>
                      </template>
                    </Dropdown>
                </div>

              </div>
            </div>
            
          </div>
        </div>
      </div>

      <Dialog header="Conferma" :showHeader="!showSpinner" v-model:visible="showConfirm" :style="{width: '50vw'}" :modal="true">
        <div v-if="!showSpinner">
          <div>
            Caricando il documento confermi di essere al corrente di quanto segue:<br/>
            <ul class="p-mt-1">
              <li>Il documento verrà caricato su IPFS e il suo hash verrà registrato su una transazione in blockchain;</li>
              <li><b>Non è in alcun modo possibile</b> rimuovere una transazione dalla blockchain;</li>
              <li>È possible eliminare o modificare un documento precedentemente caricato su IPFS, ma il documento può comunque sopravvivere nel suo formato originale.</li>
            </ul>
          </div>

          <div class="p-mt-5">
            <Checkbox id="allCategory" v-model="enableUpload" :binary="true"/>
            <label class="p-ml-2" for="allCategory">Presto il consenso al caricamento del documento.</label>
          </div>
        </div>

        <div v-else>
          <div class="p-text-center p-mt-5">
            <h2>Attendere il caricamento del documento.</h2>
            <Message class="p-mt-3" severity="warn">Non uscire o ricaricare la pagina fino al termine dell'operazione.</Message>
            <ProgressSpinner class="" />
          </div>
        </div>

        <template #footer>
          <Button v-if="!showSpinner" label="Carica" icon="pi pi-upload" @click="confirmUpload" :disabled="!enableUpload" />
        </template>
      </Dialog>

    </div>

  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import FileUpload from 'primevue/fileupload';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Tag from 'primevue/tag';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';


import NavigationBar from '../components/NavigationBar';
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted, watch } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import { useToast } from 'primevue/usetoast';

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Message, ProgressSpinner, Checkbox, FileUpload, MultiSelect, Toast, InputText, TextArea, Dropdown, ProductsTable, InputSwitch, Tag, Button, Dialog },

  setup(){
    onMounted(() => {
      getLines(account_id);
      loadProducts();
    });

    const toast = useToast();

    const route = useRoute();
    const router = useRouter();

    const account_id = route.params.data;

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const selectedLine = ref();
    const productLines = ref([]);

    const selectedCategories = ref([]);
    const lineCategories = ref([]);

    watch(() => selectedCategories.value.length, (v) => {
      if(v==0){
        allCategory.value = false;
      }
    });

    const products = ref([]);

    const selectedProducts = ref([]);

    const configureTimeline = ref(false);

    const tags = ref([]);

    const icons = ref([ 
      { icon: ['fas', 'industry'], class: "badge-icon fa-industry", reference: "Processo industriale" },
      { icon: ['fas', 'search'], class: "badge-icon fa-search", reference: "Ricerca" },
      { icon: ['fas', 'exchange-alt'], class: "badge-icon fa-exchange-alt", reference: "Logistica" },
      { icon: ['fas', 'medal'], class: "badge-icon fa-medal", reference: "Prodotto" },
      { icon: ['fas', 'box-open'], class: "badge-icon fa-box-open", reference: "Packaging" },
      { icon: ['fas', 'brain'], class: "badge-icon fa-brain", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'user-shield'], class: "badge-icon fa-user-shield", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'lock'], class: "badge-icon fa-lock", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'map-marker-alt'], class: "badge-icon fa-map-marker-alt", reference: "Proprietario" },
    ]);

    const selectedIcon = ref([]);

    const selectedIconClass = ref("");
    const selectedIconIcon = ref([]);

    const setSelectedIcon = () => {
      selectedIconClass.value = selectedIcon.value.class;
      selectedIconIcon.value = selectedIcon.value.icon;
    }

    const addTag = () => {
      tags.value.push({ tagName : ""});
    }

    const removeTag = (index) => {
      tags.value.splice(index, 1);
    }

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Carica documento'},
    ]);

    const formLayout = ref();
    const productTableLayout = ref();

    formLayout.value = "p-fluid p-formgrid p-grid p-col-12";
    productTableLayout.value = "hidden"

    const selectAll = () => {
      if(allLine.value){
        formLayout.value = "p-fluid p-formgrid p-grid p-col-12";
        productTableLayout.value = "hidden";
        allCategory.value = false;
        selectedCategories.value = [];
        products.value = [];
      }else if(allCategory.value){
        formLayout.value = "p-fluid p-formgrid p-grid p-col-12";
        productTableLayout.value = "hidden";
        allLine.value = false;
      }else{
        formLayout.value = "p-fluid p-formgrid p-grid p-col-6";
        productTableLayout.value = "p-col-6"
      }

    }

    const getLines = (account_id) => {
      accountService.value.getProductLines(account_id).then(data => {
          console.log("productLines: ",data);
          productLines.value = data;
        })
    }

    const liStyle = ref('list-style-type: none;');

    const loadLineCategories = () => {
      if(!selectedLine.value){
        loadProducts()
      }else{
        products.value = [];
      }
      accountService.value.getCategoriesByLineId(selectedLine.value, 1000000).then(data => {
        lineCategories.value = data;
      })
    }

    const loadCategoryProducts = () => {
      productService.value.getProductsByCategoryId(selectedCategories.value, 1000000).then(data => {
        products.value = data;
        /* console.log("Data: ", products.value); */
      })
      formLayout.value = "p-fluid p-formgrid p-grid p-col-6";
      productTableLayout.value = "p-col-6"
    }

    const loadProducts = () => {
      productService.value.getProducts(account_id, 1000000).then(data => {
        products.value = data.docs;
      })
      formLayout.value = "p-fluid p-formgrid p-grid p-col-6";
      productTableLayout.value = "p-col-6"
    }
    
    const selectedFile = ref();

    const onFileChange = (e) => {
      console.log("Event: ",e);
      selectedFile.value = e.target.files[0]; // accessing file
      /* console.log("File: ", selectedFile.value); */
    };

    const showConfirm = ref(false);
    const enableUpload = ref(false);
    const showSpinner = ref(false);

    const closeConfirm = () => {
      showConfirm.value = false;
    }

    const confirmUpload = () => {
      showSpinner.value = true;
      let timelineData = {
        "timelineLocation" : timelineLocation.value,
        "timelineName" : timelineName.value,
        "timelineDescription" : timelineDescription.value,
        "timelineImage" : imageUrl.value
      }

      let pub_key = '9937c539df01643ccfc1bc88df5c5f7db0cf3f9f9f5c636fa3b967bba733323d'

      accountService.value.addDocumentFile(selectedFile.value, pub_key).then((res) => {
        console.log("Risposta: ", res);
        let metadataBlockchain = {
          "ipfs": res.payload.ipfs,
          "link": res.payload.link_file,
          "tx": res.message_id
        }
        accountService.value.addDocument(null, docName.value, docDescr.value, selectedLine.value, selectedCategories.value, account_id, timelineData, tags.value, selectedProducts.value, allCategory.value, allLine.value, selectedIconIcon.value, selectedIconClass.value, metadataBlockchain, pub_key, '', true).then((res) => {
          console.log("Risposta: ", res);
          toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 5000}); 
          showSpinner.value = false;
          showConfirm.value = false;
          router.push('/');
        })
        
      });
    }

    const onUpload = (e) => {
      selectedFile.value = e.files[0];
      showConfirm.value = true;
    }

    const setSelectedProducts = (rows) => {
      selectedProducts.value = rows.value;
    }

    const docName = ref();
    const docDescr = ref();

    const timelineName = ref();
    const timelineLocation = ref();
    const timelineDescription = ref();

    const imageUrl = ref();

    const allLine = ref(false);
    const allCategory = ref(false);

    const parameters = ref({

        "extBorderTickness" : 0,
        "extBorderRadius" : 6,
        "extBorderColor" : "000000",
        "extBorderShadow" : "f5f5f5",

        "intBorderThickness" : 0,
        "intBorderRadius" : 6,
        "intBorderColor" : "000000",

        "imgBorderThickness" : 0,
        "imgBorderRadius" : 6,
        "imgBorderColor" : "000000",

        "infoBorderTickness" : 0,
        "infoBorderRadius" : 6,
        "infoBorderColor" : "000000",
        "infoBorderShadow" : "f5f5f5",

        "tagBorderTickness" : 0,
        "tagBorderRadius" : 6,
        "tagBorderColor" : "000000",
        "tagBgColor" : "ffffff",

        "primaryTextBodyColor" : "000000",
        "primaryTextTitleColor" : "000000",
        "secondaryTextTitleColor" : "000000",
        "secondaryTextBodyColor" : "000000",
        "externalLabelTextColor" : "59994c",
        "internalLabelTextColor" : "ffffff",

        "primaryLabelColor" : "59994c",
      });

    return { home, items, addTag, removeTag, productTableLayout, selectedFile, imageUrl, formLayout, onUpload, 
            allCategory, allLine, selectAll, setSelectedIcon, liStyle, showConfirm, closeConfirm, enableUpload,
            onFileChange, setSelectedProducts, selectedProducts, account_id, docName, docDescr, timelineLocation, timelineName, 
            timelineDescription, getLines, productLines, selectedLine, loadLineCategories, loadCategoryProducts, lineCategories, 
            selectedCategories, products, configureTimeline, confirmUpload, showSpinner,
            icons, selectedIconIcon, selectedIconClass, selectedIcon, tags, parameters
          }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }
</style>

