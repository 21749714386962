<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items" />
    <Toast />

    <div class="p-mt-4 p-mx-3 p-mb-5">
      <div class="p-grid p-jc-between p-mr-2 p-ml-3">

        <div class="p-d-flex p-col-12 p-jc-end">
          <Button label="Registra" icon="pi pi-pencil" class="purple-btn purple p-mt-3 p-col-12 p-md-2" style="font-size: 1.5em; margin-right: 0px !important;" :disabled="!(name && description)" @click="registerProduct()"/>
        </div>

        <div class="p-mt-3 p-mb-3 p-col-12 p-grid p-jc-between p-ai-center field-radiobutton p-shadow-2 rounded-5 p-p-3">
          <div class="p-col-12 p-md-3">
            <div class="p-d-flex p-ai-center">Inserisci ID prodotto <InputSwitch class="p-ml-3" v-model="insertTag"/></div>
          </div>

          <div class="p-col-12 p-md-9 p-d-flex p-ai-center" v-if="insertTag">
            <InputText v-model="tagToSearch" id="name" type="text" placeholder="tag ID"/>
            <Button icon="pi pi-search" class="purple-diagonal purple-btn p-ml-3" style="font-size: 1.5em" :disabled="!(tagToSearch)" @click="loadProductInfo()"/>
          </div>

          <Message v-if="productLine" severity="error">Il prodotto ha già una linea ({{productLine}}). Puoi registrare un prodotto solo se non è ancora stato assegnato ad una linea.</Message>

        </div>

        <h3 class="p-col-12 p-d-flex p-ai-center" v-if="timelineId">E' stata selezionata la timeline: {{timelineId}} <Button type="button" icon="pi pi-trash" @click="removeTimeline()" class="purple-diagonal purple-btn p-ml-2"></Button></h3> 

        <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
          <div class="p-col-12 p-mb-5">
            <div class="p-col-5">
              <RadioButton class="p-mr-1" id="option1" name="options" :value="1" v-model="selection" @change="setOptions()"/>
              <label for="option1">Crea da tag</label>

              <RadioButton class="p-ml-5 p-mr-1" id="option2" name="options" :value="2" v-model="selection" @change="setOptions()"/>
              <label for="option2">Parti da una linea</label>

              <RadioButton class="p-ml-5 p-mr-1" id="option3" name="options" :value="3" v-model="selection" @change="setOptions()"/>
              <label for="option3">Crea da zero</label>
            </div>
          </div>

          <div class="p-col-12">

            <div v-if="selection==1" class="p-col-12 overflow-break">

              <div class="p-grid">
                <div class="p-col-12 p-md-6">
                  <h3>Nome Prodotto</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="name" id="name" type="text"/>
                  </span>

                  <h3 class="p-mt-3">Descrizione Prodotto</h3>
                  <span class="p-float-label p-mt-2">
                    <TextArea v-model="description" id="description" type="text"/>
                  </span>
                </div>

                <div class="p-md-6 p-col-12">
                  <div class="p-mx-5">

                    <h3>Il seguente prodotto verrà registrato con nome e descrizione specificati.</h3>
                    
                    
                    <h4 class="p-mt-3" for="id">ID:</h4>
                    <span class="p-float-label p-mt-2">
                      {{product_id}}
                    </span>

                    <h4 class="p-mt-3" for="id">Tag ID:</h4>
                    <span class="p-float-label p-mt-2">
                      {{product_tag}}
                    </span>
                  </div>

                </div>
              </div>
            </div>

            <div v-if="selection==2" class="p-col-12 overflow-break">

              <div class="p-grid">
                <div class="p-md-6 p-col-12">

                  <h3>Linea</h3>
                  <span class="p-float-label p-mt-2">
                    <Dropdown class="p-col-12" id="line" v-model="selectedLine" @change="loadLineCategories()" :options="productLines" optionLabel="name" optionValue="id" placeholder="Select a Line" />
                  </span>
                  
                  <div v-if="selectedLine" class="p-field ">

                    <h3 class="p-mt-3" for="category">Categoria</h3>
                    <span class="p-float-label p-mt-2">
                      <Dropdown class="p-col-12" v-model="selectedCategory" @change="setCategory()" :options="lineCategories" optionLabel="name" optionValue="id" placeholder="Select Categories" />
                    </span>
                      
                  </div>
                  
                </div>

                <div class="p-md-6 p-col-12">
                  <div class="p-mx-5">

                    <h3>Il seguente prodotto verrà assegnato alla linea scelta ({{selectedLineName}}), e sarà registrato all'interno della categoria selezionata ({{selectedCategoryName}}).</h3>
                    
                    
                    <h4 class="p-mt-3" for="id">ID:</h4>
                    <span class="p-float-label p-mt-2">
                      {{product_id}}
                    </span>

                    <h4 class="p-mt-3" for="id">Tag ID:</h4>
                    <span class="p-float-label p-mt-2">
                      {{product_tag}}
                    </span>
                  </div>

                </div>
              </div>

            </div>

            <div v-if="selection==3" class="p-col-12 overflow-break">

              <div class="p-grid">
                <div class="p-col-12 p-md-6">
                  <h3>Nome Prodotto</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="name" id="name" type="text"/>
                  </span>

                  <h3 class="p-mt-3">Descrizione Prodotto</h3>
                  <span class="p-float-label p-mt-2">
                    <TextArea v-model="description" id="description" type="text"/>
                  </span>
                </div>

                <div class="p-md-6 p-col-12">
                  <div class="p-mx-5">
                    <Message severity="warn">In questo modo il prodotto non sarà collegato ad un tag NFC.</Message>
                  </div>

                </div>
              </div>
            </div>
            
          </div>

        </div>

        <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3 p-mt-3">

          <div class="p-d-flex p-ai-center"><h2>Assegna una timeline</h2> <InputSwitch class="p-ml-3" v-model="showTimelines" @change="loadTimelines()"/></div>
          
          <timelines-table-V2 class="p-col-12" v-if="showTimelines" :timelines="timelines" :showDetails="false" :assignable="true" @assignTimeline="assignTimeline"></timelines-table-V2>
          
        </div>
      
      </div>
    </div>

  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import RadioButton from 'primevue/radiobutton';
import InputSwitch from 'primevue/inputswitch';

import NavigationBar from '../components/NavigationBar';
import ProductService from '../../service/ProductService';
import AccountService from '../../service/AccountService'; 

import TimelinesTableV2 from '../components/TimelinesTableV2';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

/* import { useToast } from 'primevue/usetoast'; */


export default {
  name: "Create Product",
  components: {  NavigationBar, Breadcrumb, Toast, InputText, TextArea, Dropdown, Button, Message, RadioButton, InputSwitch, TimelinesTableV2 },

  setup(){

    onMounted(() => {
      loadTimelines();
      loadFreeProducts();
    })

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const route = useRoute();
    const router = useRouter();

    const account_id = route.params.data;

    const home = ref({
      icon: 'pi pi-home', 
      to: '/',
    });

    const items = ref([
      {
        label: 'Prodotti',
        to: '/products/'+account_id
      },
      {
        label: 'Crea prodotto'
      },
    ]);

    const selection = ref(1);

    const setOptions = () => {
      name.value = "";
      description.value = "";
      if(selection.value == 2){
        getLines();
      }else{
        selectedLine.value = "";
        selectedCategory.value = "";
        selectedLineName.value = "";
        selectedCategoryName.value = "";
      }
    }

    const name = ref("");
    const description = ref("");
    
    const selectedLine = ref("");
    const selectedLineName = ref("");
    const productLines = ref([]);

    const selectedCategory = ref("");
    const selectedCategoryName = ref("");
    const lineCategories = ref([]);

    const product_id = ref("");
    const product_tag = ref("");
    const showProduct = ref(false);

    const getLines = () => {
      accountService.value.getProductLines(account_id).then(data => {
        console.log("Data: ", data);
        productLines.value = data;
      })
    }

    const loadLineCategories = () => {
      accountService.value.getCategoriesByLineId(selectedLine.value, 10000000).then(data => {
        lineCategories.value = data;

        let line = productLines.value.find(element => element.id == selectedLine.value);
        selectedLineName.value = line.name;
      })
    }

    const setCategory = () => {
      let cat = lineCategories.value.find(element => element.id == selectedCategory.value);
      name.value = cat.name;
      description.value = cat.description;
    }

    const loadFreeProducts = () => {
      productService.value.getFreeProducts(account_id, 1).then(data => {
        product_id.value = data.docs[0].id;
        product_tag.value = data.docs[0].id_tag;
        showProduct.value = true;
      })
    };

    const timelines = ref([]);
    const timelineId = ref("");
    const showTimelines = ref(false);

    const loadTimelines = () => {
      accountService.value.getTimelines(account_id).then(data => {
        console.log("Timelines: ", data);
        timelines.value = data.docs;
      })
      
    }

    const assignTimeline = (id) => {
      timelineId.value = id;
    }

    const removeTimeline = () => {
      timelineId.value = "";
    }

    const tagToSearch = ref("");
    const insertTag = ref(false);

    const productLine = ref("");

    const loadProductInfo = () => {
      productService.value.getProductByTag(tagToSearch.value).then(data => {
        let prod_data = data.docs[0];
        if(prod_data.id_linea){
          productLine.value = prod_data.id_linea;
        }else{
          productLine.value = "";
          product_tag.value = prod_data.id_tag;
          product_id.value = prod_data.id;
        }
        
      })
    }

    const registerProduct = () => {
      let notag = selection.value == 3 ? true : false;
      productService.value.registerProduct(account_id, product_id.value, selectedLine.value, selectedCategory.value, timelineId.value, name.value, description.value, notag).then( () => {
        router.go();
      })
    }

    return { account_id, home, items, selection, name, description, selectedLine, lineCategories, productLines, selectedCategory, product_id, product_tag, showProduct, selectedLineName, selectedCategoryName, timelines, timelineId, showTimelines, tagToSearch, insertTag, productLine, loadTimelines, setOptions, loadLineCategories, getLines, loadFreeProducts, assignTimeline, removeTimeline, setCategory, loadProductInfo, registerProduct }
  }
};
</script>

<style scoped>

</style>
