<template>
    <div class="container p-mx-1 p-shadow-5" :style="{ height: panelHeight + 'px', backgroundColor: '#'+parameters.backgroundColor, borderRadius: parameters.extBorderRadius+'px !important'}">
           
        <div class="p-px-4 p-pb-2" :style="{ borderRadius: parameters.extBorderRadius+'px !important', color:  'green !important;'}">
            <div id="text-box" class="p-col-12 p-grid p-mt-3" :style="{ color: '#'+parameters.primaryTextBodyColor, padding: '0px !important' }">
                    
                <div class="p-grid p-col-12 p-d-flex">
                    <div class="p-col-4 p-grid p-mt-2" style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;">

                        <Image class="" :imageStyle="{ maxWidth: '100%', borderRadius: '30px' }" :src="imageUrl" alt="Image"/>

                    </div>

                    <div class="p-col-8 p-d-flex p-jc-end" style="padding-left: 0px; padding-bottom: 0px; color: #1a1a1a !important;">

                        <div class="p-text-right sans-bold">
                            <div class="p-mt-1 step-small-text p-jc-end p-d-flex p-ai-center">
                                <span class="location">
                                    {{timelineLocation}}
                                </span>
                                <i class="p-ml-2 pi pi-map-marker step-big-icon"></i>
                            </div>

                            <div class="p-mt-3 step-small-text p-jc-end p-d-flex p-ai-center">
                                <span class="location">
                                    {{timelineDate}}
                                </span>
                                <i class="p-ml-2 pi pi-calendar step-big-icon"></i>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="step-title p-col-12 p-mb-1" :style="{ color: '#'+parameters.primaryTextTitleColor }">
                    {{timelineName}}
                    <div v-if="!timelineName">
                        Lorem ipsum dolor sit
                    </div>
                </div>

                <div class="p-col-12 p-d-flex p-ai-center" style="color: #1a1a1a !important;">

                    <div class="step-description-text">
                        {{timelineDescription}}       
                    </div>

                </div>

                <div v-if="ecoClaim" class="p-col-12 p-grid p-d-flex p-jc-center p-ai-center p-mt-4">
                    <div class="white-btn pulse-white rounded-30 p-px-3 p-py-1 p-col-9 p-grid p-ai-center p-shadow-5 p-d-flex p-jc-center" @click="toggle" aria:haspopup="true" aria-controls="overlay_panel">
                        <div class="p-col-2">
                            <font-awesome-icon class="badge-icon fa-leaf" :icon="['fas', 'leaf']"/>
                        </div>
                        <div class="p-col-10 p-text-center">
                            <span>{{ecoClaim}}</span>
                        </div>
                    </div>
                </div>

                <OverlayPanel ref="op" appendTo="body" id="overlay_panel" style="width: 450px;" :breakpoints="{'960px': '75vw'}">
                    <div style="color: #575756; opacity: 0.7;">
                        <div class="p-mt-1 step-medium-text">
                            <i class="p-mr-2 pi pi-exclamation-triangle step-medium-text"></i>
                            <small>
                                Claim confirmed in:<br />
                            </small>
                        </div>

                        <div class="p-mt-1 p-pl-4 step-medium-text sans-medium">
                            <small>{{ecoClaimConfirmText}}</small>
                        </div>
                    </div>
                    <div class="p-mt-3">
                        <Image class="" :imageStyle="{ maxWidth: '100%', borderRadius: '30px' }" :src="ecoClaimConfirmImg" alt="Image"/>
                    </div>
                </OverlayPanel>

                <div class="p-col-12 p-mb-3 p-mt-3">

                    <span v-for="(input, index) in tags" :key='index' class="p-pr-2 p-text-left p-mb-2 sans-bold" :style="{ color: '#' + parameters.hastagColor }">
                        #{{input.tagName}}
                    </span>

                </div>

            </div>
        </div>
    </div>

    <div class="container p-grid p-mt-2" style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;">

        <div class="p-grid p-col-12 p-d-flex p-ai-center">
            <div class="p-col-4 p-grid p-mt-2" style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;">
                <a class="p-col-6" target="_blank" style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;" @click="toggle_file" aria:haspopup="true" aria-controls="file_overlay_panel">
                    <div class="p-py-2 p-shadow-1 p-text-center p-d-flex p-ai-center p-jc-center pulse-grey-20  rounded-10" :style="{ backgroundColor: '#' + parameters.buttonBackgroundColor, color: '#' + parameters.buttonIconColor, height: '55px'}">
                        <font-awesome-icon class="badge-icon fa-file-pdf" :icon="['fas', 'file-pdf']" />
                    </div>
                </a>
                <OverlayPanel class="p-shadow-5" ref="overlay_file" appendTo="body" id="file_overlay_panel" style="width: 450px;" :breakpoints="{'960px': '75vw'}">
                    <div class="p-mt-1 step-medium-text"  style="color: #575756;">
                        <div class="p-mb-3 sans-bold">See all the documents released:</div>
                        <div v-for="(input, index) in metaBlockchain" :key='index' class="p-pr-2 p-text-left p-mb-2" style="text-decoration: underline;">
                            <a class="no-underline" style="color: #575756;" :href="input.link" target="_blank"><i class="p-mr-2 pi pi-external-link step-medium-text"></i>{{input.documentName}}</a>
                        </div>
                    </div>
                </OverlayPanel>

                
                <a class="p-col-6" target="_blank" style="padding-left: 0px; padding-top: 0px; padding-bottom: 0px;" @click="toggle_txs" aria:haspopup="true" aria-controls="txs_overlay_panel">
                    <div class="p-py-2 p-shadow-1 p-text-center p-d-flex p-ai-center p-jc-center pulse-grey-20 rounded-10" :style="{ backgroundColor: '#' + parameters.buttonBackgroundColor, color: '#' + parameters.buttonIconColor, height: '55px' }">
                        <font-awesome-icon class="badge-icon fa-eye" :icon="['fas', 'eye']" />
                    </div>
                </a>
                <OverlayPanel class="p-shadow-5" ref="overlay_txs" appendTo="body" id="txs_overlay_panel" style="width: 450px;" :breakpoints="{'960px': '75vw'}">
                    <div class="p-mt-1 step-medium-text"  style="color: #575756;">
                        <div class="p-mb-3 sans-bold">Blockchain notarization:</div>
                        <div v-for="(input, index) in metaBlockchain" :key='index' class="p-pr-2 p-text-left p-mb-2" style="text-decoration: underline;">
                            <a class="no-underline" style="color: #575756;" :href="'https://explorer.iota.org/mainnet/message/' + input.tx" target="_blank"><i class="p-mr-2 pi pi-external-link step-medium-text"></i>{{input.documentName}}</a>
                        </div>
                        <div class="p-mt-4 p-mb-2 step-medium-text">
                            What does it mean?
                        </div>
                        <div class="step-xs-text">
                            To notarize a claim on blockchain means to sign it and make it permanent.
                            By doing so, the company and the other signatory partners take the legal responsibility 
                            of what is written hereby.
                           <!--  Notarizzare un’informazione su blockchain significa firmarla e renderla permanente. Così facendo, 
                            l'azienda e gli altri partner firmatari si assumono la responsabilità legale di quanto riportato nei 
                            documenti notarizzati. -->
                        </div>
                    </div>
                </OverlayPanel>

            </div>

            <div class="p-col-8 p-d-flex p-ai-center p-pr-2" style="padding-left: 0px; padding-bottom: 0px; color: #1a1a1a !important;">
                <div>
                    <div class="p-mt-1 step-medium-text roboto">
                        <i class="p-mr-2 pi pi-check-circle step-small-icon"></i>
                        <span style="text-decoration: underline;">
                            Information evidenced
                        </span>
                    </div>

                    <div class="p-mt-1 p-pl-4 step-small-text roboto">
                        <small>Check yourself all the documents released by Ohoskin</small>
                    </div>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

import Image from 'primevue/image';
import OverlayPanel from 'primevue/overlaypanel'
import { onMounted, onUpdated, watch } from '@vue/runtime-core';

import { ref } from 'vue';

export default {
    name: "TimelinePanel",
    components: { Image, OverlayPanel },
    props: {
        tags : Array,
        timelineName : String,
        timelineDescription : String,
        timelineLocation : String,
        timelineDate : String,
        ecoClaim: String,
        docName : String,
        imageUrl : String,
        parameters : Object,
        panelClass: String,
        ecoClaimConfirmText: String,
        ecoClaimConfirmImg: String,
        metaBlockchain: Object
    },
    setup(props){

        onMounted(() => {
            setExtBorderShadow(props.parameters.extBorderShadow);
            setInfoBorderShadow(props.parameters.infoBorderShadow);

            panelHeight.value = document.getElementById('text-box').clientHeight + 60; 
        })

        onUpdated(() => {
            panelHeight.value = document.getElementById('text-box').clientHeight + 60; 
        })

        const panelHeight = ref(303);

        const extBorderShadow_topleft = ref();
        const extBorderShadow_bottomright = ref();

        const extBorderShadow_right = ref();
        const extBorderShadow_left = ref();

        const infoBorderShadow_topleft = ref();
        const infoBorderShadow_bottomright = ref();

        const infoBorderShadow_right = ref();
        const infoBorderShadow_left = ref();

        const transactionLink = 'https://explorer.iota.org/mainnet/message/'+props.blockchainLink

        const op = ref();
        const overlay_file = ref();
        const overlay_txs = ref();

        const toggle = (event) => {
            op.value.toggle(event);
        };

        const toggle_file = (event) => {
            overlay_file.value.toggle(event);
        };

        const toggle_txs = (event) => {
            overlay_txs.value.toggle(event);
        };
        
        /* const changePanelHeight = () => {
            console.log("Altezza: ", panelHeight.value);
            const block = document.getElementById('text-box');
            block.style.height = ''+panelHeight.value+'px !important';
        } */

        watch(() => props.parameters.extBorderShadow, (v) => {
            setExtBorderShadow(v);
        });

        watch(() => props.parameters.infoBorderShadow, (v) => {
            setInfoBorderShadow(v);
        });

        const setExtBorderShadow = (elem) => {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(elem);
            if(result){
                let br_val_1 = parseInt(result[1], 16)-20 < 0 ? 0 : parseInt(result[1], 16)-20;
                let br_val_2 = parseInt(result[2], 16)-20 < 0 ? 0 : parseInt(result[2], 16)-20;
                let br_val_3 = parseInt(result[3], 16)-20 < 0 ? 0 : parseInt(result[3], 16)-20;
                extBorderShadow_bottomright.value = 'rgb('+br_val_1+','+br_val_2+','+br_val_3+')';

                let tl_val_1 = parseInt(result[1], 16)-10 < 0 ? 0 : parseInt(result[1], 16)-10;
                let tl_val_2 = parseInt(result[2], 16)-10 < 0 ? 0 : parseInt(result[2], 16)-10;
                let tl_val_3 = parseInt(result[3], 16)-10 < 0 ? 0 : parseInt(result[3], 16)-10;
                extBorderShadow_topleft.value = 'rgb('+tl_val_1+','+tl_val_2+','+tl_val_3+')';

                let r_val_2 = parseInt(result[2], 16)-1 < 0 ? 0 : parseInt(result[2], 16)-1;
                let r_val_3 = parseInt(result[3], 16)-1 < 0 ? 0 : parseInt(result[3], 16)-1;
                extBorderShadow_right.value = 'rgb('+parseInt(result[1], 16)+','+r_val_2+','+r_val_3+')';

                let l_val_1 = parseInt(result[1], 16)-5 < 0 ? 0 : parseInt(result[1], 16)-5;
                let l_val_2 = parseInt(result[2], 16)-5 < 0 ? 0 : parseInt(result[2], 16)-5;
                let l_val_3 = parseInt(result[3], 16)-5 < 0 ? 0 : parseInt(result[3], 16)-5;
                extBorderShadow_left.value = 'rgb('+l_val_1+','+l_val_2+','+l_val_3+')';
            }
            /* console.log(extBorderShadow_bottomright.value, extBorderShadow_topleft.value, extBorderShadow_right.value, extBorderShadow_left.value); */
        }

        const setInfoBorderShadow = (elem) => {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(elem);
            if(result){
                let br_val_1 = parseInt(result[1], 16)-20 < 0 ? 0 : parseInt(result[1], 16)-20;
                let br_val_2 = parseInt(result[2], 16)-20 < 0 ? 0 : parseInt(result[2], 16)-20;
                let br_val_3 = parseInt(result[3], 16)-20 < 0 ? 0 : parseInt(result[3], 16)-20;
                infoBorderShadow_bottomright.value = 'rgb('+br_val_1+','+br_val_2+','+br_val_3+')';

                let tl_val_1 = parseInt(result[1], 16)-10 < 0 ? 0 : parseInt(result[1], 16)-10;
                let tl_val_2 = parseInt(result[2], 16)-10 < 0 ? 0 : parseInt(result[2], 16)-10;
                let tl_val_3 = parseInt(result[3], 16)-10 < 0 ? 0 : parseInt(result[3], 16)-10;
                infoBorderShadow_topleft.value = 'rgb('+tl_val_1+','+tl_val_2+','+tl_val_3+')';

                let r_val_2 = parseInt(result[2], 16)-1 < 0 ? 0 : parseInt(result[2], 16)-1;
                let r_val_3 = parseInt(result[3], 16)-1 < 0 ? 0 : parseInt(result[3], 16)-1;
                infoBorderShadow_right.value = 'rgb('+parseInt(result[1], 16)+','+r_val_2+','+r_val_3+')';

                let l_val_1 = parseInt(result[1], 16)-5 < 0 ? 0 : parseInt(result[1], 16)-5;
                let l_val_2 = parseInt(result[2], 16)-5 < 0 ? 0 : parseInt(result[2], 16)-5;
                let l_val_3 = parseInt(result[3], 16)-5 < 0 ? 0 : parseInt(result[3], 16)-5;
                infoBorderShadow_left.value = 'rgb('+l_val_1+','+l_val_2+','+l_val_3+')';
            }
        }
        

        return { panelHeight,
            extBorderShadow_topleft, extBorderShadow_bottomright, extBorderShadow_right, extBorderShadow_left, 
            infoBorderShadow_topleft, infoBorderShadow_bottomright, infoBorderShadow_right, infoBorderShadow_left, transactionLink,
            toggle, op, toggle_file, overlay_file, toggle_txs, overlay_txs
        }
    }
    
}

</script>

<style scoped>

    .container {
        width: 85%;
    }

    .p-fieldset p {
        line-height: 1.5;
        margin: 0;
    }

    .step-small-text{
        font-size: 3.3vw;
    }

    .step-xs-text{
        font-size: 2.3vw;
    }

    .step-medium-text{
        font-size: 4vw;
    }

    .step-small-icon{
        font-size: 2.8vw;
    }

    .step-big-icon{
        font-size: 4.8vw;
    }

    .step-description-text{
        font-size: 3.7vw;
    }

    .step-title{
        font-size: 8.2vw !important;
        font-family: OpenSauceSansLightSemiBold;
    }

    ::v-deep(.info-blockchain){
        background-color: rgb(247, 246, 241);
    }

    ::v-deep(.p-panel .p-panel-header .p-panel-header-icon){
        border: 1px solid grey;
    }

    .p-grid {
    margin-right: 0rem;
    margin-left: 0rem;
    }

    ::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon){
    /* margin-right: auto !important; */
    font-size: 1.8rem;
    }

    ::v-deep(.p-accordion .p-accordion-header .p-accordion-header-link){
    padding: 1.3rem; 
    }

    .accordion-custom i, span {
    vertical-align: middle;
    }

    .accordion-custom span {
    margin: 0 .5rem;
    }


    ::v-deep(.p-accordion .p-accordion-tab){
    box-shadow: none;
    }

    ::v-deep(.p-accordion .p-accordion-content){
    padding: 0px !important;
    }
</style>

