<template>
  <main>
    <navigation-bar account_id="b31ae67e-359a-11ec-8d3d-0242ac130003"></navigation-bar>

    <div>
      <div id="mydiv" class="coloredDiv" @mouseenter="mouseOnBorder()" @mouseleave="mouseOnBorder()" :style="{ position: 'absolute', top: ypos+'px', left: xpos+'px', width: divWidth+'px', height: divHeight+'px', }" @click="moveDiv()">
        
      </div>
    </div>
  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';

import { ref } from 'vue';


export default {
  name: "sample",
  components: { NavigationBar },

  setup(){

    const xpos = ref(100);
    const ypos = ref(100);
    const divWidth = ref(250);
    const divHeight = ref(100);
    const movable = ref(false);

    const moveDiv = () => {
      console.log("Cliccato");
      movable.value = !movable.value;
      if(movable.value){
        document.addEventListener("mousemove", changeDivPosition);
      }else{
        document.removeEventListener("mousemove", changeDivPosition, false);
        document.removeEventListener("mousemove", changeDivPosition, true);
      }
    }

    const mouseOnBorder = () => {
      console.log("Sono sul bordo");
      document.getElementById('mydiv').style.cursor = 'pointer';
    }

    const changeDivPosition = (event) => {
      let mousex = event.clientX; // Gets Mouse X
      let mousey = event.clientY; // Gets Mouse Y
      xpos.value = mousex-divWidth.value/2;
      ypos.value = mousey-divHeight.value/2;
      /* console.log("Posizione: ", [xpos.value, ypos.value]); // Prints data */
    }

    return { xpos, ypos, moveDiv, divWidth, divHeight, mouseOnBorder }
  }
};
</script>

<style scoped>
  .coloredDiv{
    background-color: orange;
  }
</style>

