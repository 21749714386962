<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';

import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';


export default {
  name: "LoadProductPage",
  components: {  NavigationBar },
  setup(){

    onMounted(() => {
    })

    const route = useRoute();

    const route_params = route.params.data;

    const router = useRouter();

    const account_id = route_params.split('&')[0];
    const product_id = route_params.split('&')[1];
    const client_name = route_params.split('&')[2];

    localStorage.account = account_id;
    localStorage.product = product_id;
    localStorage.name = client_name;
    
    router.push('/'+client_name+'PP');

    return { account_id }
  }
};

</script>

<style scoped>

</style>

