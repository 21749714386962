<template>
  <main>

    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>

    <div>

      <div class="p-ml-5 p-mr-1 p-grid p-jc-between">
      

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Modifica" icon="pi pi-pencil" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="$router.push('/configureTimelineV2/'+account_id+'&'+timeline_id)"/>
        </div>

        <div class="p-grid p-col-12 p-ai-stretch p-jc-between" style="padding-left: 1rem;">

          <div class="p-grid p-col-12 p-md-8 p-jc-between">

            <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
              <div class="p-col-12">

                <div>
                  <h3 class="p-mt-5">ID</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="timeline_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                  </span>
                </div>

                <div>
                  <h3 class="p-mt-5">Nome</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="timelineName" id="ID" type="text" placeholder="Nome" :disabled="true"/>
                  </span>
                </div>

              </div>
            </div>
          
          </div>

          <div class="p-col-12 p-md-4 p-p-3">

            <timeline-panel-V2
              docName="Lorem ipsum" timelineLocation="Inserire location" timelineName="Lorem ipsum" timelineDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
              :tags="tags" :panelClass="'p-col-12 p-grid p-jc-center'" imageUrl="https://imageshack.com/i/ponJ7wHNj" :parameters="parameters" documentUrl="" blockchainLink=""
            >
            </timeline-panel-V2>

          </div>

        </div>

        <div class="p-grid p-col-12 p-jc-start p-ai-stretch p-pr-3" style="padding-left: 0px !important; margin-left: 0px !important;">
          
          <div v-if="timeline_products.length" class="p-col-12 overflow-break" style="padding-left: 0px !important; margin-left: 0px !important;">
            <Card>
              <template #content>
                <products-table class="p-mt-3" title="Lista prodotti registrati" :products="timeline_products" :showRowDetail="true" :lineSelectable="false" 
                :showRemoveSwitch="true" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
                :showCat="true" :showLine="true" :showTimeline="false" :showPage="false" :showDocs="false" :showSelectedColumns="false" @removeProduct="removeFromTimeline"
                ></products-table>
              </template>
            </Card>
          </div>
          
        </div>
        
      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Card from 'primevue/card';

import NavigationBar from '../components/NavigationBar';
import TimelinePanelV2 from '../components/TimelinePanelV2';
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, TimelinePanelV2, Button, ProductsTable, InputText, Card },
  setup(){

    onMounted(() => {
      loadTimelineInfo();
    })

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const timeline_id = route_params.split('&')[1];

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const timelineName = ref();

    const configureTimeline = ref(false);

    const timeline_products = ref([]);

    const tags = ref([ { tagName : "Lorem" }, { tagName : "Lorem" } ])

    const ids = ref([]);

    const loadTimelineInfo = () => {
      accountService.value.getTimelineById(timeline_id).then(data => {
        if(data.docs[0]){
          timelineName.value = data.docs[0].timeline_name;
          parameters.value = data.docs[0].parameters;
        }
      })

      loadTimelineProducts();
      
    }

    const loadTimelineProducts = () => {
      productService.value.getProductsByTimelineId(timeline_id).then(data => {
        console.log("Prodotti: ", data.docs);
        timeline_products.value = data.docs;
      })
    }

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Timelines',
        to: '/timelinesV2/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);
    
    const parameters = ref({});

    const setRemovable = ref(false);

    const removeFromTimeline = (id) => {
      productService.value.removeTimeline(id).then( () => {
        loadTimelineProducts();
        setRemovable.value = true;
      })
    }

    return { 
      account_id, tags, timelineName, timeline_id, timeline_products, home, items, configureTimeline, ids, parameters, removeFromTimeline, setRemovable
    }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }

  ::v-deep(.timeline-badge) {
    display: none !important;
  }

  ::v-deep(.step-small-text){
    font-size: 0.9vw !important;
  }

  ::v-deep(.step-small-icon){
    font-size: 0.7vw !important;
  }

  ::v-deep(.step-description-text){
    font-size: 1.1vw !important;
  }

</style>

