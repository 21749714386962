import * as VueRouter from "vue-router"
/* import MainPage from "@/views/MainPage"; */
import SupplierDatabase from "@/views/SupplierDatabase";
import UploadDoc from "@/views/UploadDoc";
import UploadExternalDoc from "@/views/UploadExternalDoc";
import DocumentDetail from "@/views/DocumentDetail";
import LineDetail from "@/views/LineDetail";
import ProductLines from "@/views/ProductLines";
import Products from "@/views/Products";
import ProductCategories from "@/views/ProductCategories";
import CategoryDetail from "@/views/CategoryDetail";
import CreateProductLine from "@/views/CreateProductLine";
import CreateProductCategory from "@/views/CreateProductCategory";
import CreateProduct from "@/views/CreateProduct";
/* import ConfigureTimeline from "@/views/ConfigureTimeline"; */
import ConfigureTimelineV2 from "@/views/ConfigureTimelineV2";
import ProductDetail from "@/views/ProductDetail";
/* import Timelines from "@/views/Timelines"; */
import TimelinesV2 from "@/views/TimelinesV2";
/* import TimelineDetail from "@/views/TimelineDetail"; */
import TimelineDetailV2 from "@/views/TimelineDetailV2";
/* import ProductPage from "@/views/ProductPage"; */
import ProductPageV2 from "@/views/ProductPageV2";
import ProductPassport from "@/views/ProductPassport";
/* import ConfigureProductPage from "@/views/ConfigureProductPage"; */
import ConfigureProductPageV2 from "@/views/ConfigureProductPageV2";
import Test from "@/views/Test";
/* import Pages from "@/views/Pages"; */
import PagesV2 from "@/views/PagesV2";
/* import ProductPageDetail from "@/views/ProductPageDetail"; */
import ProductPageDetailV2 from "@/views/ProductPageDetailV2";
import LoadProductPage from "@/views/LoadProductPage";
import LoadProductPassport from "@/views/LoadProductPassport";
import Settings from "@/views/Settings";
import CreateSupplier from "@/views/CreateSupplier";
import Suppliers from "@/views/Suppliers";
import SupplierDetail from "@/views/SupplierDetail";

import MintNFT from "@/views/nft_machine/views/MintNFT";
import CollectionList from "@/views/nft_machine/views/CollectionList";

/* import RegardView from "@/views/clients/RegardView";
import OhoskinView from "@/views/clients/OhoskinView"; */

const routes = [
    {
        path: "/mintNFT",
        name: "mintNFT",
        component: MintNFT,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/",
        name: "home",
        component: MainPage,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/",
        name: "home",
        component: SupplierDatabase,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/collectionList",
        name: "collectionList",
        component: CollectionList,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/documents/uploadDoc/:data",
        name: "uploadDoc",
        component: UploadDoc,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/documents/uploadExternalDoc/:data",
        name: "uploadExternalDoc",
        component: UploadExternalDoc,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/regard/:data",
        name: "regardView",
        component: RegardView,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    /* {
        path: "/ohoskin/:data",
        name: "ohoskinView",
        component: OhoskinView,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    /* {
        path: "/productPage",
        name: "productPage",
        component: ProductPage,
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/productPageV2",
        name: "productPageV2",
        component: ProductPageV2,
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/ohoskin",
        name: "ohoskin",
        component: ProductPage,
        alias: "/ProductPage",
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/ohoskin",
        name: "ohoskin",
        component: ProductPageV2,
        alias: "/ProductPageV2",
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/ohoskinPP",
        name: "ohoskinPP",
        component: ProductPassport,
        alias: "/ProductPassport",
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/regard",
        name: "regard",
        component: ProductPageV2,
        alias: "/ProductPageV2",
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/configureProductPage/:data",
        name: "configureProductPage",
        component: ConfigureProductPage,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/configureProductPageV2/:data",
        name: "configureProductPageV2",
        component: ConfigureProductPageV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/productDetail/:data",
        name: "productDetail",
        component: ProductDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/productLines/:data",
        name: "productLines",
        component: ProductLines,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/products/:data",
        name: "products",
        component: Products,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/configureTimeline/:data",
        name: "configureTimeline",
        component: ConfigureTimeline,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/configureTimelineV2/:data",
        name: "configureTimelineV2",
        component: ConfigureTimelineV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/timelineDetail/:data",
        name: "timelineDetail",
        component: TimelineDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/timelineDetailV2/:data",
        name: "timelineDetailV2",
        component: TimelineDetailV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/productLines/createProductLine/:data",
        name: "createProductLine",
        component: CreateProductLine,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/productCategories/createProductCategory/:data",
        name: "createProductCategory",
        component: CreateProductCategory,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/suppliers/createSupplier/:data",
        name: "createSupplier",
        component: CreateSupplier,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/suppliers/supplierDetail/:data",
        name: "supplierDetail",
        component: SupplierDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/suppliers/:data",
        name: "suppliers",
        component: Suppliers,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/createProduct/:data",
        name: "createProduct",
        component: CreateProduct,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/productCategories/:data",
        name: "productCategories",
        props: true,
        component: ProductCategories,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/documentDetail/:data",
        name: "documentDetail",
        component: DocumentDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/lineDetail/:data",
        name: "lineDetail",
        component: LineDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/categoryDetail/:data",
        name: "categoryDetail",
        component: CategoryDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/timelines/:data",
        name: "timelines",
        component: Timelines,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/timelinesV2/:data",
        name: "timelinesV2",
        component: TimelinesV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/pages/:data",
        name: "pages",
        component: Pages,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/pagesV2/:data",
        name: "pagesV2",
        component: PagesV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    /* {
        path: "/pages/productPageDetail/:data",
        name: "productPageDetail",
        component: ProductPageDetail,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    }, */
    {
        path: "/pages/productPageDetailV2/:data",
        name: "productPageDetailV2",
        component: ProductPageDetailV2,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/test/",
        name: "test",
        component: Test,
        props: false,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/loadProductPage/:data",
        name: "loadProductPage",
        component: LoadProductPage,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/loadProductPassport/:data",
        name: "loadProduct",
        component: LoadProductPassport,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
    {
        path: "/settings/:data",
        name: "settings",
        component: Settings,
        props: true,
        meta: {
            title: "PrimeVue Example",
        },
    },
];

const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
})

export default router;
