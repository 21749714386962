<template>
  <main style="overflow-x: hidden" title="Ohoskin">
    <div class="p-d-md-none p-d-xs-flex">
      <div v-if="loadPage" class="">
        
        <!-- COPERTINA -->
        <div class="p-d-lg-none p-d-flex p-jc-center p-ai-center p0" :style="{ background: 'url(\''+pageParameters.backgroundImgMobile+'\')', height: '100vh', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', position: 'relative'}">
          <div class="p-text-center overlay-nav p-col-12 px0 py0" style="position: fixed !important; z-index: 100;">
            <!-- <header-bar class="navigator" v-if="showHeaderBar" @openMenu="openMenu()"></header-bar> -->
            <div class="navigator p-py-3" v-if="showHeaderBar" style="color:white;">
              <a class="no-underline" style="color: white;" href="https://www.blockvision.it" target="_blank"><div style="font-size: 2.2vh"><i class="pi pi-external-link p-mr-2"></i>Product traced with blockchain technology</div></a>
            </div>
          </div>

          <div class="p-mx-5 p-mt-5 p-p-1 rounded-35 text-grey p-shadow-5 presentation-box">
            <div class="p-text-center text-black">
              <div class="p-mb-4 p-mt-4">
                <Image class="" :imageStyle="{ maxWidth: '35%' }" :src="pageParameters.logoImg" alt="Image"/>
              </div>
              <div class="p-mx-4" style="font-weight: unset !important; font-size: 2.7vh;">{{pageParameters.primaryUpperText}}</div>
              <div class="p-mx-4 p-mt-3 p-mb-3 sans-bold" style="font-size: 2.7vh;">{{pageParameters.secondaryUpperText}} <br/></div>
            </div>
            <div class="p-text-center p-mx-3">
                <a href="#timeline" class="cursor-pointer no-underline" v-smooth-scroll>
                  <Button class="rounded-30 pulse-grey p-mt-3 p-mb-5 p-py-2 p-px-4 p-button-grey" style="font-size: 3.2vh;">
                    <span class="">{{pageParameters.buttonText}}</span>
                  </Button>      
                </a>
            </div>
          </div>

        </div>

        <!-- MENU A COMPARSA IN OVERLAY -->
        <div id="menu-overlay" class="menu-overlay">
          <a class="menu-link closebtn" href="javascript:void(0)" @click="closeMenu()">&times;</a>
          <div class="overlay-content">
            <a class="menu-link" href="https://www.blockvision.it" target="_blank">Scopri Blockvision</a>
            <a class="menu-link" href="https://www.ohoskin.com/" target="_blank">Scopri Ohoskin</a>
            <a class="menu-link" href="#timeline" @click="closeMenu()">Vai alla filiera</a>
            <a class="menu-link" href="#contact-us" @click="closeMenu()">Contattaci</a>
          </div>
        </div>

        <!-- GIF FILIERA -->
        <div v-if="pageParameters.gifSrc" style="margin-top: 3.5rem;">
          <Image class="p-mt-5" :imageStyle="{ width: '100%' }" :src="pageParameters.gifSrc" alt="Image"/>
        </div>

        <!-- MOTTO OHOSKIN -->
        <div class="p-grid p-mx-5 p-text-center p-mt-3 p-ai-center p-jc-center">
          <span class="p-col-12" style="font-size: 3.7vh; padding-bottom: 0px !important; font-weight:bold;">{{pageParameters.lowerBlockText_title}}</span>
        </div>

        <!-- TESTO SOTTO -->
        <div class="p-grid p-mx-5 p-text-center p-mt-3 p-ai-center p-jc-center">
          <span class="p-col-12" style="padding-bottom: 0px !important;">{{pageParameters.lowerBlockText_content}}</span>
        </div>

        <!-- Dialog 1 Mobile-->
        <Dialog class="px0 py0" :contentStyle="{padding: '0px !important'}" v-model:visible="displayModalVideo" :style="{width: '100vw'}" :modal="true" :maximizable="true">
          <div class="player-container">
            <vue3-video-player :src="pageParameters.videoSrc"></vue3-video-player>
          </div>
        </Dialog>

      </div>

      <div v-if="loadTimeline" id="timeline" class="p-mb-5" style="margin-top: 4.1rem;">

        <div class="p-grid p-mt-5 p-mb-3">

          <!-- <Galleria :value="pageParameters.images" :numVisible="5" :showThumbnails="false" :circular="true" :showItemNavigators="true">
            <template #item="{item}">
              <img :src="item.itemImageSrc" :alt="item.alt" style="width: 100%; display: block;" />
            </template>
            <template #caption="{item}">
              <h4 style="margin-bottom: .5rem;">{{item.title}}</h4>
              <p>{{item.description}}</p>
            </template>
          </Galleria> -->

          <slideshow v-if="showSlideshow" :slideshowContent="pageParameters.images"></slideshow>

        </div>

        <div>

          <div class="p-d-flex p-jc-center pt0 p0">
            
              <div class="p-grid p-col-12 p-jc-center p-mt-2 p-ai-start py0 px0">
                
                <div v-if="(selectedIndex>0)" class="p-col-2 p-mb-1">
                  <Button class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-left" style="margin-top: 0.76rem;"  @click="shiftDocumentsRight(1)"/>
                </div>
                <div v-else class="p-col-2 p-mb-1">
                  <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="" style="margin-top: 0.76rem; color: white !important;"/>
                </div>

                <div class="p-col-8 pb0 p-d-flex p-jc-center">
                  <div class="py0 p-d-flex p-jc-center p-text-center" v-for="(input, index) in visibleDocuments" :key='index'>
                    <div class="p-jc-center p-ai-center">
                      <Avatar v-if="index==selectedIndex" class="cursor-pointer p-mx-1 timeline-badge-selected timeline-badge" shape="circle" @click="avatarClick(index)">
                        <Image class="" :imageStyle="{ maxWidth: '60%' }" :src="input.icon_link" alt="Image"/>
                      </Avatar>
                      <Avatar v-else class="cursor-pointer p-mx-1 timeline-badge p-mt-2" shape="circle" style="margin-top: 0.2rem;" @click="avatarClick(index)">
                        <Image class="" :imageStyle="{ maxWidth: '60%' }" :src="input.icon_link" alt="Image"/>
                      </Avatar>
                      <div v-if="index==selectedIndex" style="font-weight: bolder !important; color: #B8BE9C;">
                        | <br />
                        | <br />
                      </div>
                    </div>
                  </div>
                </div>


                <div v-if="(docIdx<=documents.length-2)" class="p-col-2 p-mb-1">
                  <Button v-if="(docIdx<=documents.length-2)" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.76rem;"  @click="shiftDocumentsLeft(1)"/>
                </div>
                <div v-else class="p-col-2 p-mb-1">
                  <Button :disabled="true" class="p-button-rounded p-button-text p-button-plain p-button-lg" icon="pi pi-angle-right" style="margin-top: 0.76rem; color: white !important;"/>
                </div>

              </div>

          </div>

        </div>

        <div @touchstart="handleTouchStart" @touchmove="handleTouchMove" v-if="selectedIndex!=-1" class="p-grid p-jc-center p-mt-2 p-mb-5">
          <timeline-panel-V2
            :docName="selectedStep.docName" :timelineLocation="selectedStep.timelineInfo.timelineLocation" 
            :ecoClaim="selectedStep.timelineInfo.ecoClaim" :ecoClaimConfirmText="selectedStep.timelineInfo.ecoClaimConfirmText" 
            :ecoClaimConfirmImg="selectedStep.timelineInfo.ecoClaimConfirmImg" :timelineDate="selectedStep.timelineInfo.timelineDate"
            :timelineName="selectedStep.timelineInfo.timelineName" :timelineDescription="selectedStep.timelineInfo.timelineDescription"
            :tags="selectedStep.docTags" :panelClass="'p-col-10 p-grid p-jc-center box'" 
            :imageUrl="selectedStep.timelineInfo.timelineImage" :parameters="parameters" :metaBlockchain="selectedStep.metaBlockchain"
          >
          </timeline-panel-V2>

        </div>

        <!-- LINK -->
        <div class="p-d-flex p-jc-center p-grid p-text-center" style="margin-top: 3.5rem;">

          <a href="https://www.ohoskin.com/shop/" target="_blank" class="p-col-10 p-shadow-3 pulse-grey cursor-pointer no-underline rounded-30 p-mt-3 p-mb-4 p-py-2 p-px-4 p-button-grey">
            <span class="sans-bold" style="font-size: 1.7em;">Visit our marketplace</span>
          </a> 

          <a href="https://www.ohoskin.com/" target="_blank" class="p-col-10 p-shadow-3 pulse-grey cursor-pointer no-underline rounded-30 p-mt-3 p-mb-4 p-py-2 p-px-4 p-button-grey">
            <span class="sans-bold" style="font-size: 1.7em;">Visit our website</span>
          </a> 

        </div>

        <!-- MODULO CONTATTO -->
        <h1 class="p-mx-5 p-mt-5">Would you like to know more? <br/> Contact us!</h1>

        <!-- <div v-if="loadPage" id="contact-us" class="p-grid p-jc-center" @click="openModalFeedback()"> -->
        <div v-if="loadPage" id="contact-us" class="p-grid p-jc-center p-mb-5">
          <div class="p-col-10 p-grid p-shadow-5 p-mt-5 rounded-20 p-ai-center p-jc-center">
            <div class="p-col-4 p-text-center">
              <Image class="" :imageStyle="{ maxWidth: '100%' }" :src="pageParameters.logoImg" alt="Image"/>
            </div>
            <div class="p-col-4 p-text-center">
              <font-awesome-icon style="font-size: 16vw; color: #575756;" class="badge-icon fa-comment-dots" :icon="['fas', 'comment-dots']"/>
            </div>
          </div>
        </div>
        
      </div>

      <Dialog class="p-p-0" :show-header="false" v-model:visible="displayModalFeedback" style="width: 80vw; border-radius: 20px !important;" contentStyle="border-radius: 20px !important;" :modal="true" :maximizable="false">
        <div class="p-mt-4">
          <i class="pi pi-times" @click="displayModalFeedback=false"></i>
        </div>
        <div v-if="!messageSent" class="p-mt-5 p-mb-5">
          <div class="p-grid p-jc-end">
            <div class="p-col-9 message rounded-10 p-py-3 p-px-3">
              Cerchi altre informazioni?
            </div>
          </div>
          <div>
            <Avatar class="cursor-pointer user-badge" shape="circle">
              <Image class="" :imageStyle="{ maxWidth: '80%' }" :src="pageParameters.messageLogo" alt="Image"/>
            </Avatar>
          </div>

          <div class="p-mt-5 p-mb-2">
            <small>Siamo pronti a risponderti!</small>
          </div>

          <div class="p-grid p-jc-start py0 px0 p-ai-end message-2">

            <div class="p-col-10 p-grid" style="margin-left: 0px !important;">
              <div class="p-mt-2 p-col-5 p-d-flex pl0 py0 p-mb-1">
                <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Città" style="background-color: lightgrey; border: none;"/>
              </div>
              <div class="p-mt-2 p-col-7 p-d-flex pr0 py0 p-mb-1 pl0">
                <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="Mario Rossi" style="background-color: lightgrey; border: none;"/>
              </div>
              <div class="p-col-12 p-d-flex px0 py0">
                <InputText class="rounded-5 p-col-12" v-model="name" id="name" type="text" placeholder="E-mail" style="background-color: lightgrey; border: none;"/>
              </div>
              <div class="p-col-12 p-mt-1 px0 py0">
                <TextArea class="rounded-10 p-col-12" v-model="message" id="message" type="text" rows="5" placeholder="Messaggio..." style="background-color: lightgrey; border: none;"/>        
              </div>
            </div>

            <div class="p-col-2 p-mb-1">
              <Button class="p-mb-1 p-ml-1" icon="pi pi-send" style="font-size: 1.5em; background-color: #A7B182; color: white;"  @click="sendMessage()"/>
            </div>

          </div>
        </div>

      </Dialog>

      <!-- <div class="p-grid p-jc-center p-mt-5" style="margin-bottom: 4rem !important;">

        <div v-if="!showInfo" class="p-col-10 p-grid p-jc-center p-mt-5">
          <div class="p-grid p-col-8 p-ai-center pb0" >
            <i class="p-col-2 pi pi-check-circle px0"></i>
            <Image class="p-col-10 px0" :imageStyle="{ maxWidth: '100%' }" src="https://imagizer.imageshack.com/img924/2817/CBjoQz.png" alt="Image"/>
          </div>
          <div class="p-col-10 p-ai-center p-text-center pt0">
            Filiera tracciata su Blockchain
          </div>
          
        </div>

      </div> -->

      <footer-bar class="p-px-5" style="margin-top: 6.5rem;" :updateDate="pageParameters.updateDate"></footer-bar>
    </div>
  </main>
</template>

<script>

import Image from 'primevue/image';
import Dialog from 'primevue/dialog';
import Avatar from 'primevue/avatar';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Button from 'primevue/button';
/* import Galleria from 'primevue/galleria'; */
/* import Carousel from 'primevue/carousel'; */

import TimelinePanelV2 from '../components/TimelinePanelV2';
import Slideshow from '../components/Slideshow';
/* import HeaderBar from '../components/HeaderBar'; */
import FooterBar from '../components/FooterBar';

import AccountService from '../../service/AccountService';
import TransactionService from '../../service/TransactionService';
import ProductService from '../../service/ProductService';

import { ref, onMounted } from 'vue';
import { /* useRoute,  useRouter */ } from 'vue-router';


export default {
  name: "ProductPage",
  components: { /* HeaderBar, */ FooterBar, /* Carousel, */ Image, Dialog, Avatar, InputText, /* Galleria, */ TextArea, Button, TimelinePanelV2, Slideshow/* , ScrollPanel */ },
  setup(){

    onMounted(() => {
      console.log("account_id: ", account_id);
      console.log("product_id: ", product_id);
      loadProdInfo();
    })

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());
    const transactionService = ref(new TransactionService());

    /* const router = useRouter(); */

    console.log("Account: ", localStorage.account);
    console.log("Product: ", localStorage.product);

    const account_id = localStorage.account;
    const product_id = localStorage.product;

    const scrollY = ref(0);
    const oldScroll = ref(0);

    const documents = ref([]);
    const visibleDocuments = ref([]);

    const loadPage = ref(false);
    const loadTimeline = ref(false);

    const parameters = ref({});
    const pageParameters = ref({});
    const displayBasic = ref(false);

    const tags = ref([ { tagName : "Lorem" }, { tagName : "Lorem" } ]);

    const displayModalVideo = ref(false);
    const showInfo = ref(false);

    const displayModalFeedback = ref(false);

    const pageName = ref("");

    const name = ref("");
    const surname = ref("");
    const mail = ref("");
    const message = ref("");

    const messageSent = ref(false);

    const selectedIndex = ref(0);
    const docIdx = ref(0);

    const selectedStep = ref();

    const showHeaderBar = ref(true);

    const showSlideshow = ref(false);

    const shiftDocumentsRight = (value) => {
      let idx = docIdx.value - value;

      if(idx > -1){
        if(selectedIndex.value != docIdx.value){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
    }

    const shiftDocumentsLeft = (value) => {
      let idx = docIdx.value + value;

      if(idx < documents.value.length){
        if(idx > 3){

          loadTimeline.value = false;

          let t = documents.value;

          visibleDocuments.value = t.slice(idx-3, idx+1);

          selectStep(idx);

          loadTimeline.value = true;
        }else{
          selectStep(idx);
        }
      }
      
    }

    const loadProdInfo = () => {
      productService.value.getProductByID(product_id).then(data => {
        console.log("DATA: ", data.docs[0]);
        
        let prod_data = data.docs[0];

        transactionService.value.getDocumentsByIds(prod_data.lista_docs).then( (data) => {
          documents.value = data;

          visibleDocuments.value = data.slice(0,4);

          console.log("Visible: ", visibleDocuments.value);

          loadTimelineInfo(prod_data.timeline_id);
          loadPageInfo(prod_data.page_id);

          selectedStep.value = visibleDocuments.value[0];
        })

      })
    }

    const loadTimelineInfo = (id) => {
      accountService.value.getTimelineById(id).then(data => {
        //console.log("Timeline data: ", data.docs[0].parameters);
        parameters.value = data.docs[0].parameters;
        loadTimeline.value = true;
      })
    }

    const loadPageInfo = (id) => {
      accountService.value.getProductPageById(id).then(data => {
        pageParameters.value = data.docs[0].parameters;
        document.title = pageParameters.value.pageTitle;
        loadPage.value = true;

        showSlideshow.value = true;
      })
      
    }

    const openBasic = () => {
      displayBasic.value = true;
    };

    const closeBasic = () => {
      displayBasic.value = false;
    };

    const openModalVideo = () => {
      displayModalVideo.value = true;
    };

    const openModalFeedback = () => {
      displayModalFeedback.value = true;
    }

    const sendMessage = () => {
      messageSent.value = true;
      accountService.value.sendMessage(account_id, product_id, name.value, surname.value, mail.value, message.value);
    }

    const selectStep = (index) => {

      docIdx.value = index;  

      if(index<4){
        selectedStep.value = (documents.value)[docIdx.value];
        selectedIndex.value = index;  
        
      }else{
        selectedStep.value = (documents.value)[docIdx.value];
      }

    }

    const openMenu = () => {
      showHeaderBar.value = false;
      document.getElementById("menu-overlay").style.width = "100%";
    }

    const closeMenu = () => {
      showHeaderBar.value = true;
      document.getElementById("menu-overlay").style.width = "0%";
    }

    window.onscroll = () => {
      scrollY.value = window.pageYOffset;
      oldScroll.value > scrollY.value ? showHeaderBar.value = true : showHeaderBar.value = false;
      oldScroll.value = scrollY.value;
    }

    const xDown = ref(null);                                                        
    const yDown = ref(null);

    const getTouches = (evt) => {
        return evt.touches ||             // browser API
            evt.originalEvent.touches; // jQuery
    }                                                     
                                                                            
    const handleTouchStart = (evt) => {
        const firstTouch = getTouches(evt)[0];                                      
        xDown.value = firstTouch.clientX;                                      
        yDown.value = firstTouch.clientY;                                      
    };                                               
                                                                            
    const handleTouchMove = (evt) => {
        if ( ! xDown.value || ! yDown.value ) {
            return;
        }

        var xUp = evt.touches[0].clientX;                                    
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown.value - xUp;
        var yDiff = yDown.value - yUp;
                                                                            
        if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
            if ( xDiff > 0 ) {
                shiftDocumentsLeft(1);
              } else {
                shiftDocumentsRight(1);
            }                       
        } 
        /* reset values */
        xDown.value = null;
        yDown.value = null;                                             
    }

    const avatarClick = function(index){
      var diff = index-selectedIndex.value;
      if(diff > 0){
        shiftDocumentsLeft(diff);
      }else if (diff < 0){
        shiftDocumentsRight(Math.abs(diff));
      }
      /* console.log("Click index: ", index); */
      /* console.log("Selected index: ", selectedIndex.value); */
    }
   
    return { 
      account_id, pageParameters, documents, pageName, showInfo, showHeaderBar, parameters, 
      displayModalVideo, displayModalFeedback, tags, displayBasic, messageSent, selectedIndex, 
      loadPage, loadTimeline, name, surname, message, mail, selectedStep, visibleDocuments, docIdx,
      openMenu, closeMenu, shiftDocumentsRight, shiftDocumentsLeft, selectStep, openModalVideo, 
      openModalFeedback, openBasic, closeBasic, sendMessage, handleTouchMove, handleTouchStart, avatarClick, showSlideshow
    }
  }
};
</script>

<style scoped>

  .user-badge{
    width: 4rem !important;
    height: 4rem !important;
  }

  .btn-grey{
    background-color: rgb(172, 172, 172) !important;
    color: white !important;
  }

  .overlay {
    height: auto;
    text-align: center;
    width: 85%;
    position: absolute;
    left: 7.5%;
    z-index: 9;
    background-color: white;
  }

  .overlay-nav {
    top: 0;
    left: 0;
  }

  .navigator{
    background-color: rgba(49, 49, 49, 0.55) !important;
    margin: 0px;
  }

  .timeline-badge-selected{
    background-color: #B8BE9C !important;
    color: white;
    height: 4.3rem !important;
    width: 4.3rem !important;
  }

  .timeline-badge{
    background-color: #575756;
    color: white;
    width: 3.4rem;
    height: 3.4rem;
  }

  .message{
    background-color: lightgrey;
    font-size: 3.9vw;
    position: relative;
  }

  .message:after {
    content:'';
    position: absolute;
    top: 100%;
    left: 30%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-right: solid 23px transparent;
  }

  .message-2{
    position: relative;
  }

  .message-2:after {
    content:'';
    position: absolute;
    top: 94%;
    left: 80%;
    margin-left: -50px;
    width: 0;
    height: 0;
    border-top: solid 15px lightgrey;
    border-left: solid 23px transparent;
  }


  ::v-deep(.p-avatar.p-avatar-xl){
    width: 8rem !important;
    height: 8rem !important;
  }

  ::v-deep(.p-avatar.p-avatar-lg){
    width: 6rem !important;
    height: 6rem !important;
  }
  
  ::v-deep(.p-dialog .p-dialog-content){
    padding: 0px !important;
  }
  
  .blink_me {
    animation: blinker 2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }

  ::v-deep(.pi .pi-angle-right .p-button-icon){
    font-size: 35px !important;
  }

  .pi .pi-angle-right .p-button-icon{
    font-size: 35px !important;
  }

  .menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .menu-link {
    padding: 8px;
    text-decoration: none;
    font-size: 8vw;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .menu-link:hover {
    color: #f1f1f1 !important;
  }

  .menu-link:focus {
    color: #f1f1f1 !important;
  }

  .menu-overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 15vw;
  }

</style>

