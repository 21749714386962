<template>
    <div class="header-bar p-d-flex p-jc-between p-ai-center">
        <a style="cursor: pointer;" href="https://www.blockvision.it" target="_blank"><img alt="logo" src="@/assets/img/logo.png" height="60" class="p-mr-2"></a>
        <i class="p-mr-3 pi pi-bars" style="font-weight: bolder; color: white;" @click="$emit('openMenu')"></i>
    </div>
</template>

<script>

/* import { ref } from 'vue'; */

export default {
    name: "Header",
    components: {  },
    emits: ["openMenu"],
    setup(){

        return{ }
    }
}

</script>

<style scoped>
    .header-bar{
        background-color: #2f3333;
    }
</style>

