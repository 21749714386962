/* const public_key = "9937c539df01643ccfc1bc88df5c5f7db0cf3f9f9f5c636fa3b967bba733323d"; */

import axios from 'axios';

/* const host = "http://localhost:3000/api/"; */
const host = "https://dev.blockvision.it/api/";
export default class AccountService {

	getProductLines(account_id) {
		return axios.get(host + 'line/get_lines/?account_id=' + account_id)
			.then(res => res.data)
	}

	getLineById(line_id) {
		return axios.get(host + 'line/get_line_by_id/?line_id=' + line_id)
			.then(res => res.data)
	}

	getProductCategories(account_id) {
		return axios.get(host + 'category/get_categories/?account_id=' + account_id)
			.then(res => res.data)
	}

	getCategoryById(category_id) {
		return axios.get(host + 'category/get_category_by_id/?category_id=' + category_id)
			.then(res => res.data)
	}

	getCategoriesByLineId(line_id) {
		return axios.get(host + 'category/get_categories_by_line_id/?line_id=' + line_id)
			.then(res => res.data)
	}

	registerLine(account_id, name, description, selectedTags, line_id) {
		var data = JSON.stringify({
			"account_id": account_id,
			"line_id": line_id,
			"line_name": name,
			"products": selectedTags,
			"line_description": description,
			"metadata": {}
		});

		var config = {
			method: 'post',
			url: host + 'line/register_line',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}

	registerCategory(account_id, name, description, selectedTags, line_id, category_id) {
		var data = JSON.stringify({
			"account_id": account_id,
			"line_id": line_id,
			"category_id": category_id,
			"category_name": name,
			"products": selectedTags,
			"category_description": description,
			"metadata": {}
		});

		var config = {
			method: 'post',
			url: host + 'category/register_category',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}

	async addDocument(document_id, docName, docDescr, selectedLine, selectedCategories, account_id, timelineData, tags, products, allCategory, allLine, icon, iconClass, metadataBlockchain, pub_key, supplier_id, approved) {
		var data = {};

		if(allCategory){
			console.log("TUTTE LE CATEGORIE");
			data = {
				"account_id": account_id,
				"client_id": account_id,
				"document_id": document_id,
				"name": docName,
				"description": docDescr,
				"timelineInfo": timelineData,
				"documentTags": tags,
				"metadataBlockchain": metadataBlockchain,
				"products": [],
				"categories": selectedCategories,
				"lines": '',
				"icon": icon,
				"iconClass": iconClass,
				"pub_key": pub_key,
				"supplier_id": supplier_id,
				"status": approved
			};
		}else if(allLine){
			console.log("TUTTA LA LINEA");
			data = {
				"account_id": account_id,
				"client_id": account_id,
				"document_id": document_id,
				"name": docName,
				"description": docDescr,
				"timelineInfo": timelineData,
				"documentTags": tags,
				"metadataBlockchain": metadataBlockchain,
				"products": [],
				"categories": [],
				"lines": selectedLine,
				"icon": icon,
				"iconClass": iconClass,
				"pub_key": pub_key,
				"supplier_id": supplier_id,
				"status": approved
			};
		}else{
			data = {
				"account_id": account_id,
				"client_id": account_id,
				"document_id": document_id,
				"name": docName,
				"description": docDescr,
				"timelineInfo": timelineData,
				"documentTags": tags,
				"metadataBlockchain": metadataBlockchain,
				"products": products,
				"categories": [],
				"lines": '',
				"icon": icon,
				"iconClass": iconClass,
				"pub_key": pub_key,
				"supplier_id": supplier_id,
				"status": approved
			};
		}

		console.log("Data: ", data);

		var config = {
			method: 'post',
			url: host+'document/add_document',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then( response => response.data)
			.catch(error => error);
	}

	async addDocumentFile(file, pub_key) {

		var data = new FormData();
		data.append('file', file);
		data.append('pub_key', pub_key);
		data.append('description', 'File from Prisma platform');

		console.log("Data: ", data);

		var config = {
			method: 'post',
			url: 'https://dev.blockvision.it/api/v2/upload_file_and_hash/key',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	async changeDocumentStatus(document_id, new_status, new_tx) {
		var data = JSON.stringify({
			"document_id": document_id,
			"new_status": new_status,
			"new_tx": new_tx
		});

		var config = {
			method: 'post',
			url: host + 'document/change_doc_status',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	registerTimeline(account_id, ids, timeline_for, timeline_id, parameters, timeline_name) {
		console.log("Dati: ", account_id, ids, timeline_for, timeline_id, parameters, timeline_name);
		var data = JSON.stringify({
			"timeline_name": timeline_name,
			"timeline_id": timeline_id,
			"account_id": account_id,
			"ids": ids,
			"timeline_for": timeline_for,
			"parameters": parameters,
		});

		var config = {
			method: 'post',
			url: host + 'resources/register_timeline',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	async registerproductPage(account_id, ids, page_for, page_id, parameters, page_name) {
		console.log("Dati: ", account_id, ids, page_for, page_id, parameters, page_name);
		var data = JSON.stringify({
			"page_name": page_name,
			"page_id": page_id,
			"account_id": account_id,
			"ids": ids,
			"page_for": page_for,
			"parameters": parameters,
		});

		var config = {
			method: 'post',
			url: host + 'resources/register_product_page',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	async registerProductDocuments(product_id, documents){
		var data = JSON.stringify({
			"product_id": product_id,
			"documents": documents,
		});

		var config = {
			method: 'post',
			url: host + 'product/register_product_documents',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	async removeProductDocuments(product_id, documents){
		var data = JSON.stringify({
			"product_id": product_id,
			"documents": documents,
		});

		var config = {
			method: 'post',
			url: host + 'product/remove_product_documents',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}


	sendMessage(account_id, product_id, name, surname, mail, message) {

		var data = JSON.stringify({
			"product_id": product_id,
			"account_id": account_id,
			"name": name,
			"surname": surname,
			"mail": mail,
			"message": message,
		});

		var config = {
			method: 'post',
			url: host + 'resources/register_message',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	getTimelineById(timeline_id) {
		return axios.get(host + 'resources/get_timeline_by_id/?timeline_id=' + timeline_id)
			.then(res => res.data)
	}

	getProductPageById(page_id) {
		return axios.get(host + 'resources/get_product_page_by_id/?page_id=' + page_id)
			.then(res => res.data)
	}

	getTimelines(account_id) {
		return axios.get(host + 'resources/get_timelines/?account_id=' + account_id)
			.then(res => res.data)
	}

	getPages(account_id) {
		return axios.get(host + 'resources/get_pages/?account_id=' + account_id)
			.then(res => res.data)
	}

	addFolder(folder, account_id){
		var data = JSON.stringify({
			"name" : folder.name,
			"path" : folder.path,
			"parent" : folder.parent,
			"account_id" : account_id
		});

		var config = {
			method: 'post',
			url: host + 'resources/add_folder',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			}); 
	}

	removeFolder(folder_id){
		return axios.post(host+'resources/remove_folder?folder_id='+folder_id)
	}

	loadFolders(account_id) {
		return axios.get(host + 'resources/get_folders/?account_id=' + account_id)
			.then(res => res.data)
	}

	async generateKey(name){

		var config = {
			method: 'get',
			url: 'https://dev.blockvision.it/api/v2/generate/keys?user_name='+name,
			headers: {}
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	registerSupplier(account_id, supplier_id, name, company, piva, description, public_key) {
		var data = JSON.stringify({
			"account_id": account_id,
			"supplier_id": supplier_id,
			"name": name,
			"company": company,
			"piva": piva,
			"description": description,
			"pub_key": public_key,
		});

		var config = {
			method: 'post',
			url: host + 'supplier/register_supplier',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}

	getSuppliers(account_id) {
		return axios.get(host + 'supplier/get_suppliers/?account_id=' + account_id)
			.then(res => res.data)
	}

	getSupplierById(supplier_id) {
		return axios.get(host + 'supplier/get_supplier_by_id/?supplier_id=' + supplier_id)
			.then(res => res.data)
	}
}