import { createApp } from 'vue'

import App from './App.vue'
import router from "./router";
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import VueSmoothScroll from 'vue3-smooth-scroll';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';

import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'

import { library } from "@fortawesome/fontawesome-svg-core";

import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { faMedal } from "@fortawesome/free-solid-svg-icons";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faMapPin } from '@fortawesome/free-solid-svg-icons';


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPencilAlt);
library.add(faBoxOpen);
library.add(faIndustry);
library.add(faInfoCircle);
library.add(faQuestionCircle);
library.add(faSearch);
library.add(faExchangeAlt);
library.add(faMedal);
library.add(faBrain);
library.add(faMapMarkerAlt);
library.add(faLock);
library.add(faUserShield);
library.add(faSmile);
library.add(faCommentDots);
library.add(faPaperPlane);
library.add(faEye);
library.add(faFilePdf);
library.add(faArrowCircleDown);
library.add(faArrowCircleUp);
library.add(faLeaf);
library.add(faExclamationTriangle);
library.add(faMapPin)

/* import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap" */

// Primevue Imports
import PrimeVue from "primevue/config";
import "primevue/resources/themes/md-light-indigo/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import './style/main.css';
import './style/timeline.css';

createApp(App)
    .use(PrimeVue, {
        ripple: true,
        locale: {
            startsWith: "Inizia con",
            contains: "Contiene",
            notContains: "Non contiene",
            endsWith: "Finisce con",
            equals: "Uguale a",
            notEquals: "Diverso da",
            noFilter: "Rimuovi filtri",
            lt: "Minore di",
            lte: "Minore o uguale a",
            gt: "Maggiore di",
            gte: "Maggiore o uguale a",
            dateIs: "La data è",
            dateIsNot: "La data non è",
            dateBefore: "La data è prima di",
            dateAfter: "La data è dopo",
            clear: "Cancella",
            apply: "Applica",
            matchAll: "Tutti fanno match",
            matchAny: "Alcuni fanno match",
            addRule: "Aggiungi regola",
            removeRule: "Rimuovi regola",
            accept: "Sì",
            reject: "No",
            choose: "Scegli",
            upload: "Carica",
            cancel: "Cancella",
            dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
            monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
            today: "Oggi",
            weekHeader: "Wk",
            firstDayOfWeek: 0,
            dateFormat: "dd/mm/yy",
            weak: "Debole",
            medium: "Media",
            strong: "Sicura",
            passwordPrompt: "Inserisci una password",
            emptyFilterMessage: "Nessun risultato trovato",
            emptyMessage: "Nessuna opzione disponibile",
        },
    })
    .component("font-awesome-icon", FontAwesomeIcon)
    .directive('badge', BadgeDirective)
    .use(ToastService)
    .use(router)
    .use(VueSmoothScroll)
    .use(ConfirmationService)
    .use(OpenLayersMap)
    .directive('tooltip', Tooltip)
    .mount('#app')
