<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>

    <div class="p-mx-3">
      <div class="p-grid p-jc-end p-mt-3">
        <Button label="Nuova" icon="pi pi-plus" class="purple-btn purple p-mt-3 p-col-12 p-md-2 p-mr-3" style="font-size: 1.5em" @click="$router.push('/suppliers/createSupplier/'+account_id)"/>
      </div>
      <Message class="p-mx-2" severity="info" v-if="!suppliers.length">Per prima cosa registra una linea di prodotto.</Message>  
      <div class="p-shadow-2 rounded-5 p-p-3 p-mt-3 p-mx-2">
        <suppliers-table :title="'Fornitori'" :suppliers="suppliers" :showRowDetail="true" :rowExpandable="false"></suppliers-table>
      </div>
    </div>
  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';
import SuppliersTable from '../components/SuppliersTable';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Message from 'primevue/message';

import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';

import AccountService from '../../service/AccountService';


export default {
  name: "sample",
  components: {  NavigationBar , Breadcrumb, Button, SuppliersTable, Message },
  setup(){
    onMounted(() => {
      loadSuppliers(account_id);
    })

    const route = useRoute();
    const account_id = route.params.data;

    const accountService = ref(new AccountService());

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Anagrafica fornitori'},
    ]);

    const suppliers = ref([]);

    const loadSuppliers = (account_id) => {
      accountService.value.getSuppliers(account_id).then(data => {
        console.log("suppliers: ",data);
        suppliers.value = data;
      })
    };

    return { home, items, account_id, suppliers }
  }
};
</script>

<style scoped>

</style>

