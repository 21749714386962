<template>
     <div>
        <div class="card">
            <DataTable :value="documents" :resizableColumns="true" :paginator="true" :rows="5" columnResizeMode="fit" responsiveLayout="scroll" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" stripedRows
                :rowsPerPageOptions="[5,10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters" filterDisplay="row" removableSort @rowSelect="onRowSelect" @rowSelectAll="onRowSelect" @rowUnselectAll="onRowUnselect" @rowUnselect="onRowUnselect" v-model:selection="selectedDocuments">

                <template #header>
                    <div class="p-d-flex p-jc-between p-ai-center" style="text-align:left">
                        <h2>{{title}}</h2>
                        <MultiSelect v-model="selectedColumns" :options="columns" placeholder="Select Columns" style="width: 20em"/>
                    </div>
                </template>

                <Column v-if="documentSelectable" selectionMode="multiple" headerStyle="width: 3em"></Column>

                <Column v-if="showRowDetail" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-search" @click="showDetail(slotProps.data)" class="purple-diagonal"></Button>
                    </template>
                </Column>

                <!-- <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-folder-open" @click="moveDocument(slotProps.data)" class="purple-diagonal"></Button>
                    </template>
                </Column> -->

                <Column v-if="selectedColumns.includes('Status')" :sortable="true" field="status" header="Status" filterField="status">
                    <template #body="slotProps">
                        <div class="p-col-12 p-jc-center p-text-center">
                            <Tag v-if="slotProps.data.status == 'submitted'" class="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Waiting"></Tag>
                            <Tag v-else-if="slotProps.data.status == 'approved'" class="mr-2" icon="pi pi-check" severity="success" value="Approved"></Tag>
                            <Tag v-else-if="slotProps.data.status == 'rejected'" class="mr-2" icon="pi pi-times" severity="danger" value="Rejected"></Tag>
                        </div>
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nome')" :sortable="true" field="docName" header="Nome" filterField="docName">
                    <template #body="slotProps">
                        {{slotProps.data.docName}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descrizione')" :sortable="true" field="docDescr" header="Descrizione" filterField="docDescr">
                    <template #body="slotProps">
                        {{slotProps.data.docDescr}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID')" :sortable="true" field="id" header="ID" filterField="id">
                    <template #body="slotProps">
                        {{slotProps.data.id}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Immagine')" header="Immagine">
                    <template #body="slotProps">
                        <i v-if="!slotProps.data.timelineInfo.timelineImage" class="pi false-icon pi-times-circle"></i>
                        <Avatar v-else :image="slotProps.data.timelineInfo.timelineImage" size="xlarge" />
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Icona')" header="Icona">
                    <template #body="slotProps">
                        <i v-if="!slotProps.data.icon_class" class="pi false-icon pi-times-circle"></i>
                        <font-awesome-icon v-else :class="slotProps.data.icon_class" :icon="slotProps.data.icon" />
                    </template>
                </Column>
                

            </DataTable>
        </div>
      </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import Avatar from 'primevue/avatar';

import { useRouter } from 'vue-router';

import { ref } from 'vue';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: "TransactionTable",
  components: { DataTable, Column, Button, MultiSelect, InputText, Avatar, Tag },
  props : {
      account_id : String,
      showRowDetail : Boolean,
      documents : Array, 
      title: String,
      documentSelectable: Boolean
  },
  emits: ["selectedDocuments"],
  setup(props, context){

    /* console.log("Documenti: ",props.documents); */

    const numberOfDocs = ref();

    const router = useRouter();

    const selectedDocuments = ref([]);

    const showDetail  = (data) => {
        /* console.log("Dati da visualizzare: ", data); */
        
        router.push('/documentDetail/'+props.account_id+'&'+data.id);
    }

    const selectedColumns = ref(['Status','Nome','Descrizione','ID']);
    const columns = ref(['Nome','Descrizione','ID','Immagine','Icona','Status']);

    const filters = ref({
        'status': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'docName': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'docDescr': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH}
    });

    const onRowSelect = () => {
        console.log("Documenti selezionati: ", selectedDocuments);
        context.emit("selectedDocuments", selectedDocuments) 
    };

    const onRowUnselect = () => {
        context.emit("selectedDocuments", selectedDocuments)
    };

    return { numberOfDocs, showDetail, selectedColumns, columns, filters, selectedDocuments, onRowSelect, onRowUnselect }
  }
};
</script>

<style scoped>

</style>

