
import axios from 'axios';
/* const host = "http://localhost:3000/api/"; */
const host = "https://dev.blockvision.it/api/";

/* const public_key = "feef7e13074b35f0de32b3ee02bf467d450ec961e247c55df470c938b3cde748"; */

export default class TransactionService {

	/* getTxs(){
		return axios.get('https://dev.blockvision.it/api/v2/get_messages/db?index='+public_key+'&limit=1000&skip=0')
			.then(res => res.data)
	}

	getTx(message_id){
		return axios.get('https://dev.blockvision.it/api/v2/get_message?message_id='+message_id)
			.then(res => res.data)
	} */

	/* getDocuments(){
		return axios.get('https://dev.blockvision.it/api/v2/get_messages/db?index=9937c539df01643ccfc1bc88df5c5f7db0cf3f9f9f5c636fa3b967bba733323d')
			.then(res => res.data)
	} */

	getDocuments(account_id){
		return axios.get(host+'document/get_documents/?account_id='+account_id)
			.then(res => res.data)
	}

	getDocumentById(doc_id){
		return axios.get(host+'document/get_document_by_id/?document_id='+doc_id)
			.then(res => res.data)
	}

	async getDocumentsByIds(ids){
		var data = JSON.stringify({
			"ids": ids
		});

		var config = {
			method: 'post',
			url: host + 'document/get_docs_by_ids',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}

	async changeOwnership(prev_key, new_key, doc_id){
		var config = {
			method: 'post',
			url: 'https://dev.blockvision.it/api/v2/change_ownership?previous_owner_pub_key='+prev_key+'&new_owner_pub_key='+new_key+'&message_id='+doc_id
		};

		return await axios(config)
			.then(response => response.data)
			.catch(error => error);
	}
}