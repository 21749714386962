<template>
  <main class="purple">

    <div class="p-mx-3">
     
      <div class="p-grid p-mx-3 p-ai-center" style="height: 101vh; overflow-x: hidden !important;">

        <div class="p-fluid p-formgrid p-grid p-col-12 p-md-5 p-shadow-5 rounded-5 p-ml-5" style="background-color: white;">
          
          <div class="p-field p-col-12 p-p-3">
            <h2 class="p-mb-3 p-ml-2">File</h2>

            <div class="p-field p-col-12">
              <label for="docName">Nome</label>
              <InputText id="docName" type="text" v-model="docName"/>
            </div>

            <div class="p-field p-col-12">
              <label for="docDescr">Descrizione</label>
              <TextArea v-model="docDescr" id="description" type="text"/>
            </div>
            
            <FileUpload id="document" name="file" @uploader="onUpload" :customUpload="true" :multiple="false" :maxFileSize="100000000">
                <template #empty>
                    <p>Trascina o scegli il file da caricare</p>
                </template>
            </FileUpload>
            <Message class="" severity="warn">E' possibile caricare un solo file per volta.</Message>
          </div>

        </div>

      </div>

      <Dialog header="Conferma" :showHeader="!showSpinner" v-model:visible="showConfirm" :style="{width: '50vw'}" :modal="true">
        <div v-if="!showSpinner">
          <div>
            Caricando il documento confermi di essere al corrente di quanto segue:<br/>
            <ul class="p-mt-1">
              <li>Il documento verrà caricato su IPFS e il suo hash verrà registrato su una transazione in blockchain;</li>
              <li><b>Non è in alcun modo possibile</b> rimuovere una transazione dalla blockchain;</li>
              <li>È possible eliminare o modificare un documento precedentemente caricato su IPFS, ma il documento può comunque sopravvivere nel suo formato originale.</li>
            </ul>
          </div>

          <div class="p-mt-5">
            <Checkbox id="allCategory" v-model="enableUpload" :binary="true"/>
            <label class="p-ml-2" for="allCategory">Presto il consenso al caricamento del documento.</label>
          </div>
        </div>

        <div v-else>
          <div class="p-text-center p-mt-5">
            <h2>Attendere il caricamento del documento.</h2>
            <Message class="p-mt-3" severity="warn">Non uscire o ricaricare la pagina fino al termine dell'operazione.</Message>
            <ProgressSpinner class="" />
          </div>
        </div>

        <template #footer>
          <Button v-if="!showSpinner" label="Carica" icon="pi pi-upload" @click="confirmUpload" :disabled="!enableUpload" />
        </template>
      </Dialog>

    </div>

  </main>
</template>

<script>

import FileUpload from 'primevue/fileupload';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';

import { ref, onMounted } from 'vue';

import { useRoute } from 'vue-router';

import AccountService from '../../service/AccountService';


export default {
  name: "sample",
  components: { Message, ProgressSpinner, Checkbox, FileUpload, InputText, TextArea, Button, Dialog },

  setup(){
    onMounted(() => {

    });

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const supplier_id = route_params.split('&')[1];
    const pub_key = route_params.split('&')[2];

    const accountService = ref(new AccountService());
    
    const selectedFile = ref();

    const onFileChange = (e) => {
      console.log("Event: ",e);
      selectedFile.value = e.target.files[0]; // accessing file
      /* console.log("File: ", selectedFile.value); */
    };

    const showConfirm = ref(false);
    const enableUpload = ref(false);
    const showSpinner = ref(false);

    const closeConfirm = () => {
      showConfirm.value = false;
    }

    const confirmUpload = () => {
      showSpinner.value = true;

      accountService.value.addDocumentFile(selectedFile.value, pub_key).then((res) => {
        console.log("Risposta blockchain: ", res);
        let metadataBlockchain = {
          "ipfs": res.payload.ipfs,
          "link": res.payload.link_file,
          "tx": res.message_id
        }
        accountService.value.addDocument(null, docName.value, docDescr.value, null, null, account_id, {}, [], [], false, false, [], '', metadataBlockchain, pub_key, supplier_id, false).then((res) => {
          console.log("Risposta couchdb: ", res);
          showSpinner.value = false;
          showConfirm.value = false;
        })
        
      });
    }

    const onUpload = (e) => {
      selectedFile.value = e.files[0];
      showConfirm.value = true;

      console.log("Dati: ", supplier_id, account_id, pub_key);
    }

    const docName = ref();
    const docDescr = ref();

    return { docName, docDescr, onUpload, confirmUpload, closeConfirm, enableUpload, onFileChange, showSpinner, showConfirm }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }
</style>

