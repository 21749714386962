<template>
     <div>
        <Toast />
        <div class="card">
            <DataTable :value="productCategories" :resizableColumns="true" :paginator="true" :rows="5" columnResizeMode="expand" responsiveLayout="scroll" 
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" stripedRows
                :rowsPerPageOptions="[5,10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                v-model:filters="filters" filterDisplay="row" removableSort>

                <template #header>
                    <div class="p-d-flex p-jc-between p-ai-center" style="text-align:left">
                        <h2>{{title}}</h2>
                        <MultiSelect v-if="showSelectedColumns" v-model="selectedColumns" :options="columns" placeholder="Select Columns" style="width: 20em"/>
                    </div>
                </template>

                <Column v-if="showRowDetail" headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="slotProps">
                        <Button type="button" icon="pi pi-search" @click="showDetail(slotProps.data)" class="purple-diagonal purple-btn"></Button>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nome')" :sortable="true" field="name" header="Nome" filterField="name">
                    <template #body="slotProps">
                        {{slotProps.data.name}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per nome'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descrizione')" :sortable="true" field="description" header="Descrizione" filterField="description">
                    <template #body="slotProps">
                        {{slotProps.data.description}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per descrizione'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID')" :sortable="true" field="id" header="ID" filterField="id">
                    <template #body="slotProps">
                        {{slotProps.data.id}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID Linea')" :sortable="true" field="id_line" header="ID Linea" filterField="id_line">
                    <template #body="slotProps">
                        {{slotProps.data.id_line}}
                    </template>
                    <template #filter="{filterModel,filterCallback}">
                        <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" :placeholder="'Ricerca per ID linea'" v-tooltip.top.focus="'Premi invio per filtrare'"/>
                    </template>
                </Column>
                
                <template #paginatorLeft>
                    <Button type="button" icon="pi pi-refresh" @click="loadProductCategories()" class="p-button-text" />
                </template>
            </DataTable>
        </div>
      </div>
</template>

<script>

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';
import { useRouter, useRoute } from 'vue-router';

import { ref, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: "ProductCategoriesTable",
  components: { DataTable, Column, Button, Toast, MultiSelect, InputText },
  props: {
      title : String,
      productCategories : Array,
      showRowDetail : Boolean,
      showSelectedColumns : Boolean
  },
  setup(){
    onMounted(() => {
    })

    const toast = useToast();

    const expandedRows = ref([]);

    const router = useRouter();

    const onRowExpand = (event) => {
        toast.add({severity: 'info', summary: 'Transaction Expanded', detail: event.data.message_id, life: 3000});
    };
    const onRowCollapse = (event) => {
        toast.add({severity: 'info', summary: 'Transaction Collapsed', detail: event.data.message_id, life: 3000});
    };

    const formatData = (value) => {
        var date = new Date(value*1000)
        return date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear()+
          " "+date.getHours()+
          ":"+date.getMinutes()+
          ":"+date.getSeconds()
    };

    const route = useRoute();

    const account_id = route.params.data;

    console.log("ID: "+account_id);

    const showDetail  = (data) => {
        console.log(data);
        router.push('/categoryDetail/'+data.id_account+'&'+data.id);
    }

    const rowClassFunction = () => {
        return "overflow-break";
    }

    const selectedColumns = ref(['Nome','ID','ID Linea']);
    const columns = ref(['Nome','Descrizione','ID','ID Linea']);

    const filters = ref({
        'name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'description': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
        'id_line': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
    });

    return { expandedRows, account_id, onRowExpand, onRowCollapse, formatData, showDetail, rowClassFunction, selectedColumns, columns, filters}
  }
};
</script>

<style scoped>

</style>

