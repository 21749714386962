<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items" />
    <Toast />

    <div class="p-ml-4 p-mt-5">
      <Message v-if="!supplier_id" class="p-mr-4" severity="info">Per registrare un fornitore è necessario definirne nome e ragione sociale.</Message>  
    </div>

    <div class="p-mt-5 p-mx-3 p-mb-5">
      <div class="p-grid p-jc-between p-mr-1 p-ml-3 p-mt-5">

        <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">

            <div class="p-col-12 p-md-6">
              <h3 class="">Nome fornitore</h3>
              <span class="p-float-label p-mt-2">
                <InputText v-model="name" id="name" type="text" placeholder="Nome" :disabled="supplier_id"/>
              </span>
            </div>

            <div class="p-col-12 p-md-4">
              <h3>Chiave pubblica</h3>
              <span class="p-float-label p-mt-2">
                <InputText v-model="public_key" id="public_key" type="text" placeholder="Chiave" :disabled="true"/>
              </span>
            </div>

            <div class="p-col-12 p-md-2">
              <span class="p-float-label p-mt-5">
                <Button label="Genera chiave" :disabled="!name"  icon="pi pi-key" class="purple purple-btn" style="font-size: 1.5em" @click="generateKey()"/>
              </span>
            </div>

            <div class="p-col-12 p-md-6">
              <h3 class="p-mt-5">Ragione sociale</h3>
              <span class="p-float-label p-mt-2">
                <InputText v-model="company" id="company" type="text" placeholder="Ragione sociale" :disabled="supplier_id"/>
              </span>
            </div>

            <div class="p-col-12 p-md-6">
              <h3 class="p-mt-5">Partita Iva</h3>
              <span class="p-float-label p-mt-2">
                <InputText v-model="piva" id="piva" type="text" placeholder="Partita Iva" :disabled="supplier_id"/>
              </span>
            </div>

            <div class="p-col-12">
              <h3 class="p-mt-5">Descrizione</h3>
              <span class="p-float-label p-mt-2">
                <TextArea v-model="description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="supplier_id"/>
              </span>
            </div>

            <div class="p-col-12" style="padding-left: 0px !important; padding-right: 0px !important">
              <div class="p-mt-4">
                <Button label="Registra" v-if="!supplier_id" :disabled="!(name && company)"  icon="pi pi-pencil" class="purple purple-btn" style="font-size: 1.5em" @click="registerSupplier()"/>
              </div>
            </div>

        </div>
        
      </div>
    </div>

  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Toast from 'primevue/toast';
import Message from 'primevue/message';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';

import NavigationBar from '../components/NavigationBar';

import AccountService from '../../service/AccountService';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useToast } from 'primevue/usetoast';


export default {
  name: "Create Product Line",
  components: {  NavigationBar, Breadcrumb, Toast, Message, InputText, TextArea, Button },

  setup(){
    const toast = useToast();

    onMounted(() => {
    })

    const accountService = ref(new AccountService());

    const name = ref();
    const company = ref();
    const description = ref();
    const piva = ref();
    const public_key = ref();

    const route = useRoute();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const supplier_id = route_params.split('&')[1];

    const home = ref({
      icon: 'pi pi-home', 
      to: '/',
    });

    const items = ref([
      {
        label: 'Fornitori',
        to: '/suppliers/'+account_id
      },
      {
        label: 'Crea nuova fornitore'
      }
    ])


    const registerSupplier = () => {
      accountService.value.registerSupplier(account_id, supplier_id, name.value, company.value, piva.value, description.value, public_key.value).then( response => {
        if(response == 200){
          toast.add({severity: 'success', summary: 'Success', detail: 'Supplier registered', life: 5000});
          
        }
      });
    }

    const generateKey = () => {
      console.log("Genero chiave");
      accountService.value.generateKey(name.value).then( response => {
        console.log("Risposta: ", response);

        public_key.value = response.pub_key;
      });
    }

    return { home, items, registerSupplier, name, description, company, public_key, account_id, supplier_id, piva, generateKey }
  }
};
</script>

<style scoped>
  .a {
    max-height: 80vh !important;
  }
</style>

