<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
    <Breadcrumb :home="home" :model="items"/>
    
    <div>

      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-grid p-d-flex p-jc-end" style="padding-right: 0px !important;">
          <div class="p-col-4 p-d-flex-p-jc-start p-ai-center p-grid">
            <div class="p-col-3">
              <h1>Status:</h1>
            </div>
            <div class="p-col-8 p-ml-5">
              <Tag v-if="status == 'submitted'" class="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Waiting"></Tag>
              <Tag v-else-if="status == 'approved'" class="mr-2" icon="pi pi-check" severity="success" value="Approved"></Tag>
              <Tag v-else-if="status == 'rejected'" class="mr-2" icon="pi pi-times" severity="danger" value="Rejected"></Tag>
            </div>      
          </div>

          <div class="p-col-8 p-jc-end p-d-flex" style="padding-right: 0px !important;">
            <Button :disabled="!editable" label="Salva modifiche" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="updateDoc()"/>
            <a v-bind:href="blockchainLink" target="_blank" style="text-decoration: none !important;"><Button label="Visualizza transazione" icon="pi pi-eye" class="purple-btn purple-diagonal p-mt-3 p-mb-5" style="font-size: 1.5em" /></a>
          </div>

          <div class="p-col-12 p-jc-end p-d-flex" style="padding-right: 0px !important;">
            <Message v-if="status == 'submitted'" severity="warn" class="p-col-12" :closable="false">
              <div class="p-d-flex p-ai-center">
                Il documento è stato caricato da {{supplier_name}} ({{supplier_company}}), e risulta ancora in attesa di approvazione.
                <Button label="Approva" icon="pi pi-check" class="p-button-succes p-ml-3" style="font-size: 1.5em" @click="openConfirm()"/>
                <Button label="Rifiuta" icon="pi pi-times" class="p-button-danger p-ml-3" style="font-size: 1.5em" @click="openReject()"/>
              </div>
            </Message>
            <Message v-else-if="status == 'approved'" severity="success" class="p-col-12">Il documento è stato approvato e firmato.</Message>
            <Message v-else-if="status == 'rejected'" severity="error" class="p-col-12">
              <div class="p-d-flex p-ai-center">
                Il documento è stato rifiutato.
                <Button label="Approva" icon="pi pi-check" class="p-button-succes p-ml-3" style="font-size: 1.5em" @click="openConfirm()"/>
              </div>  
            </Message>
          </div>

        </div>

        <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-p-3 p-shadow-2 rounded-5">
            <div class="p-d-flex p-ai-center p-col-12"><h3>Consenti modifica dei campi</h3> <InputSwitch class="p-ml-3" v-model="editable" /></div>

            <div v-if="documentLoaded" class="p-col-12 p-md-6">

              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="document_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Nome</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="docName" id="ID" type="text" placeholder="Nome" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Descrizione</h3>
                <span class="p-float-label p-mt-2">
                  <TextArea v-model="docDescr" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="!editable"/>
                </span>
              </div>

            </div>

            <div v-if="documentLoaded" class="p-col-12 p-md-6">
              
              <div>
                <h3 class="p-mt-5">Nome (Timeline)</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="timelineName" id="timelineName" type="text" placeholder="Nome (timeline)" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Località (Timeline)</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="timelineLocation" id="timelineLocation" type="text" placeholder="Località (timeline)" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Descrizione (Timeline)</h3>
                <span class="p-float-label p-mt-2">
                  <TextArea v-model="timelineDescription" id="timelineDescription" type="text" placeholder="Descrizione (Timeline)" rows="5" :disabled="!editable"/>
                </span>
              </div>

            </div>

            <div v-if="documentLoaded && editable" class="p-col-12 p-grid" style="padding-right: 0px !important;">
              <div class="p-field p-col-12 p-md-6">
                <h3 class="p-mt-5">Immagine (Link)</h3>
                <span class="p-float-label p-mt-2">
                  <InputText id="" v-model="image" type="text"/>
                </span>
              </div>

              <div class="p-field p-col-12 p-md-6" style="padding-right: 0px !important;">
                  <h3 class="p-mt-5">Icona</h3>
                  <span class="p-float-label p-mt-2">
                    <Dropdown v-model="selectedIcon" :options="icons" @change="setSelectedIcon()" optionLabel="reference" placeholder="Scegli icona">
                      <template #value="slotProps">
                        <div v-if="slotProps.value"><font-awesome-icon v-if="slotProps.value.class" :icon="slotProps.value.icon" class="p-mr-3"/> {{slotProps.value.reference}}</div>
                        <span v-else>
                          {{slotProps.placeholder}}
                        </span>
                      </template>
                      <template #option="slotProps">
                        <div><font-awesome-icon v-if="slotProps.option.class" :icon="slotProps.option.icon" class="p-mr-3"/> {{slotProps.option.reference}}</div>
                      </template>
                    </Dropdown>
                  </span>
              </div>
            </div>

          </div>
          
          <div v-if="editable" class="p-grid p-jc-between p-col-12 p-mt-3 p-shadow-2 rounded-5">

            <div class="p-field p-col-12 p-p-3">
              <div class="p-d-flex p-ai-center">
                <h2 class="p-ml-2">Hastags</h2>
                <Button icon="pi pi-plus" class="p-ml-4 p-button-rounded purple-diagonal" @click="addTag()"/>
              </div>
              <div class="p-grid p-col-12">
                <div v-for="(input, index) in tags" :key='index'>

                  <div class="p-field p-col-12">
                    <label for="docName">Nome</label>
                    <div class="p-my-auto">
                      <InputText id="docName" type="text" :placeholder="input.tagName" v-model="input.tagName"/>
                      <Button icon="pi pi-trash" class="p-ml-3 p-button-rounded purple-diagonal" @click="removeTag(index)"/>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>

        <div class="p-grid p-col-12 p-jc-start p-ai-stretch" style="padding-right: 0px !important; margin-right: 0px !important;">

          <div class="p-col-6">
            <Card>
              <template #content :v-if="documentLoaded">
                <embed class="p-col-12" type="application/pdf" :src="docSrc" height="500px">
              </template>
            </Card>
          </div>
           

          
          <div v-if="docProducts.length" class="p-col-12 p-lg-6 overflow-break" style="padding-right: 0px !important; margin-right: 0px !important;">
            <Card>
              <template #content :v-if="documentLoaded">
              <products-table class="p-mt-3" title="Prodotti registrati" :products="docProducts" :showRowDetail="true" :lineSelectable="false" 
                :showRemoveSwitch="true" :selectedAll="false" :setRemovable="setRemovable" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
                :showCat="true" :showLine="true" :showTimeline="true" :showPage="true" :showDocs="true" :showSelectedColumns="true" @removeProduct="removeDoc"
                ></products-table>
              </template>
            </Card>
          </div>

          <div class="p-col-12 p-lg-6">
            <h2 class="p-mt-5">Aspetto pannello timeline</h2> 
            <Message severity="info" class="p-mb-5"><b>Il pannello mostrato è indicativo.</b> L'aspetto reale potrà variare in base alle configurazioni specifiche del prodotto.</Message>

            <div class="p-grid p-col-12 p-d-flex p-jc-center">
              <div class="p-grid p-col-7">
                <timeline-panel-V2
                  :docName="docName" :timelineLocation="timelineLocation" :timelineName="timelineName" :timelineDescription="timelineDescription"
                  :tags="tags" :panelClass="'p-col-12 p-grid p-jc-center'" :imageUrl="image" :parameters="parameters" documentUrl="" blockchainLink=""
                >
                </timeline-panel-V2>
              </div>
            </div>
          </div> 
          
        </div>

        <Dialog header="Conferma" :showHeader="!showSpinner" v-model:visible="showConfirm" :style="{width: '50vw'}" :modal="true">
          <div v-if="!showSpinner">
            <div>
              <b>Approvando</b> il documento confermi di essere al corrente di quanto segue:<br/>
              <ul class="p-mt-1">
                <li>Il documento verrà firmato digitalmente e la firma prodotta inserita in una transazione su blockchain;</li>
                <li><b>Non è in alcun modo possibile</b> annullare questa operazione;</li>
              </ul>
            </div>

            <div class="p-mt-5">
              <Checkbox id="allCategory" v-model="enableUpload" :binary="true"/>
              <label class="p-ml-2" for="allCategory">Dichiaro di voler firmare il documento.</label>
            </div>
          </div>

          <div v-else>
            <div class="p-text-center p-mt-5">
              <h2>Attendere la firma del documento.</h2>
              <Message class="p-mt-3" severity="warn">Non uscire o ricaricare la pagina fino al termine dell'operazione.</Message>
              <ProgressSpinner class="" />
            </div>
          </div>

          <template #footer>
            <Button v-if="!showSpinner" label="Firma" icon="pi pi-pencil" @click="approveDoc()" :disabled="!enableUpload" />
          </template>
        </Dialog>

        <Dialog header="Rifiuta documento" v-model:visible="showReject" :style="{width: '50vw'}" :modal="true">
          <div>
            <b>Rifiutando</b> il documento confermi di essere al corrente di quanto segue:<br/>
            <ul class="p-mt-1">
              <li>Nessuna transazione verrà scritta su blockchain, e dovrai informare il fornitore di reinviarti un documento valido.</li>
              <li>Il documento rimarrà a database e potrà essere successivamente accettato qualora necessario.</li>
            </ul>
          </div>

          <div class="p-mt-5">
            <Checkbox id="allCategory" v-model="enableReject" :binary="true"/>
            <label class="p-ml-2" for="allCategory">Dichiaro di voler rifiutare il documento.</label>
          </div>

          <template #footer>
            <Button label="Rifiuta" icon="pi pi-times" @click="rejectDoc()" :disabled="!enableReject" />
          </template>
        </Dialog>

      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';

import TransactionService from '../../service/TransactionService';
import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';

import NavigationBar from '../components/NavigationBar';
import ProductsTable from '../components/ProductsTable';
import TimelinePanelV2 from '../components/TimelinePanelV2';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';


export default {
  name: "sample",
  components: {  NavigationBar , Button, Dialog, Breadcrumb, Card, Checkbox, ProgressSpinner, ProductsTable, Tag, TimelinePanelV2, Message, InputText, InputSwitch, TextArea, Dropdown },
  setup(){
    const documentLoaded = ref(false);

    onMounted(() => {
      loadDocumentInfo();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const document_id = route_params.split('&')[1];

    const docName = ref();
    const docDescr = ref();
    
    const document = ref();
    const docSrc = ref();
    const blockchainLink = ref();

    const docProducts = ref([]);

    const tags = ref([]);
    const timelineName = ref();
    const timelineDescription = ref();
    const timelineLocation = ref();
    const image = ref();

    const status = ref();

    const hasTimeline = ref(false);

    const icons = ref([ 
      { icon: ['fas', 'industry'], class: "badge-icon fa-industry", reference: "Processo industriale" },
      { icon: ['fas', 'search'], class: "badge-icon fa-search", reference: "Ricerca" },
      { icon: ['fas', 'exchange-alt'], class: "badge-icon fa-exchange-alt", reference: "Logistica" },
      { icon: ['fas', 'medal'], class: "badge-icon fa-medal", reference: "Prodotto" },
      { icon: ['fas', 'box-open'], class: "badge-icon fa-box-open", reference: "Packaging" },
      { icon: ['fas', 'brain'], class: "badge-icon fa-brain", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'user-shield'], class: "badge-icon fa-user-shield", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'lock'], class: "badge-icon fa-lock", reference: "Proprietà intellettuale" },
      { icon: ['fas', 'map-marker-alt'], class: "badge-icon fa-map-marker-alt", reference: "Proprietario" },
    ]);

    const selectedIcon = ref({});

    const selectedIconClass = ref("");
    const selectedIconIcon = ref([]);

    const setSelectedIcon = () => {
      selectedIconClass.value = selectedIcon.value.class;
      selectedIconIcon.value = selectedIcon.value.icon;
    }

    const setRemovable = ref(false);

    const editable = ref(false);

    const transactionService = ref(new TransactionService());
    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const messageId = ref();

    const loadDocumentInfo = () => {
      transactionService.value.getDocumentById(document_id).then(data => {
        let d = data.docs[0]

        console.log("DOCUMENTO: ", d);

        docName.value = d.docName;
        docDescr.value = d.docDescr;

        docProducts.value = d.products;

        docSrc.value = d.metaBlockchain.link;

        status.value = d.status;

        messageId.value = d.metaBlockchain.tx;

        blockchainLink.value = 'https://explorer.iota.org/mainnet/message/'+messageId.value;

        loadSupplierInfo(d.supplier_id);
        
        if(Object.keys(d.timelineInfo).length != 0){

          hasTimeline.value = true;
          timelineName.value = d.timelineInfo.timelineName;
          timelineDescription.value = d.timelineInfo.timelineDescription;
          timelineLocation.value = d.timelineInfo.timelineLocation;
          tags.value = d.docTags;
          image.value = d.timelineInfo.timelineImage;

          selectedIconClass.value = d.icon_class;
          selectedIconIcon.value = d.icon;
          selectedIcon.value = icons.value.find( e => e.class == d.icon_class);

        }else{
          selectedIcon.value = icons.value[0];
        }

        documentLoaded.value = true;

      })
    }

    const supplier_company = ref();
    const supplier_name = ref();
    const supplier_key = ref();

    const loadSupplierInfo = (id) => {
      accountService.value.getSupplierById(id).then( (data) => {
        supplier_name.value = data[0].name;
        supplier_company.value = data[0].company;
        supplier_key.value = data[0].pub_key;
      })
    }

    const addTag = () => {
      tags.value.push({ tagName : ""});
    }

    const removeTag = (index) => {
      tags.value.splice(index, 1);
    }

    const removeDoc = (product_id) => {
      productService.value.removeProductDocument(document_id, product_id).then( () => {
        loadDocumentInfo();
        setRemovable.value = true;
      })
    }

    const showConfirm = ref(false);
    const enableUpload = ref(false);
    const showSpinner = ref(false);

    const closeConfirm = () => {
      showConfirm.value = false;
    }

    const openConfirm = () => {
      showConfirm.value = true;
    }

    const showReject = ref(false);
    const enableReject = ref(false);

    const closeReject = () => {
      showReject.value = false;
    }

    const openReject = () => {
      showReject.value = true;
    }

    const approveDoc = () => {
      showSpinner.value = true;
      /* console.log(supplier_key.value, '9937c539df01643ccfc1bc88df5c5f7db0cf3f9f9f5c636fa3b967bba733323d', messageId.value); */
      transactionService.value.changeOwnership(supplier_key.value, '9937c539df01643ccfc1bc88df5c5f7db0cf3f9f9f5c636fa3b967bba733323d', messageId.value).then( (res) => {
        console.log("Risposta: ", res);
        accountService.value.changeDocumentStatus(document_id, 'approved', res.message_id).then( () => {
          showSpinner.value = false;
          showConfirm.value = false;
          router.go();
        })
      });
    }

    const rejectDoc = () => {
      accountService.value.changeDocumentStatus(document_id, 'rejected', null).then( () => {
        showReject.value = false;
        router.go();
      })
    }

    const updateDoc = () => {
      let timelineData = {
        "timelineLocation" : timelineLocation.value,
        "timelineName" : timelineName.value,
        "timelineDescription" : timelineDescription.value,
        "timelineImage" : image.value
      }

      accountService.value.addDocument(document_id, docName.value, docDescr.value, null, null, null, timelineData, tags.value, null, null, null, selectedIconIcon.value, selectedIconClass.value, null).then(() => {
          router.go();
      });
    }

    const parameters = ref({
      "extBorderTickness" : 0,
      "extBorderRadius" : 6,
      "extBorderColor" : "000000",
      "extBorderShadow" : "f5f5f5",

      "primaryTextBodyColor" : "000000",
      "primaryTextTitleColor" : "000000",
      "hastagColor" : "000000",

      "buttonBackgroundColor" : "ffffff",
      "buttonIconColor" : "949494",
      "backgroundColor" : "ffffff"
    });

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Dettaglio documento'},
    ]);

    return { home, items, document, hasTimeline, document_id, docSrc, docName, docDescr, timelineLocation, timelineName, account_id, supplier_key, openReject, showReject, closeReject, enableReject,
      timelineDescription, documentLoaded, blockchainLink, docProducts, tags, parameters, removeDoc, setRemovable, status, supplier_company, supplier_name, openConfirm, rejectDoc,
      selectedIconIcon, selectedIconClass, editable, updateDoc, addTag, removeTag, image, setSelectedIcon, icons, selectedIcon, approveDoc, showConfirm, enableUpload, showSpinner, closeConfirm
    }
  }
};
</script>

<style scoped>
  ::v-deep(.step-small-text){
    font-size: 0.9vw !important;
  }

  ::v-deep(.step-small-icon){
    font-size: 0.7vw !important;
  }

  ::v-deep(.step-description-text){
    font-size: 1.1vw !important;
  }
</style>

