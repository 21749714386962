<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>
      <Toast />

    <div>
      
      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Aggiungi prodotti" icon="pi pi-plus" class="purple-btn purple p-mt-3 p-mb-5" style="font-size: 1.5em" @click="uploadCategory"/>
        </div>

        <div class="p-grid p-col-12 p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
            <div class="p-col-12">
              <div class="p-d-flex p-ai-center"><h3>Consenti modifica dei campi</h3> <InputSwitch class="p-ml-3" v-model="editable" /></div>

              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="category_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Nome</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="category_name" id="ID" type="text" placeholder="Nome" :disabled="!editable"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Descrizione</h3>
                <span class="p-float-label p-mt-2">
                  <TextArea v-model="category_description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="!editable"/>
                </span>
              </div>

              <Button v-if="editable" label="Salva modifiche" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="updateCategory()"/>

            </div>
          </div>
          
        </div>

        <div class="p-grid p-col-12 p-jc-start p-ai-stretch" style="padding-right: 0px !important; margin-right: 0px !important;">
          
          <div v-if="category_products.length" class="p-col-12 overflow-break" style="padding-right: 0px !important; margin-right: 0px !important;">
            <Card>
              <template #content>
                <products-table class="p-mt-3" title="Lista prodotti registrati" :products="category_products" :showRowDetail="true" :lineSelectable="false" 
                :showRemoveSwitch="true" :selectedAll="false" :setRemovable="false" :showIdTag="true" :showID="true" :showName="true" :showDescription="true" 
                :showCat="true" :showLine="true" :showTimeline="true" :showPage="true" :showDocs="true" :showSelectedColumns="false" @removeProduct="removeFromCategory"
                ></products-table>
              </template>
            </Card>
          </div>
          
        </div>
      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';

import NavigationBar from '../components/NavigationBar';
import ProductsTable from '../components/ProductsTable';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'primevue/usetoast';

import AccountService from '../../service/AccountService';
import ProductService from '../../service/ProductService';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Card, ProductsTable, Button, Toast, InputText, InputSwitch, TextArea},
  setup(){

    onMounted(() => {
        loadCategoryInfo();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const category_id = route_params.split('&')[1];

    const category_name = ref();
    const category_description = ref();
    const category_line = ref();
    const category_products = ref([]);

    const editable = ref(false);

    const accountService = ref(new AccountService());
    const productService = ref(new ProductService());

    const toast = useToast();

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Categorie',
        to: '/productCategories/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    const loadCategoryInfo = () => {
      accountService.value.getCategoryById(category_id).then(data => {
        console.log("DATA: ", data);
        category_products.value = data.products;
        category_products.value.forEach(e => {
          e.removable = true;
        })
        category_name.value = data.name;
        category_line.value = data.id_line;
        category_description.value = data.description;
      })
    }

    const updateCategory = () => {
      accountService.value.registerCategory(account_id, category_name.value, category_description.value, null, null, category_id).then( response => {
        if(response == 200){
          router.go();
        }
      });
    }

    const uploadCategory = () => {
      router.push('/productCategories/createProductCategory/'+account_id+'&'+category_line.value+'&'+category_id)
    }

    const removeFromCategory = (id) => {
      productService.value.removeProductCategory(id).then( () => {
        toast.add({severity: 'info', summary: 'Product Removed', detail: 'The selected product ('+id+') has been removed from current category', life: 3000});
        category_products.value = [];
        loadCategoryInfo();
      })
    }

    return { account_id, editable, category_name, category_line, uploadCategory, updateCategory, removeFromCategory, category_description, category_id, category_products, home, items, /* document, docSrc, docName, docDescr, docLoc, documentLoaded, blockchainLink */}
  }
};
</script>

<style scoped>

</style>

