import axios from 'axios';

/* const host = "http://localhost:3000/api/"; */
const host = "https://dev.blockvision.it/api/";

export default class ProductService {

	getFreeProducts(account_id, limit){
		return axios.get(host+'product/find_free_products?account_id='+account_id+'&query_limit='+limit)
			.then(res => res.data)
	}

	getProductsByLineId(line_id, limit){
		console.log("Richiesti i prodotti", line_id, limit);
		return axios.get(host+'product/get_products_by_line?line_id='+line_id+'&query_limit='+limit)
			.then(res => res.data)
	}

	getFreeProductsByLineId(line_id, limit){
		console.log("Richiesti i prodotti", line_id, limit);
		return axios.get(host+'product/get_free_products_by_line?line_id='+line_id+'&query_limit='+limit)
			.then(res => res.data)
	}

	getProductsByTimelineId(timeline_id){
		return axios.get(host+'product/get_products_by_timeline?timeline_id='+timeline_id)
			.then(res => res.data)
	}

	getProductsByPageId(page_id){
		return axios.get(host+'product/get_products_by_page?page_id='+page_id)
			.then(res => res.data)
	}

	async getProductsByCategoryId(categories_id, limit){
		console.log("Richiesti i prodotti", categories_id, limit);
		var data = JSON.stringify({
			"categories_id": categories_id,
			"query_limit": limit
		});
		
		var config = {
		method: 'post',
			url: 'http://host:3000/api/product/get_products_by_category',
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		return await axios(config)
			.then( response => response.data);
	}

	getProducts(account_id, limit){
		return axios.get(host+'product/get_products?account_id='+account_id+'&query_limit='+limit)
			.then(res => res.data)
	}

	getProductByID(product_id){
		return axios.get(host+'product/get_product_by_id?product_id='+product_id)
			.then(res => res.data)
	}

	getProductByTag(tag_id){
		return axios.get(host+'product/get_product_by_tag?id_tag='+tag_id)
			.then(res => res.data)
	}

	removeProductLine(product_id){
		return axios.post(host+'product/remove_product_line?product_id='+product_id)
	}

	removeProductCategory(product_id){
		return axios.post(host+'product/remove_product_category?product_id='+product_id)
	}

	removeTimeline(product_id){
		return axios.post(host+'product/remove_product_timeline?product_id='+product_id)
	}

	removeProductDocument(document_id, product_id){
		return axios.post(host+'product/remove_product_document?document_id='+document_id+'&product_id='+product_id)
	}

	assignTimeline(product_id, timeline_id){
		return axios.post(host+'product/set_product_timeline?product_id='+product_id+'&timeline_id='+timeline_id)
	}

	registerProductLine(line_id, products){
		var data = JSON.stringify({
			"line_id": line_id,
			"products": products,
		});

		var config = {
			method: 'post',
			url: host+'product/register_product_line',
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}

	registerProductCategory(category_id, products, name, description){
		var data = JSON.stringify({
			"category_id": category_id,
			"products": products,
			"name": name,
			"description": description
		});

		var config = {
			method: 'post',
			url: host+'product/register_product_category',
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}

	registerProduct(account_id, id, id_linea, id_categoria, id_timeline, name, description, notag){
		var data = JSON.stringify({
			"id": id,
			"account_id": account_id,
			"id_linea": id_linea,
			"id_categoria": id_categoria,
			"id_timeline": id_timeline,
			"name": name,
			"description": description,
			"notag" : notag
		});

		var config = {
			method: 'post',
			url: host+'product/register_product',
			headers: { 
				'Content-Type': 'application/json'
			},
			data : data
		};

		return axios(config)
			.then(res => res.status)
			.catch(function (error) {
				console.log(error);
			});
	}
}