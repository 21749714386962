<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>

    <div class="p-mx-3">
      <div class="p-grid p-jc-end p-mt-3">
        <Button label="Nuova" icon="pi pi-plus" class="purple-btn purple p-mt-3 p-col-12 p-md-2 p-mr-3" style="font-size: 1.5em" @click="$router.push('/productCategories/createProductCategory/'+account_id)"/>
      </div>
      <Message class="p-mx-2" severity="info" v-if="!productCategories.length">Per prima cosa registra una categoria di prodotto.</Message>  
      <div class="p-shadow-2 rounded-5 p-p-3 p-mt-3 p-mx-2">
        <product-categories-table :showRowDetail="true" :rowExpandable="false" title="Categorie prodotto" :productCategories="productCategories"></product-categories-table>
      </div>
    </div>

  </main>
</template>

<script>

import NavigationBar from '../components/NavigationBar';
import ProductCategoriesTable from '../components/ProductCategoriesTable';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Message from 'primevue/message';

import AccountService from '../../service/AccountService';

import { useRoute } from 'vue-router';

import { ref, onMounted } from 'vue';


export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Button, ProductCategoriesTable, Message},
  setup(){
    onMounted(() => {
        loadProductCategories(account_id);
    })

    const route = useRoute();

    const account_id = route.params.data;

    const productCategories = ref([]);
    const accountService = ref(new AccountService());

    const loadProductCategories = (account_id) => {
        accountService.value.getProductCategories(account_id).then(data => {
            console.log("productCategories: ",data);
            productCategories.value = data;
        })
    };

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {label: 'Anagrafica categorie prodotto'},
    ]);

    return { home, items, account_id, productCategories }
  }
};
</script>

<style scoped>

</style>

