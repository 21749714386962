<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>

    <div>

      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Crea link" icon="pi pi-link" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="$router.push('/documents/uploadExternalDoc/'+account_id+'&'+supplier_id+'&'+supplier_key)"/>
        </div>

        <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3 p-ai-stretch">
            <div class="p-d-flex p-ai-center p-col-12"><h3>Consenti modifica dei campi</h3> <InputSwitch class="p-ml-3" v-model="editable" /></div>


              <div class="p-col-12 p-md-6">
                
                <div>
                  <h3 class="p-mt-5">ID</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="supplier_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                  </span>
                </div>

                <div>
                  <h3 class="p-mt-5">Nome</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="supplier_name" id="ID" type="text" placeholder="Nome" :disabled="!editable"/>
                  </span>
                </div>

                <div>
                  <h3 class="p-mt-5">Ragione sociale</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="supplier_company" id="ID" type="text" placeholder="Ragione sociale" :disabled="!editable"/>
                  </span>
                </div>

              </div>

              <div class="p-col-12 p-md-6">
                
                <div>
                  <h3 class="p-mt-5">Chiave pubblica</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="supplier_key" id="ID" type="text" placeholder="Chiave pubblica" :disabled="true"/>
                  </span>
                </div>

                <div>
                  <h3 class="p-mt-5">P. Iva</h3>
                  <span class="p-float-label p-mt-2">
                    <InputText v-model="supplier_piva" id="ID" type="text" placeholder="Partita iva" :disabled="!editable"/>
                  </span>
                </div>

                <div>
                  <h3 class="p-mt-5">Descrizione</h3>
                  <span class="p-float-label p-mt-2">
                    <TextArea v-model="supplier_description" id="description" type="text" placeholder="Descrizione" rows="5" :disabled="!editable"/>
                  </span>
                </div>

              </div>

              <Button v-if="editable" label="Salva modifiche" icon="pi pi-save" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.1em" @click="updateSupplier()"/>

          </div>
          
        </div>

      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';

import NavigationBar from '../components/NavigationBar';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AccountService from '../../service/AccountService';

export default {
  name: "sample",
  components: {  NavigationBar, Breadcrumb, Button, InputText, TextArea, InputSwitch},
  setup(){

    onMounted(() => {
        loadSupplierInfo();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];
    const supplier_id = route_params.split('&')[1];

    const supplier_name = ref();
    const supplier_description = ref();
    const supplier_company = ref();
    const supplier_piva = ref();
    const supplier_key = ref();

    const editable = ref(false);

    const accountService = ref(new AccountService());

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Fornitori',
        to: '/suppliers/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    const loadSupplierInfo = () => {
      accountService.value.getSupplierById(supplier_id).then(data => {
        console.log("Fornitore: ", data[0]);
        supplier_name.value = data[0].name;
        supplier_company.value = data[0].company;
        supplier_piva.value = data[0].piva;
        supplier_key.value = data[0].pub_key;
        supplier_description.value = data[0].description;
      })
    }

    const updateSupplier = () => {
      accountService.value.registerSupplier(account_id, supplier_id, supplier_name.value, supplier_company.value, supplier_piva.value, supplier_description.value, supplier_key.value).then( () => {
        router.go();
      });
    }

    return { account_id, supplier_name, supplier_description, supplier_id, supplier_company, supplier_piva, supplier_key, home, items, editable, updateSupplier}
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }
  

  ::v-deep(.step-info:before) {
    display: none !important;
  }

  ::v-deep(.step-info:after) {
    display: none !important;
  }
</style>

