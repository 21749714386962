<template>
  <main>
    <navigation-bar :account_id="account_id"></navigation-bar>
      <Breadcrumb :home="home" :model="items"/>
      <Toast />

    <div>

      <div class="p-mx-3 p-grid p-jc-between">

        <div class="p-col-12 p-d-flex p-jc-end">
          <Button label="Salva" icon="pi pi-plus" class="purple-btn purple-diagonal p-mt-3 p-mb-5 p-mr-3" style="font-size: 1.5em" @click="saveSettings()"/>
        </div>

        <div class="p-grid p-col-12 p-jc-between p-mr-2 p-ml-2">

          <div class="p-fluid p-formgrid p-col-12 p-grid p-shadow-2 rounded-5 p-p-3">
            <div class="p-col-12">

              <div>
                <h3 class="p-mt-5">ID</h3>
                <span class="p-float-label p-mt-2">
                  <InputText v-model="account_id" id="ID" type="text" placeholder="ID" :disabled="true"/>
                </span>
              </div>

              <div>
                <h3 class="p-mt-5">Preferiti</h3>
                <span class="p-float-label p-mt-2">
                  <MultiSelect v-model="favorites" :options="routes" optionLabel="name" placeholder="Preferiti" :filter="true" class="multiselect-custom">
                    <template #value="slotProps">
                      <span class="" v-for="option of slotProps.value" :key="option.name">
                        <span>{{option.name}}, </span>
                      </span>
                      <template v-if="!slotProps.value || slotProps.value.length === 0">
                        Scegli una route
                      </template>
                    </template>
                    <template #option="slotProps">
                      <div class="">
                        <div>{{slotProps.option.name}}</div>
                      </div>
                    </template>
                  </MultiSelect>
                </span>
              </div>

            </div>
          </div>
          
        </div>

      </div>

    </div>
  </main>
</template>

<script>

import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import MultiSelect from 'primevue/multiselect';

import NavigationBar from '../components/NavigationBar';

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "Settings",
  components: {  NavigationBar, Breadcrumb, Toast, Button, InputText, MultiSelect },
  setup(){

    onMounted(() => {
        loadSettings();
    })

    const route = useRoute();
    const router = useRouter();

    const route_params = route.params.data;

    const account_id = route_params.split('&')[0];

    const routes = ref([
      {
        "name": "Home",
        "url": "/"
      },
      {
        "name": "Lista Linee",
        "url": "/productLines/"+account_id
      },
      {
        "name": "Lista Categorie",
        "url": "/productCategories/"+account_id
      },
      {
        "name": "Lista Prodotti",
        "url": "/products/"+account_id
      }
    ]);

    const favorites = ref([]);

    const home = ref({
            icon: 'pi pi-home', 
            to: '/',
        });

    const items = ref([
      {
        label: 'Linee',
        to: '/productLines/'+account_id
      },
      {
        label: 'Dettaglio'
      },
    ]);

    const loadSettings = () => {
      console.log("CARICATI");
    }

    const saveSettings = () => {
      console.log("SALVATO");
      router.go();
    }

    return { account_id, home, items, saveSettings, routes, favorites }
  }
};
</script>

<style scoped>
  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider) {
    background: white !important;
  }

  ::v-deep(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before) {
    background: #6a1b9a;
  }

  li ::marker {
    display: none !important;
  }

  ::v-deep(ul ::marker) {
    list-style-type: none;
  }
  

  ::v-deep(.step-info:before) {
    display: none !important;
  }

  ::v-deep(.step-info:after) {
    display: none !important;
  }
</style>

