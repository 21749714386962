<template>
  <main>
    <header-bar></header-bar>
    
    <div class="p-grid p-mx-3 p-mt-3">

      <div class="p-col-3">
        <Card style="border-radius: 15px;">
            <template #title>
              <h3 class="p-ml-3">Cerca</h3>
            </template>
            <template #content class="p-mt-2 row">
              <!-- FILTRI PER LOCALITA' -->
              <div class="p-col-12">
                <h5 class="p-mt-2 text-grey p-col-12">Regione</h5>
                <AutoComplete class="p-col-12" :multiple="true" v-model="selectedRegions" :suggestions="filteredRegionsMultiple" @complete="searchRegionMultiple($event)" field="name" />

              </div>

            </template>
        </Card>
      </div>
      <div class="p-col-9">

      </div>
    </div>
  </main>
</template>

<script>

import HeaderBar from '../components/HeaderBar';
import Card from 'primevue/card';
import AutoComplete from 'primevue/autocomplete'
/* import Button from 'primevue/button';
import Message from 'primevue/message';
import RadioButton from 'primevue/radiobutton';
import InputText from 'primevue/inputtext'; */


import 'primeicons/primeicons.css';

/* import TransactionService from '../../service/TransactionService';
import AccountService from '../../service/AccountService'; */

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';


export default {
  name: "sample",
  components: {
    HeaderBar, 
    Card,
    AutoComplete
  },

  setup(){
    onMounted(() => {
      
    });

    const router = useRouter();

    const selectedRegions = ref("");
    const regions = ref([
      {name: 'Emilia Romagna', value: 'ER'},
      {name: 'Toscana', value: 'TC'},
      {name: 'Lazio', value: 'LZ'},
      {name: 'Veneto', value: 'VN'}
    ])
    const filteredRegionsMultiple = ref([]);

    const searchRegionMultiple = (event) => {
      setTimeout(() => {
          if (!event.query.trim().length) {
              filteredRegionsMultiple.value = [...regions.value];
          }
          else {
              filteredRegionsMultiple.value = regions.value.filter((reg) => {
                  return reg.name.toLowerCase().startsWith(event.query.toLowerCase());
              });
          }
      }, 100);
    };

    return {
      router,
      selectedRegions,
      filteredRegionsMultiple,
      searchRegionMultiple
    }
  }
};
</script>

<style scoped>
  ::v-deep(.p-autocomplete-multiple-container) {
    width: 100% !important;
  }
</style>

